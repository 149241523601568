import * as utilCommon from 'helpers/util-common';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
    self.props.change('fromCommittee', self.state.committeeDesignation)
}

export const handleSearh = (value) => {
    if (value.trim()) {
        self.setState({ showNoResults: false, searchValue: value }, () => {
            self.props.change('toCommittee', self.state.searchValue)
        })
        searchTriggerChange(value);
    } else {
        self.setState({ showNoResults: false, searchValue: value, searchResultsData: [] }, () => {
            self.props.change('toCommittee', self.state.searchValue)
        })
    }
}
const searchTriggerChange = (searchValue) => {
    const { committeeListWithoutCurrentCommittee } = self.state

    const searchResults = (committeeListWithoutCurrentCommittee.length > 0) && committeeListWithoutCurrentCommittee.filter(item => {
        if (item.CommitteeDesignation.toLowerCase().startsWith(searchValue.toLowerCase())) {
            return item.CommitteeDesignation.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
        }
    })

    if (searchResults.length > 0) {
        self.setState({ showNoResults: false, searchResultsData: searchResults })
    } else {
        self.setState({ showNoResults: true, searchResultsData: [] })
    }
}
export const handleResultSelect = (value) => {
    self.setState({ renderSelectedValueFromSearchList: value, searchValue: value.CommitteeDesignation, IsStudentCommitteeSetting: value.IsStudentEnabled, searchResultsData: [value] }, () => {
        self.props.change('toCommittee', self.state.searchValue)
    })
}
export const handleChange = (event, value, name) => {
    switch (name) {
        case 'CopyApplication':
            self.setState({ copyApplication: value.checked })
            break;

        case 'CopyClassification':
            self.setState({ copyClassification: value.checked }, () => {
                (!self.state.copyClassification && self.state.copyVote) && self.setState({ copyVote: self.state.copyClassification })
            })
            break;

        case 'CopyVote':
            self.setState({ copyVote: value.checked }, () => {
                self.state.copyVote && self.setState({ copyClassification: self.state.copyVote })
            })
            break;

        default:
            self.setState({ copyApplication: true, copyClassification: false, copyVote: false })
            break;
    }
}

export const handleCopyMemberSubmit = (copyMembersInCommittee) => {
    if(self.state.showMemberExistModel){
        copyMembersInCommittee = self.state.copyMemberData
    }
    self.props.getCountActiveMembersInCommittee(copyMembersInCommittee.FromCommitteeId, (response) => {
        self.setState({ countActiveMembersInSelectedCommittee: response.CountActiveMembers, showMemberExistModel: false, copyMemberData: {} }, () => {
            if (self.state.countActiveMembersInSelectedCommittee === 0) {
                let alertMessage = utilCommon.getMessage(self.props.messageCodes, '9068.text');
                self.props.showAlertPopup(alertMessage);
            } else {
                self.props.postCopyMembersToCommittee(copyMembersInCommittee, () => {
                    self.props.closePopUp()
                })
            }
        })
    })
}

export const copyMembers = (value) => {
    const copyMembersInCommittee = {
        FromCommitteeId: value.CommitteeId,
        ToCommitteeId: self.state.renderSelectedValueFromSearchList.CommitteeId,
        CopyApplication: self.state.copyApplication,
        CopyClassification: self.state.copyClassification,
        CopyVote: self.state.copyVote,
        UpdateReason: value.reasonForUpdate,
        StudentCommitteeManagement: self.state.flagFeature.StudentCommitteeManagement,
        IsStudentCommitteeEnabled: self.state.IsStudentCommitteeSetting
    }
    if (self.state.flagFeature && self.state.flagFeature.StudentCommitteeManagement) {
        if (self.state.IsStudentCommitteeSetting) {
            handleCopyMemberSubmit(copyMembersInCommittee);
        } else {
            if (self.props.initialValues && self.props.initialValues.StudnetMemberExist) {
                self.setState({ showMemberExistModel: true, copyMemberData: copyMembersInCommittee })
            } else {
                handleCopyMemberSubmit(copyMembersInCommittee);
            }
        }
    } else {
        handleCopyMemberSubmit(copyMembersInCommittee);
    }
}

export const closeInfoMessageModal = () => {
    self.setState({ showMemberExistModel: false, copyMemberData: {}});
}

export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.toCommittee) {
        errors.toCommittee = utilCommon.getMessage(props.messageCodes, '3135.text');
    } else if (values.toCommittee && self.state.committeeListWithoutCurrentCommittee.filter((item) => item.CommitteeDesignation.toLowerCase() === (values.toCommittee || '').toLowerCase()).length <= 0) {
        errors.toCommittee = utilCommon.getMessage(props.messageCodes, '3135.text');
    }
    if (!values.reasonForUpdate || values.reasonForUpdate.trim() === '') {
        errors.reasonForUpdate = utilCommon.getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}
