import axios from "axios";
import * as commonActions from "common.actions";
import getApiUrl from "helpers/api-urls";
import * as actions from "action-types";
import { encrypt, decrypt } from "helpers/util-common";

// For getting fee group Details
const getFeeGroupBannerDetailAction = (feeGroupId, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "getFeeGroupBannerDetail") + `/${feeGroupId}`;
  dispatch(commonActions.setLoader(true));

  axios
    .get(url)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content || {});
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback({});
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8150"));
      callback({});
    });
};

const getFeeGroupTabDetailAction = (feeGroupId, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "getFeeGroupTabDetail") + `/${feeGroupId}`;
  dispatch(commonActions.setLoader(true));

  axios
    .get(url)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch({
          type: actions.GET_FEEGROUP_DETAILS,
          payload: { initialValues: response.data.content },
        });
        callback(response.data.content || {});
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback({});
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8151"));
      callback({});
    });
};
const getFeeGroupNoneAssociatedMemberAction = (callback, dispatch) => {
  let url = getApiUrl("feeGroup", "getFeeGroupDeAssociatedMembers");
  dispatch(commonActions.setLoader(true));
  axios
    .get(url)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content || []);
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
    });
};

const getFeeGroupAssociatedMemberListAction = (
  feeGroupId,
  callback,
  dispatch,
) => {
  let url =
    getApiUrl("feeGroup", "getFeeGroupAssociatedMemberList") +
    `/${decrypt(feeGroupId)}`;
  dispatch(commonActions.setLoader(true));

  axios
    .get(url)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content || []);
      } else {
        // Handle error code
        dispatch(commonActions.setMessage(true, response.data.message));
        callback([]);
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8156"));
    });
};

const putFeeGroupStatusAction = (data, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "updateFeeGroupStatus");
  dispatch(commonActions.setLoader(true));
  axios
    .put(url, data)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(commonActions.setMessage(true, "2001"));
        callback(response.data.status);
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback(false);
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8154"));
    });
};

const putAssociateMemberFeeGroupAction = (data, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "associateMemberFeeGroup");
  dispatch(commonActions.setLoader(true));
  axios
    .put(url, data)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(commonActions.setMessage(true, "2023"));
        callback(response.data.status);
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback(false);
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8152"));
    });
};
const deleteAssociateMemberFeeGroupAction = (data, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "deleteAssociatedMember");
  dispatch(commonActions.setLoader(true));
  axios
    .put(url, data)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(commonActions.setMessage(true, "2048"));
        callback(response.data.status);
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback(false);
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "8153"));
    });
};

const getFeeGroupInvoiceDetailsAction = (feeGroupId, callback, dispatch) => {
  let url =
    getApiUrl("feeGroup", "getFeeGroupInvoiceDetails") +
    `/${encrypt(feeGroupId)}`;

  axios
    .get(url)
    .then((response) => {
      if (response.data.status) {
        callback(response.data.content || {}, response.data.status);
      } else {
        // Handle error code
        dispatch(commonActions.setMessage(true, response.data.message));
        callback({}, response.data.status);
      }
    })
    .catch((err) => {
      callback({}, false);
      dispatch(commonActions.setMessage(true, "8160"));
    });
};

const getInitialMembershipTypeListDataAction = (dispatch) => {
  let getMembershipTypeListUrl = getApiUrl(
    "manageMembers",
    "getMembershipTypeList",
  );
  dispatch(commonActions.setLoader(true));
  axios
    .get(getMembershipTypeListUrl)
    .then((getMembershipTypeListResponse) => {
      dispatch(commonActions.setLoader(false));
      if (getMembershipTypeListResponse.data.status) {
        dispatch({
          type: actions.MEMBERSHIPTYPE_LIST,
          membershipTypeList: getMembershipTypeListResponse.data.content,
        });
      } else {
        dispatch(
          commonActions.setMessage(
            true,
            getMembershipTypeListResponse.data.message,
          ),
        );
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "3039"));
    });
};

const getFeeGroupAuditLogAction = (
  feeGroupNumber,
  requestData,
  callback,
  dispatch,
) => {
  let url =
    getApiUrl("feeGroup", "getFeeGroupAuditLog") +
    `/${encrypt(feeGroupNumber)}`;

  axios
    .get(url, { params: { requestData: requestData } })
    .then((response) => {
      let result = {};
      if (response.data.status) {
        result = response.data.content;
      } else {
        dispatch(commonActions.setMessage(true, response.data.message));
      }
      callback(result);
    })
    .catch((err) => {
      dispatch(commonActions.setMessage(true, "3040"));
      callback({});
    });
};

const getAuditLogField = (moduleIds, callback, dispatch) => {
  let url = getApiUrl("feeGroup", "getAuditLogField");
  axios
    .get(url, { params: { moduleIds: JSON.stringify(moduleIds) } })
    .then((response) => {
      let result = {};
      if (response.data.status) {
        dispatch({
          type: actions.GET_AUDIT_LOG_FEILD,
          payload: response.data.content,
        });
        result = response.data.content;
      } else {
        dispatch(commonActions.setMessage(true, response.data.message));
      }
      callback(result);
    })
    .catch((err) => {
      dispatch(commonActions.setMessage(true, "3040"));
      callback({});
    });
};

export default {
  getFeeGroupBannerDetailAction,
  getFeeGroupNoneAssociatedMemberAction,
  getFeeGroupTabDetailAction,
  putFeeGroupStatusAction,
  getFeeGroupAssociatedMemberListAction,
  putAssociateMemberFeeGroupAction,
  deleteAssociateMemberFeeGroupAction,
  getFeeGroupInvoiceDetailsAction,
  getInitialMembershipTypeListDataAction,
  getFeeGroupAuditLogAction,
  getAuditLogField,
};
