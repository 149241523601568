import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { getHighlightedHTMLElement } from 'helpers/util-common';

let searchValue = '';

// Auto search for redux form
class CustomRendererSearch extends Component {
    state = {
        isRedux: 'input' in this.props
    }
    resultRenderer = (value) => {
        searchValue=searchValue==='.'?'@':searchValue;
        let highlightedAccountNumber = getHighlightedHTMLElement(value.AccountNumber && `${`${value.AccountNumber} &bull;` || ''}`, searchValue);
        let highlightedMemberName = getHighlightedHTMLElement(`${this.formatMemberName(value) || ''}`, searchValue);
        let highlightedCompanyName = getHighlightedHTMLElement(value.FacilityName && `${`&bull; ${value.FacilityName}` || ''}`, searchValue);
        let highlightedMemberType = ''
        let highlightedOfficerTitle = getHighlightedHTMLElement(value.OfficerTitleName && `${`${value.OfficerTitleName}` || ''}`, searchValue) +
            `${(value.IsApplicableAll && value.AssignedMembers && value.AssignedMembers.length > 0) ? '<span class="supTxtLegends type2">xy</span>' :
                (value.IsApplicableAll && value.AssignedMembers && value.AssignedMembers.length <= 0) ? '<span class="supTxtLegends type2">y</span>' :
                    (!value.IsApplicableAll && value.AssignedMembers && value.AssignedMembers.length > 0) ? '<span class="supTxtLegends type2">x</span>' : ''}`
            ;
        let highlightedCommitteeDesignation = value.CommitteeDesignation && value.CommitteeDesignation || '';
        let renderElement = '';

        if (this.props.membershipTypeList && this.props.membershipTypeList.length > 0) {
            this.props.membershipTypeList.map(item => {
                if (value.MembershipTypeId === item.value) {
                    highlightedMemberType = getHighlightedHTMLElement(`${item.text || ''}`, searchValue);
                }
            })
        }

        renderElement = `<p>${highlightedAccountNumber} ${highlightedMemberName} ${highlightedMemberType} ${highlightedCompanyName}${highlightedOfficerTitle} ${highlightedCommitteeDesignation}</p>`;

        return <div dangerouslySetInnerHTML={{ __html: renderElement }}></div>
    }
    formatMemberName(value) {
        if ((value.LastName == null && !value.LastName) && (value.FirstName == null && !value.FirstName) && (value.MiddleName == null && !value.MiddleName)) {
            return '';
        }
        return `${value.LastName || ''} ${value.FirstName || ''} ${value.MiddleName && value.MiddleName[0].toUpperCase() || ''} &bull;`;
    }
    handleReduxResultSelect = (e, { name, value }) => {
        this.props.input.onChange(value);
    }
    handleResultSelect = (e, { result }) => {
        if (this.state.isRedux) {
            this.props.onResultSelect(result);
        } else {
            this.props.onResultSelect(result);
        }

    }
    handleSearchChange = (e) => {
        searchValue = e.target.value.trim();
        if (this.state.isRedux) {
            this.props.input.onChange(e.target.value);
        } else {
            this.props.onChange(e.target.value);
        }

    }

    render() {
        if ('input' in this.props) {
            const { label, required, input: { name, value }, placeholder, className, isLoading, results, meta: { touched, error }, showNoResults, noResultsMessage } = this.props;
            searchValue = value;
            return (
                <div>
                    <label className="label">
                        {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
                        {label}
                    </label>

                    <Search
                        data-testid={name ? name : label}
                        name={name}
                        className={touched && error ? `${className} field error` : className}
                        placeholder={placeholder}
                        loading={isLoading}
                        value={value}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                        resultRenderer={this.resultRenderer}
                        results={results}
                        showNoResults={showNoResults}
                        noResultsMessage={noResultsMessage ?? undefined}
                    />
                    {touched && (error && <span className="errorMessage mt5"> {error}</span>)}
                </div>
            )
        }

        // Without redux form implementation
        const { label, placeholder, required, isLoading, name, className, results, value, showNoResults, touched, error, noResultsMessage } = this.props;
        return (
            <div>
                <label className="label">
                    {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
                    {label}
                </label>

                <Search
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    resultRenderer={this.resultRenderer}
                    results={results}
                    showNoResults={showNoResults}
                    className={className}
                    noResultsMessage={noResultsMessage ?? undefined}
                />

                {touched && (error && <span className="errorMessage mt10">{error}</span>)}
            </div>
        )


    }
}
export default CustomRendererSearch;
