import * as common from 'helpers/util-common';
import _ from "lodash";
import { minDateForInputDateFields } from 'models/common.models';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon } from 'semantic-ui-react';
import DateTimeForm from 'shared-components/DatePicker';
import RenderInput from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import RenderRadioField from 'shared-components/RadioBox';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import { MembershipTypes } from '../../members.model';
import * as _func from './function';
import CustomAlertModal from 'shared-components/CustomAlertModal';

class MemberCommitteeBasicDetailsEdit extends Component {
    prevState = {}

    constructor(props) {
        super(props);
        this.state = {
            maxDate: common.formatDateToMMDDYYYY(),
            minDate: _(this.props.initialValues).get('EstablishmentDate') ? common.formatDateToMMDDYYYY(this.props.initialValues.EstablishmentDate) : common.formatDateToMMDDYYYY(minDateForInputDateFields),
            committeeJoinDate: this.props.memberCommitteeDetails.CommitteeJoinDate,
            officerAppointedDate: this.props.memberCommitteeDetails.OfficerAppointedDate === '' ? common.formatDateToMMYYYY() : this.props.memberCommitteeDetails.OfficerAppointedDate,
            memberCommitteeStatus: this.props.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase(),
            inactiveReasonSelectedValue: (this.props.memberCommitteeDetails.TopSection && this.props.memberCommitteeDetails.TopSection.InactiveReasonId) ? this.props.memberCommitteeDetails.TopSection.InactiveReasonId : 'select',
            inactiveReason: [],
            officerTitleSelectedValue: this.props.memberCommitteeDetails.TopSection.OfficerTitleId > 0 ? this.props.memberCommitteeDetails.TopSection.OfficerTitleId : '',
            officerTitle: [],
            isCommitteeJoinDateGreaterThanClassificationDate: false,
            isCommitteeJoinDateGreaterThanVoteDate: false,
            isCommitteeJoinDateGreaterThanBothDates: false,
            honoraryStatus: this.props.memberCommitteeDetails.TopSection.HonoraryStatus === true ? 'yes' : 'no',
            committeePayStatus: this.props.memberCommitteeDetails.TopSection.CommitteePayStatus === true ? 'yes' : 'no',
            feeGroupId: this.props.memberCommitteeDetails.TopSection.FeeGroupId || 0,
            feeGroupList: [],
            isShowAdministrativeAssistantPopup: false,
            isShowAdministrativeAssistantOrStaffManagerPopup: false,
            officerTitleName: '',
            disableFieldForStudent: false
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }
    componentDidMount() {
        this.props.change('FeeGroupId', (this.props.memberCommitteeDetails.TopSection.FeeGroupId || 0).toString());
        this.prevState = this.state;
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            officerTitle: nextProps.memberCommitteeOfficerTitles ? nextProps.memberCommitteeOfficerTitles : nextProps.officerTitleList,
            inactiveReason: nextProps.inactiveReasonList
        })
        if (this.state.officerTitle && this.state.officerTitle.length > 0) {
            let result = this.state.officerTitle.filter((filterResult) => {
                return filterResult.value === this.state.officerTitleSelectedValue;
            });
            if (result && result.length > 0) {
                this.setState({ officerTitleName: _.get(result[0], 'text') })
            }

        }
    }
    handleChangeOfficerTitle = (event, value) => {
        this.setState({ officerTitleSelectedValue: value })

        const { officerTitle } = this.state;
        if (officerTitle && officerTitle.length > 0) {
            let result = officerTitle.filter((filterResult) => {
                return filterResult.value === value;
            });
            if (result && result.length > 0) {
                this.setState({ officerTitleName: _.get(result[0], 'text') })
            }

        }
    }
    render() {
        const { messageCodes, handleSubmit, onEditCancel, submitting, memberCommitteeDetails: { TopSection }, initialValues } = this.props
        const { maxDate, committeeJoinDate, officerAppointedDate, memberCommitteeStatus, inactiveReasonSelectedValue, inactiveReason, officerTitleSelectedValue, officerTitle, minDate, isCommitteeJoinDateGreaterThanClassificationDate, isCommitteeJoinDateGreaterThanVoteDate, isCommitteeJoinDateGreaterThanBothDates,
            honoraryStatus, committeePayStatus, feeGroupList, isShowAdministrativeAssistantPopup, isShowAdministrativeAssistantOrStaffManagerPopup, officerTitleName, committeeName, disableFieldForStudent
        } = this.state
        return (
            <section className="subCommInfo">
                <Form onSubmit={handleSubmit} noValidate data-testid="memberCommitteeBasicDetailsEdit">
                    <h5>{TopSection.CommitteeSubcommitteeName}
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button title="Cancel" className="closeBtn" onClick={onEditCancel}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="CommitteeJoinDate"
                                    dataTestId="committeeJoinDate"
                                    component={DateTimeForm} label="Committee Join Date"
                                    value={committeeJoinDate}
                                    showTime={false}
                                    required={true}
                                    initialDate={maxDate}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    normalize={common.dateMasking}
                                    onChange={_func.handleChangeCommitteeJoinDate}
                                    readOnly={false}
                                    disabled={memberCommitteeStatus === 'inactive'}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <Field name="Status" label="Member Committee Status"
                                    component={RenderRadioField}
                                    className="mr10"
                                    type="radio"
                                    selectedValue={memberCommitteeStatus}
                                    onChange={_func.handleChangeMemberCommitteeStatus}
                                    required={true}
                                    options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                {memberCommitteeStatus === 'inactive' ?
                                    <Field name="InactiveReason"
                                        component={RenderSelect} label="Inactive Reason"
                                        value={inactiveReasonSelectedValue}
                                        options={inactiveReason}
                                        onChange={_func.handleChangeInactiveReason}
                                        required={true}
                                    /> : ''}
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="OfficerTitle"
                                    label="Officer Title"
                                    value={officerTitleSelectedValue}
                                    onChange={(e, value) => this.handleChangeOfficerTitle(e, value)}
                                    disabled={memberCommitteeStatus === 'inactive' || disableFieldForStudent}
                                    component={DropdownMultipleSelection}
                                    isSearch={true}
                                    placeholder="Search by Officer Title"
                                    isMultiple={false}
                                    options={officerTitle}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                {(officerTitleSelectedValue !== 'select' && officerTitleSelectedValue > 0) ?
                                    <Field name="OfficerAppointedDate"
                                        label="Officer Appointed Date"
                                        required={true} placeholder='MM/YYYY'
                                        component={RenderInput}
                                        value={officerAppointedDate}
                                        normalize={common.monthYearDateMask}
                                        onChange={_func.handleChangeOfficerAppointedDate}
                                        readOnly={false}
                                        disabled={memberCommitteeStatus === 'inactive'}
                                    /> : ''
                                }
                            </Grid.Column>
                        </Grid.Row>
                        {this.props.MemberTypeId !== MembershipTypes.Student
                            &&
                            <React.Fragment>

                                <Grid.Row>
                                    <Grid.Column>

                                        <Field name="HonoraryStatus" label="Honorary Committee Member"
                                            component={RenderRadioField}
                                            className="mr10"
                                            type="radio"
                                            selectedValue={honoraryStatus}
                                            onChange={(even, value) => _func.handelHonoraryChange(even, value, 'honorary')}
                                            required={true}
                                            options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                                        />
                                    </Grid.Column>
                                    {honoraryStatus === 'yes' && <Grid.Column>
                                        <Field name="CommitteePayStatus" label="Does the committee pay for membership?"
                                            component={RenderRadioField}
                                            className="mr10"
                                            type="radio"
                                            selectedValue={committeePayStatus}
                                            onChange={(even, value) => _func.handelHonoraryChange(even, value, 'committeePay')}
                                            required={true}
                                            options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                                        />
                                    </Grid.Column>}
                                </Grid.Row>
                                {(honoraryStatus === 'yes' && committeePayStatus === 'yes') && <Grid.Row>
                                    <Grid.Column>

                                        <Field name="FeeGroupId"
                                            component={DropdownMultipleSelection} label="Fee Group"
                                            isSearch={true}
                                            isMultiple={false}
                                            isDefaultValue={true}
                                            required={true}
                                            noResultFoundMessage='No results found.'
                                            placeholder="Type to select a fee group"
                                            options={feeGroupList}
                                        />
                                    </Grid.Column>
                                </Grid.Row>}
                            </React.Fragment>
                        }
                    </Grid>

                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="ReasonforUpdate"
                                    component={TextArea} required={true} label="Reason for Update"
                                    maxLength="200" placeholder="Please enter the reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Confirm className="confirm-box"
                        open={isCommitteeJoinDateGreaterThanClassificationDate || isCommitteeJoinDateGreaterThanVoteDate || isCommitteeJoinDateGreaterThanBothDates}
                        content={common.getMessage(messageCodes, (isCommitteeJoinDateGreaterThanBothDates && '9061.text') || (isCommitteeJoinDateGreaterThanClassificationDate && '9062.text') || (isCommitteeJoinDateGreaterThanVoteDate && '9063.text'))}
                        onCancel={_func.popUpCancel}
                        onConfirm={_func.popUpConfirm}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />

                    <Confirm className="confirm-box"
                        data-testid="confirm-box"
                        open={isShowAdministrativeAssistantOrStaffManagerPopup}
                        content={common.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', officerTitleName).replace('<Main Committee Designation>', committeeName)}
                        onCancel={_func.cancelAdministrativeAssistantOrStaffManagerPopup}
                        onConfirm={_func.confirmAdministrativeAssistantOrStaffManagerPopup}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />

                    {isShowAdministrativeAssistantPopup &&
                        <CustomAlertModal content={common.getMessage(messageCodes, '9522.text').replace('@OfficerTitleName', officerTitleName)} handleClick={_func.handleClickOk} />
                    }
                </Form>
            </section>
        )
    }
}
export default reduxForm({
    form: 'MemberCommitteeBasicDetailsEdit',
    validate: _func.validateHandler,
    onSubmit: _func.updateBasicDetails
})(MemberCommitteeBasicDetailsEdit);
