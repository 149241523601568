import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { Button } from 'semantic-ui-react';
import domtoimage from 'dom-to-image-more';
import jsPDF from "jspdf";
import "./invoice.css";
import { memberFeeNumberFormat, decrypt, convertImageUrlToBase64 } from "../../../../../helpers/util-common";
import { getMemberRNETemplateAction } from "../../../actions/membership-report.action";
import { exportFileNameWithDot } from 'helpers/util-common';
import CustomLoader from '../../../../../shared-components/Loader';

const GenerateInvoice = ({ match }) => {

const INVOICE = "invoice";
const [invoiceDetails, setInvoiceDetails] = useState({});
const [templateUrl, setTemplateUrl] = useState();
const [imageBase64String, setImageBase64String] = useState();
const [isLoading, setIsLoading] = useState(false);
const { type, stock } = match.params;

const dispatch = useDispatch();

useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const encryptedData = queryParams.get('data');
  if (encryptedData) {
    const decryptedData = decrypt(decodeURIComponent(encryptedData));
    const parsedData = JSON.parse(decryptedData);
    setInvoiceDetails(parsedData);
  }

  if (!stock) {
    let template = type == INVOICE ? "TWVtYmVyc2hpcCBSZW5ld2FsIEludm9pY2U=" : "TWVtYmVyc2hpcCBSZWNlaXB0";
    getMemberRNETemplateAction(template, dispatch, (templateUrlResult) => {
      if (templateUrlResult) {
        setTemplateUrl(templateUrlResult);
        convertImageUrlToBase64(templateUrlResult)
            .then(result => setImageBase64String(result))
            .catch(() => setImageBase64String(null));
      }
    });
  }

  let myDiv = document.getElementById("root");
  myDiv.style.overflow = "visible";
}, []);
  
const downloadPDF = () => {
  setIsLoading(true);
  type === INVOICE ? document.getElementById("membership-card").style.top = '963px' : document.getElementById("renewale-card-wrap").style.top = '953px';
  type === INVOICE ? document.getElementById("membership-left-section").style.top = '195px' : document.getElementById("membership-left-section-receipt").style.top = '593px';
  document.getElementById("membership-address").style.top = '200px';
  let source = document.getElementById("membership-container");
  const pageWidth = 902;
  const pageHeight = 1165;
  if (!process.env.testmode) {
    domtoimage
    .toPng(source)
    .then(function (dataUrl) {
        type === INVOICE ? document.getElementById("membership-card").style.top = '1020px' : document.getElementById("renewale-card-wrap").style.top = '1010px';
        type === INVOICE ? document.getElementById("membership-left-section").style.top = '252px' : document.getElementById("membership-left-section-receipt").style.top = '650px';
        document.getElementById("membership-address").style.top = '250px';

        let img = new Image();
        img.src = dataUrl;
        const pdf = new jsPDF({
              orientation: "p",
              unit: "px",
              hotfixes: ["px_scaling"],
              format: [pageWidth, pageHeight],
            });
            const totalHeight = pageHeight * 1;
            pdf.addImage(
              img,
              "JPEG",
              20,
              0,
              pageWidth * 0.95,
              totalHeight,
              undefined,
              "FAST"
            );
            const fileName = type == INVOICE ? "Invoice_" : "Receipt_";
            const fileNameWithDate = exportFileNameWithDot(fileName, new Date());
            pdf.save(fileNameWithDate + ".pdf");
            setIsLoading(false);
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
  }
};

  return (
     <>
    {!window.location.pathname.includes('stock') && <div className="download-btn">
      <Button
            color="blue"
            onClick={() => { if(!isLoading) downloadPDF();}}
          >
          {isLoading ? <>Downloading...</> : 'Download AS PDF'}
        </Button>
      </div>}
      {isLoading && <CustomLoader />}
      <div id="membership-container">
        <div id="membership-address">
          <span id="membership-address-span">
            {get(invoiceDetails, "fullAddress") &&
              get(invoiceDetails, "fullAddress")
                .split("\n")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
          </span>
          <span id="membership-address-span">
            {get(invoiceDetails, "poNumber") ? get(invoiceDetails, "poNumber") : ""}
          </span>
        </div>
        <div
          id={
            type == INVOICE ? "membership-left-section" : "membership-left-section-receipt"
          }
        >
          <div>
            {get(invoiceDetails, "accountNumber") ? get(invoiceDetails, "accountNumber") : ""}
          </div>
          <div>
            {get(invoiceDetails, "membershipFee") ? memberFeeNumberFormat(get(invoiceDetails, "membershipFee")) : 0}
          </div>
          <div>
            {get(invoiceDetails, "membershipType") ? get(invoiceDetails, "membershipType") : ""}
          </div>
        </div>
        <div id={
                type == INVOICE ? "membership-card" : "renewale-card-wrap"
              }
              >
          <div className="membership-card-item-member-name">
            {get(invoiceDetails, "fullName") ? get(invoiceDetails, "fullName") : ""}
            <div className="org-name-space"></div>
            <p className="paragraph-txt">{get(invoiceDetails, "orgName") ? get(invoiceDetails, "orgName") : ""}</p>
          </div>
          <div id={type != INVOICE ? "membership-card-items": "membership-card-items-invoice"}>
            <span>
              {get(invoiceDetails, "accountNumber")? get(invoiceDetails, "accountNumber") : ""}
            </span>
            <span
              id={
                type == INVOICE ? "membership-card-item-member-type" : "membership-card-item-member-type-receipt"
              }
            >
              {get(invoiceDetails, "membershipType") ? get(invoiceDetails, "membershipType") : ""}
            </span>
          </div>
        </div>
        <img id="membership-img" src={templateUrl} />
      </div>
    </>
  );
};

export default withRouter(GenerateInvoice);