import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal, Confirm } from 'semantic-ui-react';
import RenderInput from 'shared-components/Input';
import TextArea from 'shared-components/TextArea'
import RenderCheckbox from 'shared-components/CheckBox';
import CustomRendererSearch from '../custom-renderer-search';
import { CopyMembersModalLegendNote, CopyMembersModalFootNote } from '../../committee.model';
import { ReasonForUpdateMaxLength } from '../../../../models/common.models';
import * as _func from './function';
import { cacheManager } from "../../../../helpers/util-common";
import ActiveStudentMemberModal from '../../../../shared-components/CustomAlertModal/ActiveStudentMemberModal';

class CopyMembersModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            committeeDesignation: this.props.initialValues.CommitteeDesignation,
            committeeListWithoutCurrentCommittee: [],
            searchResultsData: [],
            copyApplication: true,
            copyClassification: false,
            copyVote: false,
            searchValue: '',
            showNoResults: false,
            renderSelectedValueFromSearchList: {},
            countActiveMembersInSelectedCommittee: 0,
            flagFeature: JSON.parse(cacheManager.getItem("featureFlag")),
            IsStudentCommitteeSetting: false,
            showMemberExistModel: false,
            copyMemberData: {}
        }
    }

    componentWillMount() {
        const committeeListWithoutCurrentCommittee = (this.props.activeCommittees && this.props.activeCommittees.length > 0) && this.props.activeCommittees.filter((item) => {
            return this.state.committeeDesignation !== item.CommitteeDesignation
        })
        _func.setClassInstance(this);
        this.setState({ committeeListWithoutCurrentCommittee })
    }

    render() {
        const { committeeDesignation, searchResultsData, copyApplication, copyClassification, copyVote, searchValue, showNoResults, showMemberExistModel } = this.state;
        const { handleSubmit, closePopUp } = this.props;

        return (
            <div>
                <Modal open='true' className="tiny" data-testid="copyMembersModal">
                    <Modal.Header>Copy Members <i className="close" onClick={closePopUp}>&#x2716;</i></Modal.Header>

                    <Form size='large' onSubmit={handleSubmit}>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <span className="legendNotes mt20">{CopyMembersModalLegendNote}</span>

                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field name="fromCommittee"
                                                label="From Committee"
                                                placeholder='Committee Designation'
                                                component={RenderInput}
                                                value={committeeDesignation}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field name={'toCommittee'} type="text"
                                                component={CustomRendererSearch}
                                                label={'To Committee'}
                                                placeholder={'Search by Committee Designation'}
                                                maxLength={'100'}
                                                value={searchValue}
                                                onChange={(event, value) => _func.handleSearh(value)}
                                                showNoResults={showNoResults}
                                                results={searchResultsData}
                                                onResultSelect={_func.handleResultSelect}
                                                required={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <span className="inlineBlock mr20">
                                                <RenderCheckbox name="copyApplication"
                                                    setLabeltitleProp="Copy Application"
                                                    IsChecked={copyApplication} IsEditable={true}
                                                    onChange={(event, value) => _func.handleChange(event, value, 'CopyApplication')}
                                                />
                                            </span>
                                            <span className="mr20">
                                                <RenderCheckbox name="copyClassification"
                                                    setLabeltitleProp="Copy Classification"
                                                    IsChecked={copyClassification} IsEditable={true}
                                                    onChange={(event, value) => _func.handleChange(event, value, 'CopyClassification')}
                                                />
                                            </span>
                                            <span>
                                                <RenderCheckbox name="copyVote"
                                                    setLabeltitleProp="Copy Vote"
                                                    IsChecked={copyVote} IsEditable={true}
                                                    onChange={(event, value) => _func.handleChange(event, value, 'CopyVote')}
                                                />
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <span className="legendNotes mb20 mt10">
                                        <strong>Note: </strong>{CopyMembersModalFootNote}
                                    </span>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field component={TextArea} type='text' label='Reason for update'
                                                name='reasonForUpdate' required={true} maxLength={ReasonForUpdateMaxLength}
                                                placeholder="Please enter reason for update"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>

                        <Modal.Actions>
                            <div>
                                <button className="ui button primary" type="submit">Copy</button>
                                <button className="ui button cancel" onClick={closePopUp}>Cancel</button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal >
                <ActiveStudentMemberModal showCustomMessageStudentExist={showMemberExistModel} handleClick={_func.handleCopyMemberSubmit} handleDismiss={_func.closeInfoMessageModal} />
            </div>
        )
    }
}
export default reduxForm({
    form: 'CopyMembersModal',
    validate: _func.validateHandler,
    onSubmit: _func.copyMembers
})(CopyMembersModal);
