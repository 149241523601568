import lodash from 'lodash';
import React, { Component } from 'react';
import { Accordion, Icon, Loader } from 'semantic-ui-react';
import MemberCommitteeBasicDetails from '../member-committee-basic-details';
import MemberCommitteeBasicDetailsEdit from '../member-committee-basic-details-edit';
import MemberCommitteeGeneralInfo from '../member-committee-general-information';
import MemberCommitteeEditGeneralInfo from '../member-committee-general-information-edit';
import MemberCommitteeHistoryDetailsModal from '../member-committee-history-modal';
import MemberCommitteeOfficerTitleHistoryDetailsModal from '../member-committee-officer-title-modal';
import MemberCommitteeOrganizationDetails from '../member-committee-organization-details';
import MemberCommitteeOrganizationDetailsEdit from '../member-committee-organization-details-edit';
import * as _func from './function';
import { cacheManager } from '../../../../../helpers/util-common';


class MemberCommitteeDetails extends Component {

    state = {
        isOpen: false,
        memberMainSubCommittees: [],
        activeIndex: 0,
        showLoader: false,
        activeCommitteeId: this.props.activeCommitteeId,
        toggleCommitteeId: 0,
        showResponsibilities: false,
        showMemberCommitteeStatusModal: false,
        isMemberCommitteeBasicDetailsEdit: false,
        checkCommitteeStatus: '',
        checkMemberStatus: '',
        showMemberCommitteeOfficerTitleStatusModal: false,
        isMemberCommitteeGeneralInfoEdit: false,
        parentClicked: false,
        isMainCommittee: false,
        isShowClassificationEdit: false,
        accountNumber: null,
        flagFeature: JSON.parse(cacheManager.getItem("featureFlag"))
    }

    componentWillMount() {
        this.props.resetLeftPanel();
        this.setState({ memberMainSubCommittees: [] })
        setTimeout(() => {
            let accountNumber = this.props.AccountNumber;
            this.props.getCommonCommitteeClassificationTypeAndNoVoteReason(this.props.activeCommitteeId, accountNumber);

            this.setState({ showLoader: true, accountNumber: accountNumber }, () => _func.setClassInstance(this));
        }, 1500);       
    }

    OnclickSubcommitteeHandler = (id, index) => {
        let statusDetail = this.state.memberMainSubCommittees[index].SubCommittees.filter(item => item.CommitteeId == id)[0];
        this.props.getCommonCommitteeClassificationTypeAndNoVoteReason(id, this.state.accountNumber);
        this.setState({ activeCommitteeId: id, checkCommitteeStatus: statusDetail.CommitteeStatus, checkMemberStatus: statusDetail.MemberStatus, isMainCommittee: false })
        this.props.getMemberCommitteeDetails({
            accountNumber: this.state.accountNumber,
            CommitteeId: id
        })
        _func.cancelEditMemberCommitteeBasicDetails()
    }

    handleToggleClick = (Index, id) => {
        let toggleCommitteeId = this.state.toggleCommitteeId == id ? 0 : id;
        this.setState({ toggleCommitteeId })
    }

    handleClickHideResponsibilities = () => {
        this.setState({ showResponsibilities: false, parentClicked: true })
    }
    openMemberCommitteeStatusModal = () => {
        this.props.getMemberCommitteeHistoryDetails(this.state.accountNumber, this.state.activeCommitteeId);
        this.setState({ showMemberCommitteeStatusModal: true });
    }

    closeMemberCommitteeHistoryModal = () => {
        this.setState({ showMemberCommitteeStatusModal: false });
    }
    reverseParentClick = () => {
        this.setState({ parentClicked: false })
    }

    render() {        
        const { memberMainSubCommittees, showLoader, activeCommitteeId, toggleCommitteeId, showResponsibilities, showMemberCommitteeStatusModal, isMemberCommitteeBasicDetailsEdit, checkCommitteeStatus, checkMemberStatus, showMemberCommitteeOfficerTitleStatusModal, isMemberCommitteeGeneralInfoEdit, parentClicked, isMainCommittee, isShowClassificationEdit, flagFeature } = this.state;
        const { memberCommitteeDetails, memberCommitteeHistoryList, memberCommitteeOfficerTitleHistoryList, hasEditMemberParticipationOnCommittees, initialValues, userAccess: { hasUserPageAccess, hasFeeGroupViewAccess } } = this.props;
        return (
            <div onClick={this.handleClickHideResponsibilities} data-testid="memberCommitteeDetails">
                {showLoader && (
                    <Loader size='small' className="small-loader">Loading</Loader>
                )}
                <div className="ui container">
                    <a href="javascript:void(0);" className="editBtn" style={{ 'width': '32px' }} onClick={() => this.props.showHide(0)}><Icon name="arrow left" /></a>

                    <div className="">
                        <div className="headingTitle clearfix mt20 mb20">
                            <h2>Committees</h2>
                        </div>
                    </div>
                    <div className="leftPanelAccoridan">
                        <Accordion fluid className="accordion3">
                            {memberMainSubCommittees.length > 0 && memberMainSubCommittees.map((item, index) => {
                                return <React.Fragment>
                                    <Accordion.Title data-testid="toggle" active={toggleCommitteeId == item.CommitteeId} >
                                        {item.SubCommittees.length != 0 ? <span style={{ 'position': 'absolute', 'right': '0', 'top': '0', 'display': 'inline-block', 'z-index': '3', 'width': '26px', 'height': '60px' }} onClick={() => { this.handleToggleClick(index, item.CommitteeId) }}>
                                            <Icon name='dropdown' />
                                        </span> : ''
                                        }
                                        <div data-testid="memberComRow" onClick={(e) => _func.handleClick(e, index, item.CommitteeId)} className={(item.CommitteeId == activeCommitteeId && item.CommitteeStatus !== 'A') ? 'memberComRow clearfix inactive' : (item.CommitteeId == activeCommitteeId && item.CommitteeStatus == 'A') ? 'memberComRow clearfix active' : 'memberComRow clearfix'}>

                                            <div>
                                                <a href="#" className={(item.CommitteeStatus !== 'A' ? 'ellip redTxt' : 'ellip')} >{item.CommitteeName}</a>
                                                <span className="desgName">{item.OfficerTitle}</span>
                                            </div>
                                            <div>
                                                {item.ClassificationType}
                                            </div>
                                            <div>
                                                Vote: {item.IsVote ? 'Yes' : 'No'}
                                            </div>
                                        </div>

                                    </Accordion.Title>
                                    <Accordion.Content active={toggleCommitteeId == item.CommitteeId}>
                                        {item.SubCommittees.map((subItem) => {

                                            return <div data-testid="memberSubComRow" className={(activeCommitteeId == subItem.CommitteeId && subItem.CommitteeStatus !== 'A') ? 'memberComRow clearfix inactive' : (activeCommitteeId == subItem.CommitteeId && subItem.CommitteeStatus == 'A') ? 'memberComRow clearfix active' : 'memberComRow clearfix'} onClick={() => this.OnclickSubcommitteeHandler(subItem.CommitteeId, index)}>
                                                <div>
                                                    <a href="#" className={(subItem.CommitteeStatus !== 'A' ? 'redTxt' : '')}>{subItem.CommitteeName}</a>
                                                    <span className="desgName">{subItem.OfficerTitle}</span>
                                                </div>
                                                <div>
                                                    {subItem.ClassificationType}
                                                </div>
                                                <div>
                                                    Vote: {subItem.IsVote ? 'Yes' : 'No'}
                                                </div>
                                            </div>
                                        })}
                                    </Accordion.Content>
                                </React.Fragment>
                            })}
                        </Accordion>
                    </div>
                    {memberMainSubCommittees.length > 0 && <div className="rightPanelView">
                        {initialValues &&
                            <div className="commonInfoTop">
                                <h3 className="ellip">{_func.getMemberDetails(initialValues).Name} - {_func.getAccountNumber(initialValues)}</h3>
                                <p className="font14">
                                    <a class="linkTxt vAuto" href={`mailto:${_func.getMemberDetails(initialValues).Email}`}>{_func.getMemberDetails(initialValues).Email}</a>
                                    {_func.getMemberDetails(initialValues).Email && _func.getMemberDetails(initialValues).PhoneNumber && < span className="inlineBlock ml5 mr5"> <b>&bull;</b> </span>}
                                    {_func.getMemberDetails(initialValues).PhoneNumber}
                                </p>
                                <a target="_blank" href={`/member-management/members/details/${_func.getAccountNumber(initialValues)}`} className="ui primary button" >View Member Details</a>
                            </div>
                        }
                        <div className="rightPanelDetailDv">
                            {memberCommitteeDetails && memberCommitteeDetails.TopSection &&
                                <div>
                                    {!isMemberCommitteeBasicDetailsEdit ?
                                        <MemberCommitteeBasicDetails
                                            {...this.props}
                                            hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}
                                            activeCommitteeId={activeCommitteeId}
                                            memberCommitteeDetails={memberCommitteeDetails}
                                            MemberId={this.props.MemberId}
                                            showOfficerResponsibilities={showResponsibilities}
                                            hidePopUp={showResponsibilities}
                                            onClickMemberCommitteeStatusHistory={this.openMemberCommitteeStatusModal}
                                            committeeStatus={checkCommitteeStatus}
                                            memberStatus={checkMemberStatus}
                                            isParentClicked={parentClicked}
                                            reverseParentClick={this.reverseParentClick}
                                            onEditClick={() => { _func.editMemberCommitteeBasicDetails(activeCommitteeId) }}
                                            hasFeeGroupViewAccess={hasFeeGroupViewAccess}
                                            onClickMemberCommitteeOfficerTitleStatusHistory={() => { _func.openMemberCommitteeOfficerTitleHistoryModal(activeCommitteeId) }}
                                        />
                                        :
                                        <MemberCommitteeBasicDetailsEdit
                                            {...this.props}
                                            flagFeature={flagFeature}
                                            activeCommitteeId={activeCommitteeId}
                                            onEditCancel={_func.cancelEditMemberCommitteeBasicDetails}
                                        />}
                                </div>
                            }
                            <div className="dashed-border mt10 mb20"></div>
                            {!isMemberCommitteeGeneralInfoEdit && <MemberCommitteeGeneralInfo
                                {...this.props}
                                committeeStatus={checkCommitteeStatus}
                                memberStatus={checkMemberStatus}
                                showEditForm={_func.showHideGeneralInfoEdit} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}
                            />}
                            {isMemberCommitteeGeneralInfoEdit && <MemberCommitteeEditGeneralInfo {...this.props} HideEditForm={_func.showHideGeneralInfoEdit} flagFeature={flagFeature} activeCommitteeId={activeCommitteeId} isMainCommittee={isMainCommittee} onSuccess={_func.getMemberMainSubCommittees} />}
                            {showMemberCommitteeStatusModal &&
                                <MemberCommitteeHistoryDetailsModal hasUserPageAccess={hasUserPageAccess}
                                    memberCommitteeHistoryList={memberCommitteeHistoryList} {...this.props}
                                    onCloseMemberCommitteeHistoryModal={this.closeMemberCommitteeHistoryModal} />
                            }

                            {showMemberCommitteeOfficerTitleStatusModal &&
                                <MemberCommitteeOfficerTitleHistoryDetailsModal hasUserPageAccess={hasUserPageAccess}
                                    memberCommitteeOfficerTitleHistoryList={memberCommitteeOfficerTitleHistoryList} {...this.props}
                                    onCloseMemberCommitteeOfficerTitleHistoryModal={_func.closeMemberCommitteeOfficerTitleHistoryModal} />
                            }
                            {((memberCommitteeDetails.MemberActivity && memberCommitteeDetails.MemberActivity.PrimaryActivityId > 0) || isMainCommittee) && (
                                <div>
                                    <div className=" dashed-border mt10 mb20 "></div>
                                    {isShowClassificationEdit ? (
                                        <MemberCommitteeOrganizationDetailsEdit
                                            {...this.props}
                                            flagFeature={flagFeature}
                                            cancelEdit={_func.showMemberCommitteeOrganizationDetails}
                                            CommitteeId={activeCommitteeId}
                                        />
                                    ) : (
                                        <MemberCommitteeOrganizationDetails
                                            {...this.props}
                                            committeeStatus={checkCommitteeStatus}
                                            hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}
                                            showEdit={_func.showMemberCommitteeOrganizationDetailsEdit}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>}


                </div>
            </div >
        )
    }
}
export default MemberCommitteeDetails;
