import { cacheManager, deepCopy, formatDateToMMDDYYYY, formatDateToMMYYYY, getMessage } from 'helpers/util-common';
import React from "react";
import { Icon } from 'semantic-ui-react';
import CustomTooltip from 'shared-components/Tooltip';
import { MemberCommitteeTableColumn, RefreshMemberCommitteeHeader, RefreshMemberCommitteeTableColumnOnBulkEdit, UpdateCommitteesModel } from '../../members.model';
let self = {};
let accountNo = '';
let committeesModel = [];
let backupMemerCommitteeInfo = [];
let memberCommitteeTableColumninfo = [];
let memberSummaryModel = { AccountNo: '', AccountNumber: '', CommitteeIds: [], CommitteeTitles: '', UpdateReason: '', Filters: [], ImpactedCommitteeTitles: '' }

export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.props.initialValues.AccountNumber;
    memberSummaryModel.AccountNo = accountNo;
    memberSummaryModel.AccountNumber = accountNo;
    memberCommitteeTableColumninfo = MemberCommitteeTableColumn;
}

export const getInitialData = () => {
    self.props.getMasterDataforUpdateCommittee(accountNo, (classificationList, votelist, noReasonList) => {
        UpdateCommitteesModel[0].radioArray = [];
        UpdateCommitteesModel[1].secondRadioArray = [];
        UpdateCommitteesModel[3].radioArray = [];
        classificationList.map(item => UpdateCommitteesModel[0].radioArray.push({ label: item.Name, value: '', id: item.ClassificationTypeId }))
        votelist.map(item => UpdateCommitteesModel[1].secondRadioArray.push({ label: item.Description, value: '', id: item.VoteId }))
        noReasonList.map(item => UpdateCommitteesModel[3].radioArray.push({ label: item.Name, value: '', id: item.InactiveReasonId, CommitteeTypeIds: item.CommitteeTypeIds }))
        committeesModel = UpdateCommitteesModel;
    })
    self.props.getMemberCommitteeInfoList(accountNo, setMemberCommitteeInfoData);
    self.props.getMemberMainCommittees(accountNo, setMemberCommittees);
    resetData();
}

export const resetData = () => {
    self.props.getMemberCommitteeInfoList(accountNo, setMemberCommitteeInfoData);
    self.setState({
        selectedCommittees: [],
        selectedMemberCommitteeStatus: -1,
        selectedCommitteeStatus: -1, showProceed: false, showSelectedCommitteeFilter: false,
        selectClassification: {},
        selectedVote: {},
        memberCommitteeTableColumnInfo: MemberCommitteeTableColumn,
        selectReactivate: {},
        showCommiteeSummary: false,
        memberSummaryModel: {},
        enableUpdateComittees: false
    });
}


export const sortBy = (event, key) => {
    let orderType; // 1 for Ascending order, 0 for descending order
    event.preventDefault();
    if (event.target.attributes.getNamedItem('data-order')) {
        orderType = event.target.attributes.getNamedItem('data-order').value;
    } else {
        orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
    }
    const memberCommitteeData = self.state.memberCommitteeInfo
    if (memberCommitteeData && memberCommitteeData.length > 0) {
        let sortkey = key.split(' ').join('');
        if (orderType === "0") {
            memberCommitteeData.sort((a, b) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));

        }
        else {
            memberCommitteeData.sort((b, a) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));
        }
        self.setState({
            sortKey: sortkey,
            orderType: orderType,
            memberCommitteeInfo: memberCommitteeData
        });
    }
}

export const showHideDetailPage = (activeCommitteeId, MemberId) => {
    let showLeftPannel = !self.state.showLeftPannel;
    self.setState({
        showLeftPannel, activeCommitteeId, showAddcommitteeMember: false, MemberId: MemberId
    }, () => { getInitialData(); });
}

export const OpenAddCommitteeMember = () => {
    RefreshMemberCommitteeHeader();
    let showAddcommitteeMember = !self.state.showAddcommitteeMember;
    self.setState({ showAddcommitteeMember, showLeftPannel: false, isGridEdited: false })
}

export const getIconClass = (row) => {
    if (row.OfficerTitleId > 0) {
        return <td className="comDesign" >
            <CustomTooltip icon={<Icon name="star" />} content={row.OfficerTitleName} />
            <a onClick={() => showHideDetailPage(row.CommitteeId, row.MemberId)}>
                {row.CommitteeDesignation}
            </a>
        </td>;
    }
    else if (row.MemberStatus.toLowerCase() !== 'a' && row.CommitteeStatus.toLowerCase() !== 'a') {
        return <td className="comDesign leftRedBorder"><Icon name="user" className="redIcon" /><a onClick={() => showHideDetailPage(row.CommitteeId, row.MemberId)}>{row.CommitteeDesignation}</a></td>;
    }
    else if (row.MemberStatus.toLowerCase() === 'a' && row.CommitteeStatus.toLowerCase() !== 'a') {
        return <td className="comDesign"><Icon name="user" /><a class="redTxt" style={{ 'color': '#b13036' }} onClick={() => showHideDetailPage(row.CommitteeId, row.MemberId)}>{row.CommitteeDesignation}</a></td>;
    }
    else if (row.MemberStatus.toLowerCase() === 'a' && row.CommitteeStatus.toLowerCase() === 'a') {
        return <td className="comDesign"><Icon name="user" className="blueIcon" /><a onClick={() => showHideDetailPage(row.CommitteeId, row.MemberId)}>{row.CommitteeDesignation}</a></td>;
    }
    else if (row.MemberStatus.toLowerCase() !== 'a') {
        return <td className="comDesign"><Icon name="user" className="redIcon" /><a onClick={() => showHideDetailPage(row.CommitteeId, row.MemberId)}>{row.CommitteeDesignation}</a></td>;
    }
}

export const filterMemberCommitteeList = (data) => {
    let result = [];
    if (self.state.selectedCommittees && self.state.selectedCommittees.length > 0) {
        self.state.selectedCommittees.map((item) => {
            let filter = data.filter(p => (p.RootCommitteeId === item))
            if (filter && filter.length > 0) {
                filter.map((row) => {
                    if (IsRowExists(row, result)) {
                        result.push(row);
                    }
                });
            }
            else {
                result = [];
            }
        });
    }
    else {
        result = data;
    }
    if (self.state.selectedMemberCommitteeStatus > -1) {
        let filter = [];
        if (getStatus(self.state.selectedMemberCommitteeStatus).toLowerCase() !== 'officer') {
            filter = result.filter(p => (p.MemberStatus.toLowerCase() === getStatus(self.state.selectedMemberCommitteeStatus).toLowerCase()))
        }
        else {
            filter = result.filter(p => (p.OfficerTitleId > 0))
        }
        result = filter;
        if (filter && filter.length > 0) {
            filter.map((row) => {
                if (IsRowExists(row, result)) {
                    result.push(row);
                }
            });
        } else {
            result = [];
        }
    }
    if (self.state.selectedCommitteeStatus > -1) {
        let filter = [];
        filter = result.filter(p => (p.CommitteeStatus.toLowerCase() === getStatus(self.state.selectedCommitteeStatus).toLowerCase()))
        result = filter;
        if (filter && filter.length > 0) {
            filter.map((row) => {
                if (IsRowExists(row, result)) {
                    result.push(row);
                }
            });
        } else {
            result = [];
        }
    }
    let sortkey = self.state.sortKey;
    let orderType = self.state.orderType;
    if (result !== undefined && result !== null && result.length > 0 && checkVal(orderType) && checkVal(sortkey)) {
        if (orderType === "0") {
            result.sort((a, b) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));

        }
        else {
            result.sort((b, a) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));
        }
    }
    self.setState({ memberCommitteeInfo: result });
}

export const excelExport = () => {
    self.setState({ loading: true });
    let ExportedData = [];
    if (self.state.memberCommitteeInfo && self.state.memberCommitteeInfo.length > 0) {
        self.state.memberCommitteeInfo.forEach(items => {
            ExportedData.push({
                "CommitteeDesignation": validate(items.CommitteeDesignation),
                "CommitteeTitle": validate(items.CommitteeTitle),
                "Classification": validate(items.Classification),
                "ClassificationDate": validate(items.ClassificationDate) === "" ? "" : formatDateToMMDDYYYY(items.ClassificationDate),
                "Vote": items.Vote ? 'Yes' : 'No',
                "NoVoteReason": validate(items.NoVoteReason),
                "VoteDate": validate(items.VoteDate) === "" ? "" : formatDateToMMDDYYYY(items.VoteDate),
                "CommitteeJoinDate": validate(items.CommitteeJoinDate) === "" ? "" : formatDateToMMDDYYYY(items.CommitteeJoinDate),
                "OfficerTitle": validate(items.OfficerTitleName),
                "OfficerAppointedDate": validate(items.OfficerAppointedDate) === "" ? "" : formatDateToMMYYYY(items.OfficerAppointedDate),
                "OfficerEndDate": validate(items.OfficerEndDate) === "" ? "" : formatDateToMMDDYYYY(items.OfficerEndDate),
                "MemberStatus": validate(items.MemberStatus) === 'A' ? 'Active' : 'Inactive',
                "OfficerTitleId": validate(items.OfficerTitleId)
            });
        });
        ExportedData.sort((a, b) => (a.CommitteeDesignation === null ? "" : a.CommitteeDesignation).localeCompare((b.CommitteeDesignation === null ? "" : b.CommitteeDesignation)));
        let OfficerTitleIds = [];
        self.state.memberCommitteeInfo.map((item) => {
            if (item.OfficerTitleId > 0) {
                OfficerTitleIds.push(item.OfficerTitleId);
            }
        });
        if (OfficerTitleIds.length === 0) {
            OfficerTitleIds.push(0);
        }
        self.props.getMemberOfficerTitle(OfficerTitleIds, (response) => {
            if (response && response.length > 0) {
                ExportedData.forEach(item => {
                    item.OfficerTitle = response.filter(info => info.OfficerTitleId === item.OfficerTitleId).length > 0 ? response.filter(info => info.OfficerTitleId === item.OfficerTitleId)[0].OfficerTitleName : '';
                    item.OfficerAppointedDate = item.OfficerTitleId > 0 ? item.OfficerAppointedDate : '';
                });
            }
            if (ExportedData.length > 0) {
                self.setState({ isExportExcel: true, exportedData: ExportedData, loading: false }, () => resetExcelDownload());
            }
            else {
                self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], loading: false });
            }
        });
    }
    else {
        self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], loading: false });
    }
}


export const emptyExportDataCancelHandler = () => {
    self.setState({ isOpenExcelDownloadConfirmBox: false });
}

export const emptyExportDataConfirmHandler = () => {
    self.setState({ isExportExcel: true, isOpenExcelDownloadConfirmBox: false }, () => { resetExcelDownload() });
}

const resetExcelDownload = () => {
    setTimeout(() => {
        self.setState({ isExportExcel: false });
    }, 100);
}


const checkVal = (value) => {
    let flag = false;
    if (value && value !== undefined && value !== null && value !== '') {
        flag = true;
    }
    return flag;
}

export const getVote = (Vote) => {
    return Vote ? 'Yes' : 'No';
}
const setMemberCommitteeInfoData = (data) => {
    backupMemerCommitteeInfo = data;
    self.setState({ memberCommitteeInfo: data, initMemberCommitteeInfo: data });
}

const setMemberCommittees = (data) => {
    let result = [];
    RefreshMemberCommitteeHeader();
    data.map((items) => {
        result.push({ key: items.CommitteeId, text: items.Code, value: items.CommitteeId });
    });
    self.setState({ committeeOptions: result });
}

const validate = (value) => {
    return value ? value : '';
}

const getStatus = (value) => {
    let result = 'All';
    switch (value) {
        case -1:
            result = 'All';
            break;
        case 0:
            result = 'I';
            break;
        case 1:
            result = 'A';
            break;
        case 2:
            result = 'Officer';
            break;
        default:
            result = -1;
    }
    return result;
}

const IsRowExists = (row, result) => {
    let flag = false;
    if (result && result.length > 0 && row && row) {
        if (result.filter(p => (p === row)).length > 0) {
            flag = false;
        }

        else {
            flag = true
        }

    }
    else {
        flag = true;
    }
    return flag;

}

export const showHideUpdateCommittees = (e) => {
    e.stopPropagation();
    let enableUpdateComittees = !self.state.enableUpdateComittees;
    let tempCommitteesModel = deepCopy(committeesModel)
    tempCommitteesModel.map(item=>{
        if(self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17 && [1, 2].includes(item.id)){
            item.isEnable= false;
        }
        return item;
    })
    self.setState({ enableUpdateComittees, updateCommitteesModel: tempCommitteesModel, showProceed: false })
}
export const resetCommitteesFilter = (e) => {
    e.stopPropagation();
    let tempCommitteesModel = deepCopy(committeesModel)
    tempCommitteesModel.map(item=>{
        if(self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17 && [1, 2].includes(item.id)){
            item.isEnable= false;
        }
        return item;
    })
    self.setState({ updateCommitteesModel: tempCommitteesModel, showProceed: false, showSelectedCommitteeFilter: false, memberCommitteeInfo: backupMemerCommitteeInfo, memberCommitteeTableColumnInfo: deepCopy(MemberCommitteeTableColumn) });
}
export const resetSelectedCommitteesFilter = (e) => {
    e.stopPropagation();
    self.setState({ showSelectedCommitteeFilter: false, memberCommitteeInfo: backupMemerCommitteeInfo, memberCommitteeTableColumnInfo: MemberCommitteeTableColumn });
}
export const onChangeCommitteesModel = (e, itemInfo) => {
    e.stopPropagation();
    let actionStatus = !itemInfo.status;
    let tempCommitteesModel = ([4].includes(itemInfo.id) && actionStatus) ? deepCopy(committeesModel.map(item=>{
        if(self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17 && [1, 2].includes(item.id)){
            item.isEnable= false;
        }
        return item;
    })) : self.state.updateCommitteesModel;

    let actionId = itemInfo.id;
    let statusCount = 0;
    switch (actionId) {
        case 1:
            tempCommitteesModel.map(item => {
                if ([2].includes(item.id)) {
                    item.status = item.radioArray.filter(radioItem => ((radioItem.label === 'Yes' && radioItem.value === true) || (radioItem.label === 'No' && radioItem.value === true && item.secondRadioArray.filter(secondItem => secondItem.value).length > 0))).length > 0
                    statusCount = item.status ? ++statusCount : statusCount;
                }
                if (item.id === itemInfo.id) {
                    item.status = !item.status
                }
                if (item.id === 4) {
                    item.isEnable = !actionStatus;
                }
                if (!item.status) {
                    item.radioArray.map(radioItem => radioItem.value = '')
                }

            })
            break;
        case 2:
            tempCommitteesModel.map(item => {
                if ([1].includes(item.id)) {
                    item.status = item.radioArray.filter(radioItem => radioItem.value === true).length > 0;
                    statusCount = item.status ? ++statusCount : statusCount;
                }

                if (item.id === itemInfo.id) {
                    item.status = !item.status
                }
                if (item.id === 4) {
                    item.isEnable = !actionStatus;
                }
                if (!item.status) {
                    item.radioArray.map(radioItem => radioItem.value = '')
                    if (item.id === 2) {
                        item.secondRadioArray.map(radioItem => radioItem.value = '')
                    }

                }
            })
            break;
        case 3:
            tempCommitteesModel.map(item => {

                if ([1].includes(item.id)) {
                    if (self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17) {
                      if (actionStatus) {
                        item.radioArray.map((radioItem) => {
                          if (radioItem.label === "Unclassified") {
                            radioItem.value = true;
                          }
                          return radioItem;
                        });
                        item.status = actionStatus;
                        statusCount = item.status ? ++statusCount : statusCount;
                        if (!item.status) {
                          item.radioArray.map((radioItem) => (radioItem.value = ""));
                        }
                      } else {
                        item.status = actionStatus;
                        item.radioArray.map((radioItem) => {
                          radioItem.value = false;
                          return radioItem;
                        });
                      }
                    } else {
                      item.status = item.radioArray.filter((radioItem) => radioItem.value === true).length > 0;
                      statusCount = item.status ? ++statusCount : statusCount;
                      if (!item.status) {
                        item.radioArray.map((radioItem) => (radioItem.value = ""));
                      }
                    }

                }
                if ([2].includes(item.id)) {
                    if (self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17) {
                      if (actionStatus) {
                        item.status = true;
                        item.radioArray.map((radioItem) => {
                          if (radioItem.label === "No") {
                            radioItem.value = true;
                          }
                          return radioItem;
                        });
                        item.secondRadioArray.map((radioItem) => {
                          if (radioItem.label === "Non Voter") {
                            radioItem.value = true;
                          }
                          return radioItem;
                        });
                        statusCount = item.status ? ++statusCount : statusCount;
                        if (!item.status) {
                          item.radioArray.map((radioItem) => (radioItem.value = ""));
                        }
                      } else {
                        item.status = actionStatus;
                        item.radioArray.map((radioItem) => {
                          radioItem.value = false;
                          return radioItem;
                        });
                        item.secondRadioArray.map((radioItem) => {
                          radioItem.value = false;
                          return radioItem;
                        });
                      }
                    } else {
                      item.status = item.radioArray.filter((radioItem) => (radioItem.label === "Yes" && radioItem.value === true) || (radioItem.label === "No" && radioItem.value === true && item.secondRadioArray.filter((secondItem) => secondItem.value === true).length > 0)).length > 0;
                      statusCount = item.status ? ++statusCount : statusCount;
                      if (!item.status) {
                        item.radioArray.map((radioItem) => (radioItem.value = ""));
                      }
                    }

                }

                if (item.id === 4) {
                    item.isEnable = !actionStatus;
                }
                if (item.id === itemInfo.id) {
                    item.status = !item.status;
                }
            })
            break;
        case 4:
            tempCommitteesModel.map(item => {
                if (actionStatus && item.id !== actionId) {
                    item.status = false;
                    item.isEnable = false;
                } else {
                    item.isEnable = !(self.state.flagFeature?.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === 17 && [1, 2].includes(item.id)) ;
                }
                if (item.id === itemInfo.id) {
                    item.status = !item.status
                }
            })
            break;
        default:
            break;
    }
    if (self.state.updateCommitteesModel.filter(item => item.id === 3 && item.status === true).length > 0) {
        if (self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true && item.radioArray.filter(radioItem => ((radioItem.label === 'Yes' && radioItem.value === true) || (radioItem.label === 'No' && radioItem.value === true && item.secondRadioArray.filter(secondItem => secondItem.value).length > 0))).length > 0).length > 0
            && self.state.updateCommitteesModel.filter(item => item.id === 1 && item.status === true && item.radioArray.filter(radioItem => radioItem.value === true).length > 0).length > 0) {
            statusCount = 1;
        } else {
            statusCount = 0;
        }
    }
    self.setState({ updateCommitteesModel: tempCommitteesModel, showProceed: statusCount > 0, showSelectedCommitteeFilter: false })
}
export const changeVoteStatus = (e, index, subIndex, actionType) => {
    e.stopPropagation();
    let showProceed = self.state.showProceed
    let tempCommitteesModel = self.state.updateCommitteesModel;


    if (actionType === 1) {
        tempCommitteesModel[index].radioArray.map(item => item.value = false)
        tempCommitteesModel[index].radioArray[subIndex].value = true;
        if (tempCommitteesModel[index].radioArray[subIndex].label !== 'No') {
            showProceed = true;
        } else if (tempCommitteesModel[index].radioArray[subIndex].label === 'No') {
            tempCommitteesModel[index].secondRadioArray.map(item => item.value = '');
            showProceed = false;
        }
    } else if (actionType === 2) {
        tempCommitteesModel[index].secondRadioArray.map(item => item.value = false);
        tempCommitteesModel[index].secondRadioArray[subIndex].value = true;
        showProceed = true;
    }
    if (self.state.updateCommitteesModel.filter(item => item.id === 3 && item.status === true).length > 0) {
        if (self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true && item.radioArray.filter(radioItem => ((radioItem.label === 'Yes' && radioItem.value === true) || (radioItem.label === 'No' && radioItem.value === true && item.secondRadioArray.filter(secondItem => secondItem.value).length > 0))).length > 0).length > 0
            && self.state.updateCommitteesModel.filter(item => item.id === 1 && item.status === true && item.radioArray.filter(radioItem => radioItem.value === true).length > 0).length > 0) {
            showProceed = true;
        } else {
            showProceed = false;
        }
    }
    self.setState({ updateCommitteesModel: tempCommitteesModel, showProceed })
}
export const proceedCommitteeFilter = (e) => {
    e.stopPropagation();
    let showSelectedCommitteeFilter = !self.state.showSelectedCommitteeFilter;
    let selectClassification = {};
    let selectReactivate = {};
    let selectNoReason = {};
    let selectedVote = {};
    // RefreshMemberCommitteeHeader();
    RefreshMemberCommitteeTableColumnOnBulkEdit();
    memberSummaryModel.Filters = [];
    if (self.state.updateCommitteesModel.filter(item => item.id === 1 && item.status === true).length > 0 && self.state.updateCommitteesModel.filter(item => item.id === 1 && item.status === true)[0].radioArray.filter(item => item.value === true).length > 0) {
        let classification = self.state.updateCommitteesModel.filter(item => item.id === 1 && item.status === true)[0].radioArray.filter(item => item.value === true)[0]
        selectClassification = { 'title': classification.label, 'id': classification.value, committeeTypeIds: 0 };
    }
    if (self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true && item.radioArray.filter(radioItem => radioItem.label === 'Yes' && radioItem.value === true).length > 0).length > 0) {
        let selectedVotewithYes = self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true)[0].radioArray.filter(item => item.label === 'Yes' && item.value === true)[0];
        selectedVote = { 'title': selectedVotewithYes.label, 'id': selectedVotewithYes.value, committeeTypeIds: 0 }
    } else if (self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true && item.radioArray.filter(radioItem => radioItem.label === 'No' && radioItem.value === true && item.secondRadioArray.filter(secondItem => secondItem.value === true).length > 0).length > 0).length > 0) {
        let selectedVotewithNo = self.state.updateCommitteesModel.filter(item => item.id === 2 && item.status === true && item.radioArray.filter(radioItem => radioItem.label === 'No' && radioItem.value === true).length > 0)[0].secondRadioArray.filter(secondItem => secondItem.value === true)[0];
        selectedVote = { 'title': `${'No-' + selectedVotewithNo.label}`, 'id': selectedVotewithNo.value, committeeTypeIds: 0 };
    }
    if (self.state.updateCommitteesModel.filter(item => item.id === 3 && item.status === true).length > 0) {
        let reactivate = self.state.updateCommitteesModel.filter(item => item.id === 3 && item.status === true)[0];
        selectReactivate = { 'title': reactivate.title, 'id': reactivate.id, committeeTypeIds: 0 };
    }
    if (self.state.updateCommitteesModel.filter(item => item.id === 4 && item.status === true).length > 0 && self.state.updateCommitteesModel.filter(item => item.id === 4 && item.status === true)[0].radioArray.filter(item => item.value === true).length > 0) {
        let noReason = self.state.updateCommitteesModel.filter(item => item.id === 4 && item.status === true)[0].radioArray.filter(item => item.value === true)[0]
        selectNoReason = { 'title': noReason.label, 'id': noReason.value, committeeTypeIds: noReason.CommitteeTypeIds };
    }

    self.setState({ showSelectedCommitteeFilter, selectClassification, selectedVote, selectReactivate, selectNoReason }, () => {
        let requestData = { accountNumber: accountNo, filters: [] };
        if (self.state.updateCommitteesModel && self.state.updateCommitteesModel.length > 0) {
            self.state.updateCommitteesModel.map(item => {
                if (item.status === true) {
                    switch (item.id) {
                        case 1:// For classification selection
                            item.radioArray.map(radioItem => {
                                if (radioItem.value === true) {
                                    requestData.filters.push({ Action: 'classification', Id: radioItem.id, Status: item.status });
                                    memberSummaryModel.Filters.push({ Action: 'classification', Id: radioItem.id, Status: item.status, title: selectClassification.title, ActionId: 1, CommitteeTypeIds: 0 })
                                }

                            })
                            break;
                        case 2: // For  Vote status selection
                            item.radioArray.map(radioItem => {
                                if (radioItem.label === 'Yes' && radioItem.value === true) {
                                    requestData.filters.push({ Action: 'vote', Id: radioItem.id, Status: item.status, CommitteeTypeIds: 0 });
                                    memberSummaryModel.Filters.push({ Action: 'vote', Id: radioItem.id, Status: true, title: selectedVote.title, ActionId: 2 });
                                } else if (radioItem.label === 'No' && radioItem.value === true) {
                                    item.secondRadioArray.map(secondItem => {
                                        if (secondItem.value === true) {
                                            requestData.filters.push({ Action: 'vote', Id: secondItem.id, Status: false, CommitteeTypeIds: 0 });
                                            memberSummaryModel.Filters.push({ Action: 'vote', Id: secondItem.id, Status: false, title: selectedVote.title, ActionId: 2 });

                                        }
                                    })
                                }

                            })
                            break;
                        case 3: // For  Reactive selection
                            if (item.status) {
                                requestData.filters.push({ Action: 'reactivated', Id: 0, Status: item.status, CommitteeTypeIds: 0 });
                                memberSummaryModel.Filters.push({ Action: 'reactivated', Id: 0, Status: false, title: selectedVote.title, ActionId: 3 });

                            }
                            break;
                        case 4:// For inactive selection

                            item.radioArray.map(radioItem => {
                                if (radioItem.value === true) {
                                    requestData.filters.push({ Action: 'inactivated', Id: radioItem.id, Status: item.status, CommitteeTypeIds: radioItem.CommitteeTypeIds });
                                    memberSummaryModel.Filters.push({ Action: 'inactivated', Id: radioItem.id, Status: item.status, title: selectNoReason.title, ActionId: 4 })
                                }

                            })
                            break;
                        default:
                            break;
                    }
                }
            })
        }

        memberSummaryModel.CommitteeTitles = '';
        memberSummaryModel.UpdateReason = '';
        memberSummaryModel.ImpactedCommitteeTitles = '';

        self.props.getMemberCommitteeListBultEdit(requestData, (response) => {
            let responsedata = response;

            if (responsedata && responsedata.length > 0) {
                responsedata = responsedata.map(item => {
                    item.isChecked = false;
                    return item;
                });

                self.setState({ memberCommitteeInfo: responsedata, memberSummaryModel: {}, memberCommitteeTableColumnInfo: deepCopy(MemberCommitteeTableColumn.filter(item => item.title != 'Action')), selectedMemberCommitteeStatus: -1, selectedCommittees: -1, selectedCommitteeStatus: -1 })
            } else {

                self.setState({ memberCommitteeInfo: [], memberCommitteeTableColumnInfo: deepCopy(MemberCommitteeTableColumn.filter(item => item.title != 'Action')), selectedMemberCommitteeStatus: -1, selectedCommittees: -1, selectedCommitteeStatus: -1 })
            }

        });
    })
}

export const isGridEdit = (isGridEdited) => {
    self.setState({ isGridEdited: isGridEdited })
}
export const OnSelectMemerCommittee = (e, index) => {
    e.stopPropagation();
    let memberCommitteeInfo = self.state.memberCommitteeInfo;
    let hasParentCount = 0;
    if (memberCommitteeInfo[index].isChecked && (((self.state.selectNoReason.title && self.state.selectNoReason.title != '' && memberCommitteeInfo[index].ParentCommitteeId > 0) || (self.state.selectReactivate.title && self.state.selectReactivate.title != '')))) {
        memberCommitteeInfo.map(item => {
            if (self.state.selectNoReason.title && self.state.selectNoReason.title != '') {
                if (item.CommitteeId == memberCommitteeInfo[index].ParentCommitteeId && item.isChecked) {
                    hasParentCount++;

                }
            }
            if (self.state.selectReactivate.title && self.state.selectReactivate.title != '') {
                if (item.ParentCommitteeId == memberCommitteeInfo[index].CommitteeId && item.isChecked) {
                    hasParentCount++;

                }
            }

        })
    }
    if (hasParentCount > 0) {
        if (self.state.selectReactivate.title && self.state.selectReactivate.title != '') {
            self.props.showAlertPopup(getMessage(self.props.messageCodes, '9047.text'));
        }
        if (self.state.selectNoReason.title && self.state.selectNoReason.title != '') {
            self.props.showAlertPopup(getMessage(self.props.messageCodes, '9046.text'));
        }
    }
    else {

        memberCommitteeInfo[index].isChecked = !memberCommitteeInfo[index].isChecked;
        let childCommittees = []
        memberSummaryModel.CommitteeIds = [];
        memberSummaryModel.CommitteeTitles = '';
        memberCommitteeInfo.map(item => {
            // For getting parent Id
            if (self.state.selectNoReason.title && self.state.selectNoReason.title != '') {
                if (item.ParentCommitteeId == memberCommitteeInfo[index].CommitteeId) {
                    childCommittees.push({ id: item.CommitteeId })
                    item.isChecked = (self.state.selectNoReason.title && self.state.selectNoReason.title != '') ? memberCommitteeInfo[index].isChecked : item.isChecked;
                }
            }
            if (self.state.selectReactivate.title && self.state.selectReactivate.title != '') {
                if (item.CommitteeId == memberCommitteeInfo[index].ParentCommitteeId) {
                    childCommittees.push({ id: item.ParentCommitteeId })
                    item.isChecked = (self.state.selectReactivate.title && self.state.selectReactivate.title != '') ? true : item.isChecked;
                }
            }
            if (item.isChecked) {
                if (memberSummaryModel.CommitteeTitles == '') {
                    memberSummaryModel.CommitteeTitles = item.CommitteeDesignation;
                } else {
                    memberSummaryModel.CommitteeTitles += ', ' + item.CommitteeDesignation;
                }
                memberSummaryModel.CommitteeIds.push(item.CommitteeId)
            }

            memberSummaryModel.UpdateReason = '';
            memberSummaryModel.ImpactedCommitteeTitles = '';
        })

        if ((self.state.selectNoReason.title && self.state.selectNoReason.title != '') || (self.state.selectReactivate.title && self.state.selectReactivate.title != '')) {
            do {
                let childIds = [];
                if (childCommittees.length > 0) {
                    childCommittees.map(item =>
                        memberCommitteeInfo = memberCommitteeInfo.map(item2 => {
                            if (self.state.selectNoReason.title && self.state.selectNoReason.title != '') {
                                if (item2.ParentCommitteeId === item.id) {
                                    childIds.push({ id: item2.CommitteeId })
                                    item2.isChecked = memberCommitteeInfo[index].isChecked;
                                    if (item2.isChecked) {
                                        if (memberSummaryModel.CommitteeTitles == '') {
                                            memberSummaryModel.CommitteeTitles = item2.CommitteeDesignation;
                                        } else {
                                            memberSummaryModel.CommitteeTitles += ', ' + item2.CommitteeDesignation;
                                        }
                                        memberSummaryModel.CommitteeIds.push(item2.CommitteeId)
                                    }
                                }
                            }
                            if (self.state.selectReactivate.title && self.state.selectReactivate.title != '') {
                                if (item2.CommitteeId === item.id) {
                                    childIds.push({ id: item2.ParentCommitteeId })
                                    item2.isChecked = true;
                                    if (item2.isChecked) {
                                        if (memberSummaryModel.CommitteeTitles == '') {
                                            memberSummaryModel.CommitteeTitles = item2.CommitteeDesignation;
                                        } else {
                                            memberSummaryModel.CommitteeTitles += ', ' + item2.CommitteeDesignation;
                                        }
                                        memberSummaryModel.CommitteeIds.push(item2.CommitteeId)
                                    }
                                }
                            }
                            return item2;
                        })

                    )
                    childCommittees = childIds;

                }
                if (childCommittees && childCommittees.length == 0) {
                    self.setState({ memberCommitteeInfo })
                }
            } while (childCommittees.length > 0)
        } else { self.setState({ memberCommitteeInfo }) }
    }
}
export const showHideMemberCommitteeSummary = (e, action) => {
    if (e) {
        e.stopPropagation();
    }

    switch (action) {
        case 1:// For cancel
            self.setState({ showCommiteeSummary: false })
            break;
        case 2: // Update
            self.setState({ showCommiteeSummary: false }, getInitialData())
            break;
        default:
            memberSummaryModel.AccountNo = accountNo;
            memberSummaryModel.AccountNumber = accountNo;
            if (memberSummaryModel.Filters.filter(item => item.ActionId === 1 && item.title.toLowerCase() === "producer").length > 0) {
                self.props.getImpactedCommitteeList(memberSummaryModel, (response) => {
                    memberSummaryModel.ImpactedCommitteeTitles = response;
                    let showCommiteeSummary = true;
                    self.setState({ showCommiteeSummary, memberSummaryModel });

                })
            } else {
                let showCommiteeSummary = !self.state.showCommiteeSummary;
                self.setState({ showCommiteeSummary, memberSummaryModel });
            }

            break;
    }


}

export const selectAll = (e) => {
    e.stopPropagation();
    let memberCommitteeInfo = self.state.memberCommitteeInfo;
    let count = memberCommitteeInfo.filter(item => item.isChecked === true).length;
    memberCommitteeInfo = memberCommitteeInfo.map(item => {
        item.isChecked = count === 0;
        if (item.isChecked) {
            if (memberSummaryModel.CommitteeTitles == '') {
                memberSummaryModel.CommitteeTitles = item.CommitteeDesignation;
            } else {
                memberSummaryModel.CommitteeTitles += ', ' + item.CommitteeDesignation;
            }
            memberSummaryModel.CommitteeIds.push(item.CommitteeId)
        }
        return item;
    })
    self.setState({ memberCommitteeInfo, memberSummaryModel })
}

export const setDefaultSortingIcon = () => {
    self.setState({ orderType: 0 });
}
