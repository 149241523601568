import _ from 'lodash';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

class DropdownMultipleSelection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            noResultMessage: '',
            search: '',
            autoFocus: false,
            isSearch: props.isSearch
        }

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onChangeWithoutReduxHandler = this.onChangeWithoutReduxHandler.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        this.loadEvent();
    }

    loadEvent = () => {
        if (_.get(this.refs, "dropdownRef") && _.get(this.refs.dropdownRef, "ref") && _.get(this.refs.dropdownRef.ref, "current")) {
            this.refs.dropdownRef.ref.current.addEventListener('keydown', this.menuCloseOnEnterKey, true)
        }
        document.addEventListener('click', this.clickHandlerOutSideDetected, true); // : Handle for outside click
    }

    clickHandlerOutSideDetected = (e) => {
        if (_.get(this.refs, "dropdownRef") && _.get(this.refs.dropdownRef, "ref") && _.get(this.refs.dropdownRef.ref, "current") &&
            _.get(this.refs.dropdownRef.ref.current, 'childNodes') && this.refs.dropdownRef.ref.current.childNodes.length > 1) {
            if (this.refs.dropdownRef.ref.current.childNodes[1].contains(e.target)) {
                this.setState({
                    autoFocus: false
                }, () => this.handleCursor());

            } else if (!this.refs.dropdownRef.ref.current.contains(e.target)) {
                this.setState({
                    autoFocus: false
                });
            }
        }
    }

    menuCloseOnEnterKey = (e) => {
        let autoFocus = true;
        if (e.keyCode === 13) {
            autoFocus = false;
        }

        this.setState({ autoFocus });
    }

    inputChangeHandler = (value) => {
        if (value !== null && value.length === 0) {
            this.setState({ autoFocus: false })
        }
    }

    focusClickHandler = () => {
        // :For handleCursor reset cursor while pass type : 2
        this.setState({ autoFocus: true }, () => this.handleCursor(2))
    }

    handleCursor = (type = 1) => {
        if (_.get(this.refs.dropdownRef.ref.current, 'childNodes') && this.refs.dropdownRef.ref.current.childNodes.length > 0) {
            if (type === 1) {
                this.refs.dropdownRef.ref.current.childNodes[0].setAttribute('style', "caret-color:transparent")
            } else if (type === 2) {
                this.refs.dropdownRef.ref.current.childNodes[0].removeAttribute('style');
            }
        }
    }

    // Get value from redix form field
    onChangeHandler(e, data) {
        const { isMultiple, input, options, maxLength } = this.props;

        input.onChange(data.value);

        if (isMultiple) {
            // In case we have selected all the values
            if (data.value && options.length === data.value.length) {
                this.setState({ noResultMessage: '' });
            }

            // In case we have selected max allowed values
            if (data.value && maxLength && data.value.length === maxLength) {
                this.setState({ isSearch: false })
            } else {
                this.setState({ isSearch: true })
            }

            // Setting auto focus false to close the dropdown when 'All' is selected
            if (data.value && data.value.length > 0 && data.value.indexOf(0) !== -1) {
                this.setState({
                    autoFocus: false
                }, () => this.handleCursor());
            }
        }
        // In case of single select, close the dropdown when a value is selected
        else if (data.value) {
            this.setState({ autoFocus: false })
        }
    }

    onChangeWithoutReduxHandler(e, data) {
        this.props.onChange(data.value);

        if (data.value && this.props.options.length === data.value.length) {
            this.setState({ noResultMessage: '' });
        }

        // Update the focus state on change always false.
        this.setState({ autoFocus: false })
    }

    onSearchChange(e, data) {
        if (this.props.onSearchChange) {
            this.props.onSearchChange(data)
        }
        if (data.searchQuery !== '') {
            this.setState({
                noResultMessage: `"${data.searchQuery.trim()}"`,
                autoFocus: true
            });
        }
        this.handleCursor(2) // :For reset cursor while pass type : 2
    }

    componentWillUnmount() {
        if (_.get(this.refs, "dropdownRef") && _.get(this.refs.dropdownRef, "ref") && _.get(this.refs.dropdownRef.ref, "current")) {
            this.refs.dropdownRef.ref.current.removeEventListener('keydown', this.menuCloseOnEnterKey, true)
        }
        document.removeEventListener('click', this.clickHandlerOutSideDetected, true);
    }

    render() {
        // For redux form implementation
        if ('input' in this.props) {
            const { enable, options, customSearchHandler, renderLabel, isMultiple, disabled, placeholder, noResultFoundMessage, customOverrideMessage, input: { value, name }, label, required, meta: { touched, error }, isSinglItemPrevent, customError, showNoErrorMessage } = this.props;
            const { isSearch, autoFocus } = this.state;
            return (
                <div data-testid={name} ref='MultiSelectDropDown' className={isSinglItemPrevent && value && value.length === 1 ? 'hideDeleteIcon' : ''}>
                    <label className="label" htmlFor={name}>
                        {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                        {label}
                    </label>
                    <Dropdown
                        name={name}
                        id={name}
                        ref="dropdownRef"
                        open={autoFocus}
                        value={value}
                        placeholder={placeholder}
                        onChange={this.onChangeHandler} fluid
                        multiple={isMultiple}
                        search={customSearchHandler || isSearch}
                        selection
                        onClick={this.focusClickHandler}
                        renderLabel={renderLabel}
                        options={options}
                        disabled={disabled}
                        onSearchChange={this.onSearchChange}
                        noResultsMessage={showNoErrorMessage ? null : (customOverrideMessage ? customOverrideMessage : (this.state.noResultMessage.length > 0 ? ((noResultFoundMessage && noResultFoundMessage !== '') ? noResultFoundMessage : `Found no matching records for ${this.state.noResultMessage}`) : 'No record exists.'))}
                        className={touched && error ? "error" : ''} />
                    {customError && customError.showError && <span className="errorMessage mt0">{customError.errorMessage}</span>}
                    {touched && (error && <span className="errorMessage mt0">{error}</span>)}
                </div>
            );

        }

        // Without redux form implementation
        const { options, label, required, placeholder, value, isSinglItemPrevent, isSearch, isMultiple, disabled } = this.props;
        return (
            <div ref='MultiSelectDropDown' className={isSinglItemPrevent && value && value.length === 1 ? 'hideDeleteIcon' : ''}>
                {label && (
                    <label className="label">
                        {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                        {label}
                    </label>
                )}
                <Dropdown
                    value={value}
                    placeholder={placeholder}
                    fluid
                    selection
                    options={options}
                    search={isSearch}
                    onChange={this.onChangeWithoutReduxHandler}
                    multiple={isMultiple}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export default DropdownMultipleSelection;
