import * as common from 'helpers/util-common';
import lodash from "lodash";
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon } from 'semantic-ui-react';
import DateTimeForm from 'shared-components/DatePicker';
import RenderRadioField from 'shared-components/RadioBox';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import * as _func from './function';


class MemberCommitteeEditGeneralInfo extends Component {
    state = {
        classificationList: [],
        noVoteReasonList: [],
        selectedVote: this.props.memberCommitteeDetails.GeneralInformation.NoVoteReason,
        selectedVoteStatus: lodash(this.props.memberCommitteeDetails.GeneralInformation).get('IsOfficialVote') ? this.props.memberCommitteeDetails.GeneralInformation.IsOfficialVote.toString() : 'false',
        maxDate: common.formatDateToMMDDYYYY(),
        isUpdatePopUp: false,
        disableFieldForStudent: false
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }
    render() {
        const { messageCodes, memberCommitteeDetails: { JoinAsAffiliateDate, TopSection: { CommitteeJoinDate } }, HideEditForm, handleSubmit, submitting, isMainCommittee, MemberTypeId } = this.props;
        const { selectedVoteStatus, maxDate, classificationList, noVoteReasonList, isUpdatePopUp, disableFieldForStudent } = this.state;
        return (

            <section className="subCommGenInfo">
                <Form size='large' onSubmit={handleSubmit(_func.saveMemberCommittee)} noValidate data-testid="memberCommitteeEditGeneralInfo">
                    <h5>General Information

                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button title="Cancel" className="closeBtn" onClick={HideEditForm}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="classificationId" type="text"
                                    component={RenderSelect} label="Classification"
                                    required='true' options={classificationList}
                                    onChange={_func.onChangeClassification}
                                    disabled={disableFieldForStudent}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field name="ClassificationDate"
                                    component={DateTimeForm} label="Classification Assigned Date"
                                    showTime={false}
                                    required={true}
                                    normalize={common.dateMasking}
                                    maxDate={maxDate}
                                    minDate={common.formatDateToMMDDYYYY(CommitteeJoinDate)}
                                    value={maxDate}
                                    readOnly={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="label"><i aria-hidden="true" className="asterisk  icon"></i>Official Vote</span>
                                <Field name="isOfficialVote"
                                    component={RenderRadioField}
                                    className="mr10"
                                    type="radio" onChange={_func.onChangeVoteStatus}
                                    options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                    selectedValue={selectedVoteStatus}
                                    isDisabled={disableFieldForStudent}
                                />

                            </Grid.Column>
                            <Grid.Column>
                                {selectedVoteStatus == 'false' && <Field name="noVoteReason" type="text"
                                    component={RenderSelect} label="No Vote Reason"
                                    required='true' onChange={_func.onChangeNoVoteReason}
                                    options={noVoteReasonList}
                                    disabled={disableFieldForStudent}

                                />}
                                {/* {selectedVoteStatus == 'true' && false &&
                                    <Field name="voteDate"
                                        component={DateTimeForm} label="Vote Date"
                                        showTime={false}
                                        required={selectedVoteStatus === 'true'}
                                        normalize={common.dateMasking}
                                        maxDate={maxDate}
                                        value={maxDate}
                                        onChange={this.validateStartDate}
                                        readOnly={false}
                                    />
                                } */}
                                {selectedVoteStatus == 'true' && <div style={{ height: '60px' }}>&nbsp;</div>}
                            </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="voteDate"
                                    component={DateTimeForm} label="Vote Assigned Date"
                                    showTime={false}
                                    required={true}
                                    normalize={common.dateMasking}
                                    maxDate={maxDate}
                                    minDate={common.formatDateToMMDDYYYY(CommitteeJoinDate)}
                                    value={maxDate}
                                    readOnly={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {isMainCommittee && <Grid divided='vertically' className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="rosterNotes" type="text"
                                    component={TextArea} label="Main Committee Roster Notes"
                                    maxLength="1000" placeholder="Type here.." required={false} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>}
                    {MemberTypeId === 4 && // If the membership type of the member is AFFILIATE
                        <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="affiliateDate"
                                        component={DateTimeForm} label="Joined as Affiliate Date"
                                        showTime={false}
                                        maxDate={maxDate}
                                        minDate={common.formatDateToMMDDYYYY(CommitteeJoinDate)}
                                        required='true'
                                        readOnly={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                    <Grid divided='vertically' className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="updateReason" type="text"
                                    component={TextArea} required={true} label="Reason for Update"
                                    maxLength="500" placeholder="Type here.." />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Confirm className="confirm-box"
                        data-testid="confirm-box"
                        open={isUpdatePopUp}
                        content={common.getMessage(messageCodes, '9044.text')}
                        onCancel={_func.popUpCancel}
                        onConfirm={_func.popUpConfirm}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />
                </Form>
            </section >

        )
    }
}
export default (reduxForm({
    form: 'MemberCommitteeEditGeneralInfo',
    validate: _func.validateHandler
})(MemberCommitteeEditGeneralInfo));
