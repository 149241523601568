/**
 * Table Data and Interaction Settings
 * ---
 * attributes: the attributes showed on the table, whose the boolean values determind whether showing on the table at first.
 * expandables: the data of the attributes could be expand when clicking.
 * interactables: the data of attributes have actions.
 * currencies: the data of the attributes should be transformed to currency format.
 * hasDetails: the data of the attributes have more detail, such as google map link.
 * filterables: the attributes could be filtered.
 * omitOnMneu: the attributes not used on the context menu.
 */

import { cacheManager, capitalize, deepCopy, encrypt, formatDateToMMDDYYYY, formatDateToMMYYYY, getListItemTextByValue, phoneNumberMask, routeNavigationUrl } from 'helpers/util-common';
import lodash from "lodash";
import { AppGridIds, GridColumns, McsStatusMasterId, minDateForInputDateFields } from 'models/common.models';
import { CommitteeClassificationOptions, CommitteeRosterVoteOptions } from 'modules/committee-management/committee.model';
import { MembershipTypes } from 'modules/member-management/members/members.model';
import moment from 'moment';
import React, { Fragment } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group'; // ES6
import { Link } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import DateTimePicker from 'shared-components/DatePicker';
import RenderSelect from 'shared-components/Select';
import DgActionMenu from './dgActionMenu';
import DgMenu from './dgMenu';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import { utcDateTimeToLocalDateTime } from '../../helpers/util-common';
import { EXCEPTION_STATUS_FILTERS, PendingNovoteReasonID, NoVoteReasonIdForEmpty } from '../../models/common.models';
import { membershipTypeJSON } from '../../modules/member-management/members/members.model';

class CustomDataGrid extends React.Component {

    isFirstSort = true;

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            isRedirect: false,
            records: deepCopy(props.gridSetting.records),
            attributes: props.gridSetting.attributes,
            expandables: props.gridSetting.expandables,
            sortableColumns: props.gridSetting.sortables,
            interactables: props.gridSetting.interactables ? props.gridSetting.interactables : [],
            currencies: props.gridSetting.currencies,
            hasDetails: props.gridSetting.hasDetails,
            filterables: props.gridSetting.filterables,
            omitOnMenu: props.gridSetting.omitOnMenu,
            exportableFields: props.gridSetting.excelExportableFieldsCallback,
            displayName: props.gridSetting.displayName,
            allowTooltip: props.gridSetting.allowTooltip ? props.gridSetting.allowTooltip : false,
            sortAttribute: '',
            focusCell: {
                recordId: '',
                attribute: ''
            },
            lastExpanded: '',
            showMenu: false,
            showAction: false,
            activeSortColumn: (props.activeSortColumn && props.activeSortColumn.name) ? props.activeSortColumn : { name: '', orderByIcon: 0 },
            rowIndex: -1,
            isFixedHeaderMenu: false,
            tableHeaderBackUp: props.gridSetting.attributes,
            isEditMode: props.isEditMode || false,
            fixedHeaderOnTop: lodash(this.props).get('fixedHeaderOnTop', null) ? this.props.fixedHeaderOnTop : 150, // 150 is default value on full page from Top,
            isTableOnFullWindow: lodash(this.props).get('isTableOnFullWindow', false) ? this.props.isTableOnFullWindow : false,
            checkedOptionsMenuList: null,
            minDate: lodash(this.props.initialValues).get('EstablishmentDate') ? formatDateToMMDDYYYY(this.props.initialValues.EstablishmentDate) : formatDateToMMDDYYYY(minDateForInputDateFields),
            selectedNoVoteReason: PendingNovoteReasonID,
            isDisableFieldsForStudentMember: false
        };
        // Set this.toggleExpendableCol = null default value for toggle click cells columns
        this.toggleExpendableCol = null;
    }

    componentDidMount() {
        // While resize the grid
        this.loadScrollEvent();
        this.setNotExpendableColumnWidth();
        this.enableScrollingFun(true);
        this.setState({ isEditMod: false });
        this.addTableClassForTooltip();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedNoVoteReason !== PendingNovoteReasonID) {
            this.setState({ selectedNoVoteReason: PendingNovoteReasonID });
        }
        const isStudentMember = this.props.flagFeature && this.props.flagFeature.StudentCommitteeManagement && this.props.modelEntity && this.props.modelEntity.MemberDetail_MemberType === membershipTypeJSON.student.displayName.toUpperCase();
        if (prevState.isDisableFieldsForStudentMember !== isStudentMember) {
            this.setState({ isDisableFieldsForStudentMember: isStudentMember });
        }
    }

    // TODO : Add dynamic class for dynamic grid.
    addTableClassForTooltip = () => {
        const { gridId } = this.props;
        let table = document.getElementById('customGrid');
        switch (gridId) {
            case AppGridIds.CommitteeRosterGrid:
                table.classList.add('rosterCustomGrid')
                break;
            default:
            // No Class Added.
        }
    }

    loadMouseOverEvents = (ele) => {
        const { allowTooltip } = this.state;
        // TODO : Check allow tooltip on grid.
        if (allowTooltip) {
            let currentElement = ele.target;
            if (currentElement.offsetWidth < currentElement.scrollWidth && !currentElement.getAttribute('title')) {
                let span = document.createElement('span');
                span.innerText = currentElement.innerText;
                let currentElementChild = currentElement.children;
                if (currentElementChild.length == 0) {
                    currentElement.appendChild(span);
                    currentElement.classList.add('hasTooltip');
                }
            }
        }
    }

    loadMouseOutEvents = (ele) => {
        // TODO : Remove dynamic element on mouse-out.
        const { allowTooltip } = this.state;
        let currentElement = ele.target;
        if (allowTooltip) {
            let currentElementChild = currentElement.children;
            if (currentElementChild.length > 0) {
                currentElement.children[0].remove();
                currentElement.classList.remove('hasTooltip');
            }
        }
    }


    loadScrollEvent = () => {
        const { fixedHeaderOnTop } = this.state;
        window.addEventListener('scroll', this.getTableScrollPosition.bind(null, fixedHeaderOnTop), true)
    }

    setMenuPositionOnFixedHeader = (isFixed, top = 90) => { // Set default 93px from Top
        if (this.getMenuRef()) {
            if (isFixed) {
                this.getMenuRef().setAttribute("style", "top:" + top + "px;position:fixed;right:28px;")
            } else {
                this.getMenuRef().setAttribute("style", "position:absolute")
            }
        }
    }

    fullWindowGridValidate = () => { // Validate if grid is in full window mode
        const { isTableOnFullWindow } = this.state;
        if (isTableOnFullWindow && this.getGridRef() && lodash(this.getGridRef()).get("parentElement", null) && lodash(this.getGridRef().parentElement).get("parentElement", null)) {
            let selector = this.getGridRef().parentElement;
            let parentSelector = selector.parentElement;
            let classList = lodash(parentSelector).get('classList', null);
            if (classList && classList.contains('tableWrapper')) {
                classList.add('grid-relative-position')
            }
        }
    }

    getTableScrollPosition = (fixedHeaderOnTop, event) => {
        let target = event.target;
        let headSelector = this.getTripleDotOption(this.getGridRef());
        let headerRef = this.getHeaderRef(this.getGridRef());
        if (target && headSelector) {
            let TOP = headerRef.getBoundingClientRect().top;
            if (TOP < 0) {
                for (let i = 0; i < headSelector.length; i++) {
                    let isClass = lodash(headSelector[i]).get('classList', null);
                    if (isClass) {
                        isClass.add('stickyHeader');
                    }
                }

                this.setState({
                    isFixedHeaderMenu: true
                }, () => this.setMenuPositionOnFixedHeader(true))
            } else {
                for (let i = 0; i < headSelector.length; i++) {
                    let isClass = lodash(headSelector[i]).get('classList', null);
                    if (isClass) {
                        isClass.remove('stickyHeader');
                    }
                }

                this.setState({
                    isFixedHeaderMenu: false
                })
                this.setMenuPositionOnFixedHeader(false)
            }

        }
    }


    getGridRef = () => {
        return document.getElementById('customGrid')
    }

    getTripleDotOption = (selector) => {
        if (selector) {
            return selector.querySelectorAll('thead th');
        }
        return null;
    }

    getHeaderRef = (selector) => {
        if (selector) {
            return selector.querySelector('thead');
        }
        return null;
    }

    getMenuRef = () => {
        return document.querySelector('.dg-menu');
    }

    enableScrollingFun = (isEnable) => {
        let selector = this.getGridRef();
        let columnCount = this.getVisibleColumnCount();
        if (selector) {
            if (isEnable) {
                if (columnCount < 15) {
                    selector.setAttribute('style', 'overflow-x:hidden');
                } else {
                    selector.setAttribute('style', 'overflow-x:auto');
                }
            } else {
                selector.setAttribute('style', 'overflow-x:auto');
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { checkedOptionsMenuList } = this.state;
        // Hide Menu while preferenced Saved
        if (nextProps.isShowGridMenuEvent !== this.props.isShowGridMenuEvent) {
            this.setState({
                showMenu: !nextProps.isShowGridMenuEvent
            });
        }
        if (nextProps.isDataUpdated) {
            this.props.resetDataUpdatedFlag();
            this.setState({ records: nextProps.gridSetting.records });
            if (checkedOptionsMenuList !== null) {
                this.setState({
                    attributes: checkedOptionsMenuList
                }, () => this.state.exportableFields(checkedOptionsMenuList))
            }
        }
    }

    getVisibleColumnWidth = () => {
        const { attributes } = this.state;
        let dotOptionSelectorCount = 0;
        let columnCount = 0;
        let tableClientWidth = 0;
        for (let keys in attributes) {
            if (attributes[keys] === true) {
                columnCount++;
            }
        }
        let clientWidthSelector = this.getGridRef();
        if (clientWidthSelector) {
            let dotOptionsSelector = this.getTripleDotOption(clientWidthSelector);
            if (dotOptionsSelector) {
                dotOptionSelectorCount = lodash(dotOptionsSelector[dotOptionsSelector.length - 1]).get('offsetWidth', 0);
            }
            tableClientWidth = Number(clientWidthSelector.clientWidth) - dotOptionSelectorCount;
        }
        return tableClientWidth / columnCount;
    }

    getVisibleColumnCount = () => {
        const { attributes } = this.state;
        let columnCount = 0;
        for (let keys in attributes) {
            if (attributes[keys] === true) {
                columnCount++;
            }
        }
        return columnCount;
    }

    // Hide width while set new menu on grid
    validateWidthOnSelectedMenu = (attributes) => {
        let selectorCustomGrid = this.getGridRef();
        if (attributes !== null && selectorCustomGrid) {
            let tableWidth = this.getVisibleColumnWidth();
            for (let key in attributes) {
                let selector = document.querySelector('.header--' + this.extractColumnNameForCssClass(key));
                if (selector) {
                    selector.removeAttribute('style');
                    selector.setAttribute('style', 'width:' + tableWidth + 'px');
                    if (this.state.isFixedHeaderMenu) {
                        selector.classList.add('stickyHeader')
                    } else {
                        selector.classList.remove('stickyHeader')
                    }
                }
            }
        }
    }

    // Set Width for all column
    setNotExpendableColumnWidth = () => {
        const { attributes } = this.state;
        if (attributes !== null) {
            let tableWidth = this.getVisibleColumnWidth();
            // Set Fixed width selected column which are not expending
            for (let key in attributes) {
                let selector = document.querySelector('.header--' + this.extractColumnNameForCssClass(key));
                if (selector) {
                    selector.removeAttribute('style');
                    selector.setAttribute('style', 'width:' + tableWidth + 'px');
                }
            }
        }
    }

    getNotExpendableColumn = (col) => {
        const { expandables } = this.state;
        if (expandables.indexOf(col) === -1) {
            return true;
        }
        return false;
    }

    emptyColumnNotExpendableHandler = (column) => {
        let columnSize = column ? column.length : 0;
        let blankColumnCount = 0;
        if (column && columnSize > 0) {
            for (let i = 0; i < columnSize; ++i) {
                if (lodash(column[i]).get('innerText') === "") {
                    blankColumnCount++;
                }
            }
        }
        if (blankColumnCount === columnSize) {
            return false;
        }
        return true;
    }

    columnWrapAndNoWrap = (isExpendable, colSelector) => {
        if (isExpendable) {
            if (colSelector !== null && colSelector.length > 0) {
                for (let i = 0; i < colSelector.length; ++i) {
                    colSelector[i].setAttribute('style', 'white-space:normal');
                }
            }
        } else if (!isExpendable && colSelector !== null && colSelector.length > 0) {
            for (let i = 0; i < colSelector.length; ++i) {
                colSelector[i].setAttribute('style', 'white-space:nowrap');
            }
        }
    }

    calculateNeighbourWidth = (attribute, isExpendable, colSelector) => {
        const { attributes } = this.state;
        let dotOptionSelectorCount = 0
        let dotOptionsSelector = this.getTripleDotOption(this.getGridRef());
        if (dotOptionsSelector) {
            dotOptionSelectorCount = lodash(dotOptionsSelector[dotOptionsSelector.length - 1]).get('offsetWidth', 0);
        }
        let totalWidth = Number(this.getGridRef().clientWidth) - dotOptionSelectorCount;
        let columnCount = this.getVisibleColumnCount();
        let singleColumnCount = totalWidth / columnCount;
        let expendableColumnWidth = singleColumnCount * 2;
        let remainingColumnTotalWidth = (totalWidth - expendableColumnWidth) / (columnCount - 1);
        if (isExpendable) {
            for (let key in attributes) {
                if (key === attribute) {
                    let selector = document.querySelector('.header--' + this.extractColumnNameForCssClass(key));
                    selector = selector ? selector.setAttribute('style', 'width:' + expendableColumnWidth + 'px') : '';
                } else {
                    let selector = document.querySelector('.header--' + this.extractColumnNameForCssClass(key));
                    selector = selector ? selector.setAttribute('style', 'width:' + remainingColumnTotalWidth + 'px') : '';
                }
            }
        } else {
            this.setNotExpendableColumnWidth();
        }

    }

    // Toggle functionality for all headers in grid
    expandableToggleColumn = (attribute) => {
        const { expandables } = this.state;
        let headerSelector = document.querySelector(`.header--${this.extractColumnNameForCssClass(attribute)}`);
        // Get max length of InnerText
        let colSelector = document.querySelectorAll('.column--' + this.extractColumnNameForCssClass(attribute));
        // If column are blank, then validate the column and not expendables
        let isColumnExpendable = this.emptyColumnNotExpendableHandler(colSelector);
        if (headerSelector && expandables.indexOf(attribute) > -1 && isColumnExpendable) {
            let dataExpandableValue = JSON.parse(headerSelector.getAttribute('data-expendable'));
            let isExpandables = !dataExpandableValue;
            this.calculateNeighbourWidth(attribute, isExpandables, colSelector);
            this.columnWrapAndNoWrap(isExpandables, colSelector);
            headerSelector.setAttribute('data-expendable', isExpandables);
        }

    }

    filteredAttributes = () => {
        let self = this;
        return Object.keys(self.state.attributes).filter((el) => {
            return self.state.attributes[el]
        })
    };

    headerClass = (attribute) => {
        let self = this;
        if (self.state.expanding !== attribute && self.state.expandables.includes(attribute)) {
            return 'header--expandable'
        }
        return '';
    };

    maxLenOfCols = () => { // Maxiumn length of each attribute

        let self = this;
        let records = self.state.records
        let attributes = self.state.attributes;

        return records.reduce((acc, record) => {
            Object.keys(record).forEach((prop) => {
                if (record[prop] && acc[prop] < record[prop].length) {
                    acc[prop] = record[prop].length
                }
            })
            return acc
        }, Object.keys(attributes).reduce((acc, prop) => {
            acc[prop] = 0
            return acc
        }, {}))
    };

    changeSortIcon = (attribute, status) => {
        let self = this;

        // Return if the column is not sortable
        if (!this.state.sortableColumns[attribute]) {
            return;
        }
        let activeSortColumn = self.state.activeSortColumn;
        activeSortColumn.name = attribute;

        // Since the default records are coming as asc, we need to get desc records on first click
        if (self.props.gridId === AppGridIds.CommitteeGrid && attribute === 'CommitteeDesignation' && this.isFirstSort) {
            activeSortColumn.orderByIcon = status === 0 ? 2 : status === 1 ? 2 : status === 2 ? 1 : 0;
        } else {
            activeSortColumn.orderByIcon = status === 0 ? 1 : status === 1 ? 2 : status === 2 ? 1 : 0;
        }
        this.isFirstSort = false;
        self.setState({ activeSortColumn: activeSortColumn, sortAttribute: attribute });

        // In case we need to refresh the data from backend.
        if (self.props.onChangeSort) {
            return self.props.onChangeSort(attribute, activeSortColumn.orderByIcon);
        }

        let records = self.state.records;
        let isString = (typeof records[0][attribute] == "string");

        switch (activeSortColumn.orderByIcon) {
            case 1:

                records.sort((a, b) => {
                    if (isString) {
                        return a[attribute].localeCompare(b[attribute])
                    }

                    return moment(a[attribute]).isBefore(b[attribute]) ? -1 : 1
                });
                break;
            case 2:

                records.reverse((a, b) => {
                    if (isString) {
                        return a[attribute].localeCompare(b[attribute])
                    }

                    return moment(a[attribute]).isBefore(b[attribute]) ? -1 : 1

                });
                break;
            default:

        }
        self.setState({ records: records })

    }


    onCellClick = (attribute, id, event) => {
        // --> Click Event not work on not Expendable column found
        if (this.getNotExpendableColumn(attribute)) {
            return false;
        }

        this.expandableToggleColumn(attribute);
        return false;
    };

    openMenu = (event) => {
        event.stopPropagation()
        let showMenu = !this.state.showMenu;
        const { isFixedHeaderMenu } = this.state;
        this.setState({ showMenu, showAction: false }, () => this.setMenuPositionOnFixedHeader(isFixedHeaderMenu))
    };

    getCheckedMenuList = (options) => {
        this.validateWidthOnSelectedMenu(options);
        this.state.exportableFields(options);
        this.setState({
            checkedOptionsMenuList: Object.assign({}, options)
        })
    }

    hidecolumn = options => {
        this.setState({ attributes: options }, () => this.getCheckedMenuList(options))
    };

    openSecondTab = (MemberId) => {
        cacheManager.setItem('memberCommitteeInfo', this.props.selectedCommitteeId);
        cacheManager.setItem('memberId', MemberId);
        cacheManager.setItem('committeeRosterInfo', routeNavigationUrl(this.props.detailsPageUrl2, null, [encrypt(this.props.selectedCommitteeId)]))
    }

    showHideMenu = (e, item) => {
        let records = this.state.records;
        let rowIndex = records.findIndex(index => index === item);
        let isRowEdit = !records[rowIndex].isRowEdit;
        records.map(record => {
            record.isRowEdit = false;
            return record;
        })
        records[rowIndex].isRowEdit = isRowEdit;
        if (isRowEdit) {
            this.props.onEditChange(undefined, item)

        } else {
            this.props.onEditChange(undefined, undefined)
        }

        this.setState({ records })

    }
    handleOnChange = (e, value) => {
        this.setState({ selectedNoVoteReason: value });
        this.props.onEditChange('voteReason', value);
    };

    // Used to return cell based on column name and other conditions
    renderCell = (columnName, row) => {
        let self = this;
        let columnValue = lodash(row).get(columnName);
        let cssClassName = `column--${this.extractColumnNameForCssClass(columnName)}`;

        if (columnName === GridColumns.Email || columnName === GridColumns.MemberDetail.Email) {
            return <a onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={`${cssClassName}`} href={"mailto:" + columnValue}>{columnValue}</a>
        } else if (self.props.gridId === AppGridIds.MemberGrid ||
            self.props.gridId === AppGridIds.OrganizationGrid) { // Links for member and organization list page
            let membershipTypeId = lodash(row).get(GridColumns.MemberDetail.MemberTypeId);

            if (columnName === GridColumns.MemberDetail.AccountNumber && self.props.detailsPageUrl && self.props.detailsPageUrl.length > 0) {
                if (membershipTypeId !== MembershipTypes.Organizational) {
                    let companyId = lodash(row).get(GridColumns.MemberDetail.MemberId);
                    let orgAccountOrEncryptCompanyId = columnValue === '##' ? encrypt(`${companyId}##true`) : columnValue;
                    return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl, null, [orgAccountOrEncryptCompanyId])}`} style={this.pTagStyle}> {columnValue}</Link>
                } else if (membershipTypeId === MembershipTypes.Organizational) {
                    return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl, null, [columnValue])}`} style={this.pTagStyle}> {columnValue}</Link>
                }
            } else if (self.props.gridId === AppGridIds.MemberGrid && columnName === GridColumns.MemberDetail.FacilityName && columnValue &&
                (membershipTypeId === MembershipTypes.Representative || membershipTypeId === MembershipTypes.cooperativeAgreementRep) && self.props.detailsPageUrl2) {
                let orgAccountNumber = lodash(row).get(GridColumns.MemberDetail.OrgAccountNumber)
                return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl2, null, [orgAccountNumber])}`} style={this.pTagStyle}> {columnValue}</Link>
            } else if (columnName === GridColumns.MemberDetail.RepMemberName && columnValue) {
                let repAccountNumber = lodash(row).get(GridColumns.MemberDetail.RepAccountNumber)
                return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl2, null, [repAccountNumber])}`} style={this.pTagStyle}> {columnValue}</Link>
            } else if (columnName === GridColumns.MemberDetail.PhoneNumber && columnValue) {
                let maskedPhoneNumber = phoneNumberMask(columnValue);
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {maskedPhoneNumber}</p>
            }
        } else if (self.props.gridId === AppGridIds.CommitteeGrid) { // Links for committee list page
            if (columnName === GridColumns.CommitteeDetail.CommitteeDesignation) {
                let committeeId = encrypt(lodash(row).get(GridColumns.CommitteeDetail.CommitteeId));
                return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl, null, [committeeId])}`} style={this.pTagStyle}> {columnValue}
                </Link>
            } else if (columnName === GridColumns.CommitteeDetail.CommitteeClassification && columnValue) {
                let value = getListItemTextByValue(CommitteeClassificationOptions, columnValue, 'text');
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {value}</p>
            } else if (columnName === GridColumns.CommitteeDetail.CommitteeTitle) {
                const status = lodash(row).get(GridColumns.CommitteeDetail.Status);
                const inactiveReason = lodash(row).get(GridColumns.CommitteeDetail.InactiveReason);
                const inactiveDate = lodash(row).get(GridColumns.CommitteeDetail.InactiveDate);
                const mergedCommittee = lodash(row).get(GridColumns.CommitteeDetail.MergedCommittee);

                let committeeInactive = (status === McsStatusMasterId.InactiveCommmittee && (inactiveReason || '').toLowerCase() === "merged") ? (`${'Merged with ' + (mergedCommittee || 'Merged')} on ` + formatDateToMMYYYY(inactiveDate || new Date())) :
                    (status === McsStatusMasterId.InactiveCommmittee && (inactiveReason || '').toLowerCase() === "discharged") ? (`${'Discharged on ' + formatDateToMMYYYY(inactiveDate || new Date())}`) : '';

                committeeInactive = committeeInactive !== '' ? columnValue + ` (${committeeInactive})` : columnValue;
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {committeeInactive}</p>
            } else if (columnName === GridColumns.CommitteeDetail.Chairman) {
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue}</p>
            }
        } else if (self.props.gridId === AppGridIds.CommitteeRosterGrid) {
            if (columnName === GridColumns.MemberDetail.AccountNumber) {
                let memberId = lodash(row).get(GridColumns.MemberDetail.MemberId);
                let accountNumber = lodash(row).get(GridColumns.MemberDetail.AccountNumber);
                return <a onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} /* to={`${routeNavigationUrl(self.props.detailsPageUrl, null, [columnValue])}`} */ style={this.pTagStyle} onClick={() => this.props.showHideFullPoup(memberId, accountNumber)}> {columnValue}</a>
            } else if (columnName === GridColumns.MemberDetail.OfficerTitleName) {
                if (row.isRowEdit) {
                    return <DropdownMultipleSelection
                        name={'officerTitleDropdown'}
                        value={this.props.modelEntity.MemberDetail_OfficerTitleId ? this.props.modelEntity.MemberDetail_OfficerTitleId : ''}
                        onChange={(value) => this.props.onEditChange('officer', value)}
                        options={self.props.masterData.officerTitleList}
                        isSearch={true}
                        placeholder="Search by Officer Title"
                        isMultiple={false}
                        disabled={self.state.isDisableFieldsForStudentMember}
                    />
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue}</p>
            } else if (columnName === GridColumns.MemberDetail.Classification) {
                if (row.isRowEdit) {
                    return <RenderSelect
                        name={'classificationDropdown'}
                        value={this.props.modelEntity.MemberDetail_ClassificationTypeId}
                        onChange={(e, value) => this.props.onEditChange('classification', value)}
                        options={self.props.masterData.classificationList}
                        disabled={self.state.isDisableFieldsForStudentMember}
                    />
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue}</p>
            }
            else if (columnName === GridColumns.MemberDetail.NoVoteReason) {
                const isVoteZero = this.props.modelEntity.MemberDetail_Vote === 0;
                const isNoVoteReasonSeven = this.props.modelEntity.MemberDetail_NoVoteReasonId === NoVoteReasonIdForEmpty;
                const selectValue = isVoteZero ? (isNoVoteReasonSeven ? this.state.selectedNoVoteReason : this.props.modelEntity.MemberDetail_NoVoteReasonId) : '';
                if (row.isRowEdit) {
                    return <div className={this.props.modelEntity.NoVoteReasonError ? 'validationError' : ''}> <RenderSelect
                        name={'noVoteReasonDropdown'}
                        value={selectValue}
                        onChange={this.handleOnChange}
                        options={self.props.masterData.noVoteReasonList}
                        disabled={this.props.modelEntity.MemberDetail_Vote === 1 || self.state.isDisableFieldsForStudentMember}
                    /></div>
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue}</p>
            }
            else if (columnName === GridColumns.MemberDetail.Vote) {
                if (row.isRowEdit) {
                    return <RenderSelect
                        name={'voteDropdown'}
                        value={this.props.modelEntity.MemberDetail_Vote}
                        onChange={(e, value) => this.props.onEditChange('vote', value)}
                        options={CommitteeRosterVoteOptions.filter(item => item.text !== 'All')}
                        disabled={self.state.isDisableFieldsForStudentMember}
                    />
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue ? 'Yes' : !columnValue ? 'No' : ''}</p>
            }
            else if (columnName === GridColumns.MemberDetail.JoinedDate) {
                let joinedDate = columnValue && columnValue !== '' ? formatDateToMMDDYYYY(columnValue) : columnValue;
                if (row.isRowEdit) {
                    return <DateTimePicker
                        name={'committeeJoinDate'}
                        label=""
                        showTime={false}
                        defaultValue={this.props.modelEntity.MemberDetail_JoinedDate}
                        initialDate={this.props.modelEntity.MemberDetail_JoinedDate}
                        maxDate={formatDateToMMDDYYYY()}
                        minDate={this.state.minDate}
                        readOnly={false}
                        onChange={(e, value) => this.props.onEditChange('joinDate', value)}
                    />

                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {joinedDate}</p>
            }
            else if (columnName === GridColumns.MemberDetail.ClassificationDate) {
                let ClassificationDate = columnValue && columnValue !== '' ? formatDateToMMDDYYYY(columnValue) : columnValue;
                if (row.isRowEdit) {
                    return <p className={cssClassName} style={this.pTagStyle}> <DateTimePicker
                        name={'classificationDate'}
                        label=""
                        showTime={false}
                        defaultValue={this.props.modelEntity.MemberDetail_ClassificationDate}
                        initialDate={this.props.modelEntity.MemberDetail_ClassificationDate}
                        maxDate={formatDateToMMDDYYYY()}
                        minDate={this.props.modelEntity.MemberDetail_JoinedDate}
                        readOnly={false}
                        onChange={(e, value) => this.props.onEditChange('classificationDate', value)}
                    /></p>
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {ClassificationDate}</p>
            }
            else if (columnName === GridColumns.MemberDetail.VoteDate) {
                let votedate = columnValue && columnValue !== '' ? formatDateToMMDDYYYY(columnValue) : columnValue;
                if (row.isRowEdit) {
                    return <p className={cssClassName} style={this.pTagStyle}> <DateTimePicker
                        name={'voteDate'}
                        label=""
                        showTime={false}
                        defaultValue={this.props.modelEntity.MemberDetail_VoteDate}
                        initialDate={this.props.modelEntity.MemberDetail_VoteDate}
                        maxDate={formatDateToMMDDYYYY()}
                        minDate={this.props.modelEntity.MemberDetail_JoinedDate}
                        readOnly={false}
                        onChange={(e, value) => this.props.onEditChange('voteDate', value)}
                    /></p>
                }
                return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {votedate}</p>
            }
            else if (columnName === GridColumns.Action) {
                if (row.isRowEdit) {
                    return <div >
                        <a className="updateBtn mr10" title="Update" data-testid="updateBtn" onClick={this.props.OnUpdateRecored}><Icon name="check" /></a>
                        <a className="closeBtn" title="Close" onClick={(e) => this.showHideMenu(e, row)}><Icon name="close" /></a>
                    </div>
                }

                let status = lodash(row).get(GridColumns.MemberDetail.MemberCommitteeStatus);
                let hasEditableColumn = Object.keys(self.state.attributes)
                    .filter(item => self.state.attributes[item] === true &&
                        Object.keys(self.props.gridSetting.editableGridHeader).filter(item1 => item1 === item && (item1 !== GridColumns.Action && item1 !== GridColumns.MemberDetail.AccountNumber))
                            .length > 0)
                    .length > 0;

                return status && status.toLowerCase() === 'active' && this.props.hasEditMemberParticipationOnCommittees ?
                    (
                        <a className={hasEditableColumn ? "editBtn" : "editBtn pointer-events"} title="Edit" onClick={(e) => this.showHideMenu(e, row)}><Icon name="pencil" /></a>
                    ) : '';
            }
        } else if (self.props.gridId === AppGridIds.MembershipException) {
            if (columnName === GridColumns.ExceptionDetails.AccountNumber && self.props.detailsPageUrl && self.props.detailsPageUrl.length > 0) {
                return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl, null, [columnValue])}`} style={this.pTagStyle}> {columnValue}</Link>
            }
            if (columnName === GridColumns.ExceptionDetails.EncounteredOn) {
                return <p className={`${cssClassName}`}>{utcDateTimeToLocalDateTime(columnValue)}</p>
            }
            if (columnName === GridColumns.ExceptionDetails.Status) {
                const { isUpdate, editableStatus } = this.props;
                if (row.isRowEdit) {
                    return (
                        <div className={`dropdownWidthAuto`} >
                            <div className="inlineBlock mr10 vMiddle">
                                <RenderSelect
                                    name={'grid_exception_status'}
                                    value={editableStatus}
                                    onChange={(e, value) => this.props.onEditChange('status', value)}
                                    options={EXCEPTION_STATUS_FILTERS.filter(item => item.text !== 'All')}
                                />
                            </div>
                            <div className="actionGrid" >
                                <a className="updateBtn mr5" title="Update" onClick={(e) => this.props.OnUpdateRecored(e, row, editableStatus)}><Icon name="check" /></a>
                                <a className="closeBtn" title="Close" onClick={(e) => this.showHideMenu(e, row, true, columnName)}><Icon name="close" /></a>
                            </div>
                        </div>
                    )
                }
                return (
                    <div>
                        {columnValue}
                        {isUpdate && < a className="editBtn floatRight ml10" title="Edit" onClick={(e) => this.showHideMenu(e, row, true, columnName)}> <Icon name="pencil" /></a>}
                    </div >
                );
            }
            if (columnName === GridColumns.ExceptionDetails.ModifiedBy && self.props.detailsPageUrl2 && self.props.detailsPageUrl2.length > 0) {
                const modifiedByIds = lodash(row).get('ModifiedByIds');
                return <Link onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} to={`${routeNavigationUrl(self.props.detailsPageUrl2, null, [modifiedByIds])}`} style={this.pTagStyle}> {columnValue}</Link>
            }
        }

        return <p onMouseOut={(evt) => this.loadMouseOutEvents(evt)} onMouseOver={(evt) => this.loadMouseOverEvents(evt)} className={cssClassName} style={this.pTagStyle}> {columnValue}</p >
    }

    // Used it to show a red border when the member status is Historical
    isShowRedBorder = (index, row) => {
        const { gridId } = this.props;

        if (index === 0) {
            let showRedBorder = false;
            let status = '';

            switch (gridId) {
                case AppGridIds.MemberGrid:
                case AppGridIds.OrganizationGrid:
                    status = lodash(row).get(GridColumns.MemberDetail.McsStatusMasterId);
                    showRedBorder = status === McsStatusMasterId.HistoricalMember || status === McsStatusMasterId.InactiveMember;
                    break;
                case AppGridIds.CommitteeGrid:
                    status = lodash(row).get(GridColumns.CommitteeDetail.Status)
                    showRedBorder = status === McsStatusMasterId.InactiveCommmittee;
                    break;
                case AppGridIds.CommitteeRosterGrid:
                    status = lodash(row).get(GridColumns.MemberDetail.MemberCommitteeStatusId);
                    showRedBorder = status === McsStatusMasterId.InactiveCommitteeMember;
                    break;
                default:
                    showRedBorder = false;
            }

            return showRedBorder ? 'leftRedBorder' : '';
        }

        return '';
    }

    extractColumnNameForCssClass = (columnName) => {
        if (columnName) {
            let list = columnName.split('.');

            if (list && list.length > 1) {
                return list[1];
            }

            return list[0];
        }

        return '';
    }

    render() {
        const { displayName, records, showAction, showMenu, omitOnMenu, attributes, sortableColumns, activeSortColumn, isFixedHeaderMenu } = this.state;
        const { hidecolumn } = this;
        const { gridSetting: { emptyMessage }, gridId, isUpdate } = this.props;
        return (

            <div className="tableWrapper relative">
                <TransitionGroup component="tr">
                    <CSSTransition classNames="fade">

                        <table className="customTable advanceGridTable" id="customGrid" data-testid="customGrid">
                            <thead>
                                <tr>
                                    {this.filteredAttributes().map((attribute) => {
                                        return (attributes[attribute] &&
                                            (
                                                <React.Fragment>
                                                    {(attribute !== GridColumns.Action || (attribute === GridColumns.Action && this.props.hasEditMemberParticipationOnCommittees)) &&
                                                        <th style={{ position: 'sticky', top: '50px' }} data-expendable={false} className={'header  header--' + this.extractColumnNameForCssClass(attribute) + ' ' + this.headerClass(attribute)}
                                                            key={'header--' + attribute}
                                                            onClick={() => { this.changeSortIcon(attribute, (activeSortColumn.name === attribute ? activeSortColumn.orderByIcon : 0)) }}>
                                                            {displayName !== null && displayName !== undefined ? capitalize(displayName[attribute]) : attribute}
                                                            {sortableColumns[attribute] && (
                                                                <i aria-hidden="true" className={activeSortColumn.name === attribute && activeSortColumn.orderByIcon === 1 ? 'long arrow alternate up icon activeSort' : activeSortColumn.name === attribute && activeSortColumn.orderByIcon === 2 ? 'long arrow alternate down icon activeSort' : 'sort icon'} ></i>
                                                            )}
                                                        </th>}
                                                </React.Fragment>))

                                    })}
                                    {!this.props.isEditMode && <th data-testid="customizeTableCol" className={showMenu ? `customizeTableCol active ${isFixedHeaderMenu ? 'stickyHeader' : ''}` : `customizeTableCol ${isFixedHeaderMenu ? 'stickyHeader' : ''}`} onClick={(e) => this.openMenu(e)}>
                                        <span><i aria-hidden="true" className="icon ellipsis vertical"></i></span>
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {(records && records.length > 0) && (
                                    records.map((record, index) => {
                                        return <tr>
                                            {this.filteredAttributes().map((attribute, i) => {
                                                return <React.Fragment>
                                                    {(attribute !== GridColumns.Action || (attribute === GridColumns.Action && this.props.hasEditMemberParticipationOnCommittees)) &&

                                                        <td key={'cell--' + attribute} className={`${this.isShowRedBorder(i, record)}`} onClick={(event) => { this.onCellClick(attribute, record.uid, event) }}>
                                                            {this.renderCell(attribute, record)}
                                                        </td>
                                                    }
                                                </React.Fragment>
                                            })}

                                            {!this.props.isEditMode && <td>&nbsp;</td>}
                                        </tr>
                                    })
                                )}

                            </tbody>

                            <tbody />
                        </table>

                    </CSSTransition >
                </TransitionGroup>
                {
                    (records && records.length <= 0) && (
                        <div className="noRecordMessage">
                            {emptyMessage}
                        </div>
                    )
                }
                {
                    showMenu && (

                        < DgMenu gridId={gridId} saveGridPerferenceClickAction={this.props.saveGridPerferenceClickAction} options={attributes} displayName={displayName} escaped={omitOnMenu} hidecolumn={hidecolumn} onClose={(e) => this.openMenu(e)} isUpdate={isUpdate}></DgMenu>
                    )
                }
                {
                    showAction && (
                        <DgActionMenu options={attributes} escaped={omitOnMenu} hidecolumn={hidecolumn}></DgActionMenu>
                    )
                }

            </div >

        );
    }
}
export default CustomDataGrid;
