import { getMessage, checkIfDateValid, formatDateToMMDDYYYY, validateEmail } from 'helpers/util-common';
import { primaryActivityKey, membershipTypeJSON } from '../../members.model';
import { forEach, isEmpty } from 'lodash';

let self = {};
let studentMembershipId = 17;
export const setClassInstance = (instance) => {
    self = instance;
    getAllPrimaryActivities();
    setActivityData();
}

const setActivityData = () => {
    let ActivityData = self.props.memberCommitteeDetails.MemberActivity;
    if (ActivityData.PrimaryActivityId && ActivityData.PrimaryActivityId > 0) {
        self.props.change('PrimaryActivityId', ActivityData.PrimaryActivityId);
        self.setState({
            selectedIsMultipleOrganizations: 'false',
            selectedIsProducerOrganization: 'true',
            selectedIsAdvocacyGroup: 'true'
        }, () => {
            self.props.change('IsMultipleOrganizations', 'false');
            self.props.change('IsProducerOrganization', 'true');
            self.props.change('IsAdvocacyGroup', 'true');
        });
        if (ActivityData.PrimaryActivityId < 5) {
            self.props.change('OrganizationName', ActivityData.Manufacturer.OrganizationName);
            self.props.change('Description', ActivityData.Manufacturer.Description);
            self.props.change('DivisionName', ActivityData.Manufacturer.DivisionName);
            self.props.change('OrganizationWebsite', ActivityData.Manufacturer.OrganizationWebsite);
            self.props.change('ParentOrganization', ActivityData.Manufacturer.ParentOrganization);
        }
        else if (ActivityData.PrimaryActivityId === 5 || ActivityData.PrimaryActivityId === 11) {
            self.props.change('NameofyourConsultingFirm', ActivityData.Consultant.NameofyourConsultingFirm);
            self.props.change('NameOfOrganizationRepresenting', ActivityData.Consultant.NameOfOrganizationRepresenting);
            self.props.change('OrganizationWebsite', ActivityData.Consultant.OrganizationWebsite);
            if (!ActivityData.Consultant.IsProducerOrganization) {
                self.props.change('BusinessActivity', ActivityData.Consultant.ProducerOrganizationValue);
            }
            else {
                self.props.change('ProductSold', ActivityData.Consultant.ProducerOrganizationValue);
            }
            self.setState({
                selectedIsMultipleOrganizations: ActivityData.Consultant.IsMultipleOrganizations.toString(),
                selectedIsProducerOrganization: ActivityData.Consultant.IsProducerOrganization.toString()
            }, () => {
                self.props.change('IsMultipleOrganizations', ActivityData.Consultant.IsMultipleOrganizations.toString());
                self.props.change('IsProducerOrganization', ActivityData.Consultant.IsProducerOrganization.toString());
            });
        }
        else if (ActivityData.PrimaryActivityId === 6) {
            self.props.change('GovernmentAgencyName', ActivityData.GovernmentAgency.GovernmentAgencyName);
            self.props.change('GovernmentInterest', ActivityData.GovernmentAgency.GovernmentInterest);
            self.props.change('Branch', ActivityData.GovernmentAgency.Branch);
            self.props.change('OrganizationWebsite', ActivityData.GovernmentAgency.WebsiteAddress);
        }
        else if (ActivityData.PrimaryActivityId === 7) {
            self.props.change('UniversityName', ActivityData.Academia.UniversityName);
            self.props.change('Department', ActivityData.Academia.Department);
            self.props.change('OrganizationWebsite', ActivityData.Academia.WebsiteAddress);
            self.props.change('AcademiaInterest', ActivityData.Academia.AcademiaInterest);
        }
        else if (ActivityData.PrimaryActivityId === 8) {
            self.props.change('NameOfConsumerAdvocacy', ActivityData.Consumer.NameOfConsumerAdvocacy);
            self.props.change('ContactEmailOfConsumerAdvocacy', ActivityData.Consumer.ContactEmailOfConsumerAdvocacy);
            self.props.change('ConsumerInterest', ActivityData.Consumer.ConsumerInterest);
            self.setState({ selectedIsAdvocacyGroup: ActivityData.Consumer.IsAdvocacyGroup.toString() }, () => {
                self.props.change('IsAdvocacyGroup', ActivityData.Consumer.IsAdvocacyGroup.toString());
            });
        }
        else if (ActivityData.PrimaryActivityId === 9) {
            self.props.change('ConsumerInterest', ActivityData.ConsumerAdvocacy.ConsumerInterest);
        }
        else if (ActivityData.PrimaryActivityId === 10) {
            self.props.change('MemberAffiliation', ActivityData.Other.MemberAffiliation);
            self.props.change('OtherInterest', ActivityData.Other.OtherInterest);
        }
    }
}

const getAllPrimaryActivities = () => {
    let memberTypeId = self.props.MemberTypeId === membershipTypeJSON.represntative.id ? membershipTypeJSON.organizational.id : self.props.MemberTypeId;
    const membershipTypeId = self.props.initialValues.MemberTypeId ? self.props.initialValues.MemberTypeId : self.props.initialValues.memberDetails.MemberTypeId;
    self.props.getActivityList(memberTypeId, response => {
        let activityList = [];
        let isOrgAssociated = !(isEmpty(self.props.initialValues.FacilityName) && isEmpty(self.props.initialValues.memberDetails?.FacilityName));
        forEach(response, (listItem) => {
            const activityObj = {
                text: listItem.ActivityName,
                key: listItem.CommitteePrimaryActivityId,
                value: listItem.CommitteePrimaryActivityId
            };
            const { consumer, consultant, other } = primaryActivityKey;
            if (isOrgAssociated) {
                activityList.push(activityObj);
            } else {
                if (activityObj.value === consumer || activityObj.value === consultant || activityObj.value === other) {
                    activityList.push(activityObj);
                }
            }
        });
        self.setState({ primaryActivityList: activityList, isOrgAssociated });
        if (self.props.flagFeature.StudentCommitteeManagement && membershipTypeId === studentMembershipId) {
            self.setState({ disableFieldForStudent: true });
        }
    });
    self.setState({ selectedPrimaryActivity: self.props.memberCommitteeDetails?.MemberActivity?.PrimaryActivityId });
}

export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.PrimaryActivityId) {
        errors.PrimaryActivityId = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Primary Activity");
    }
    if (values.PrimaryActivityId < 5) {
        if (!values.Description || values.Description.trim() == '') {
            errors.Description = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Description");
        }
    } else if (values.PrimaryActivityId == 5 || values.PrimaryActivityId == 11) {
        if (values.IsMultipleOrganizations === '') {
            errors.IsMultipleOrganizations = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Multiple Organizations");
        }
        if (values.IsMultipleOrganizations == 'false' && values.IsProducerOrganization === '') {
            errors.IsProducerOrganization = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Producer Organization(s)");
        }
        if (values.IsProducerOrganization == 'false' && (!values.BusinessActivity || values.BusinessActivity.trim() == '')) {
            errors.BusinessActivity = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Business Activity");
        }
        if (values.IsProducerOrganization == 'true' && (!values.ProductSold || values.ProductSold.trim() == '')) {
            errors.ProductSold = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Products Sold");
        }
    } else if (values.PrimaryActivityId === 6) {
        if (!values.GovernmentInterest || values.GovernmentInterest.trim() == '') {
            errors.GovernmentInterest = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Government Interest(s)");
        }
    } else if (values.PrimaryActivityId === 7) {
        if (!values.AcademiaInterest || values.AcademiaInterest.trim() == '') {
            errors.AcademiaInterest = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Academia Interest(s)");
        }
    } else if (values.PrimaryActivityId === 8) {
        if (!values.IsAdvocacyGroup) {
            errors.IsAdvocacyGroup = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Advocacy Group");
        }
        if ((values.IsAdvocacyGroup == 'true' && !values.NameOfConsumerAdvocacy) || (values.NameOfConsumerAdvocacy && values.NameOfConsumerAdvocacy.trim() == '')) {
            errors.NameOfConsumerAdvocacy = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Name Of Consumer Advocacy");
        }
        if ((values.IsAdvocacyGroup == 'true' && !values.ContactEmailOfConsumerAdvocacy) || (values.ContactEmailOfConsumerAdvocacy && values.ContactEmailOfConsumerAdvocacy.trim() == '')) {
            errors.ContactEmailOfConsumerAdvocacy = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Contact Email Of Consumer Advocacy");
        } else if (values.IsAdvocacyGroup == 'true' && values.ContactEmailOfConsumerAdvocacy && !validateEmail(values.ContactEmailOfConsumerAdvocacy)) {
            errors.ContactEmailOfConsumerAdvocacy = getMessage(props.messageCodes, '3128.text');
        }
        if ((values.IsAdvocacyGroup == 'false' && !values.ConsumerInterest) || (values.ConsumerInterest && values.ConsumerInterest.trim() == '')) {
            errors.ConsumerInterest = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Consumer Interest(s)");
        }
    } else if (values.PrimaryActivityId === 9) {
        if (!values.ConsumerInterest || values.ConsumerInterest.trim() == '') {
            errors.ConsumerInterest = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Consumer Interest(s)");
        }
    } else if (values.PrimaryActivityId === 10) {
        if (!values.OtherInterest || (values.OtherInterest && values.OtherInterest.trim() == '')) {
            errors.OtherInterest = getMessage(props.messageCodes, '4007.text').replace("@FieldName", "Other Interest(s)");
        }
    }
    if (!values.ReasonForUpdate || values.ReasonForUpdate.trim() == '') {
        errors.ReasonForUpdate = getMessage(props.messageCodes, '8121.text');
    }
    return errors;
}

export const validateValues = (value) => {
    return (value ? value : '')
}

export const checkOnlyReasonUpdated = (values) => {
    let ActivityData = self.props.memberCommitteeDetails.MemberActivity;
    if (ActivityData.PrimaryActivityId && ActivityData.PrimaryActivityId > 0) {
        if (ActivityData.PrimaryActivityId == values.PrimaryActivityId) {
            if (ActivityData.PrimaryActivityId < 5) {
                if (validateValues(values.OrganizationName) == validateValues(ActivityData.Manufacturer.OrganizationName) &&
                    validateValues(values.Description) == validateValues(ActivityData.Manufacturer.Description) &&
                    validateValues(values.DivisionName) == validateValues(ActivityData.Manufacturer.DivisionName) &&
                    validateValues(values.ParentOrganization) == validateValues(ActivityData.Manufacturer.ParentOrganization) &&
                    validateValues(values.OrganizationWebsite) == validateValues(ActivityData.Manufacturer.OrganizationWebsite)
                ) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 5) {
                if (validateValues(values.NameofyourConsultingFirm) == validateValues(ActivityData.Consultant.NameofyourConsultingFirm) &&
                    validateValues(values.NameOfOrganizationRepresenting) == validateValues(ActivityData.Consultant.NameOfOrganizationRepresenting) &&
                    validateValues(values.OrganizationWebsite) == validateValues(ActivityData.Consultant.OrganizationWebsite) &&
                    validateValues(values.IsMultipleOrganizations) == validateValues(ActivityData.Consultant.IsMultipleOrganizations.toString()) &&
                    validateValues(values.IsProducerOrganization) == validateValues(ActivityData.Consultant.IsProducerOrganization.toString()) &&
                    (validateValues(values.IsProducerOrganization) == 'false' && validateValues(values.BusinessActivity) == validateValues(ActivityData.Consultant.ProducerOrganizationValue) ||
                        validateValues(values.IsProducerOrganization) == 'true' && validateValues(values.ProductSold) == validateValues(ActivityData.Consultant.ProducerOrganizationValue))) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 6) {
                if (validateValues(values.GovernmentAgencyName) == validateValues(ActivityData.GovernmentAgency.GovernmentAgencyName) &&
                    validateValues(values.GovernmentInterest) == validateValues(ActivityData.GovernmentAgency.GovernmentInterest) &&
                    validateValues(values.Branch) == validateValues(ActivityData.GovernmentAgency.Branch) &&
                    validateValues(values.OrganizationWebsite) == validateValues(ActivityData.GovernmentAgency.WebsiteAddress)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 7) {
                if (validateValues(values.UniversityName) == validateValues(ActivityData.Academia.UniversityName) &&
                    validateValues(values.Department) == validateValues(ActivityData.Academia.Department) &&
                    validateValues(values.OrganizationWebsite) == validateValues(ActivityData.Academia.WebsiteAddress) &&
                    validateValues(values.AcademiaInterest) == validateValues(ActivityData.Academia.AcademiaInterest)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 8) {
                if (validateValues(values.NameOfConsumerAdvocacy) == validateValues(ActivityData.Consumer.NameOfConsumerAdvocacy) &&
                    validateValues(values.ContactEmailOfConsumerAdvocacy) == validateValues(ActivityData.Consumer.ContactEmailOfConsumerAdvocacy) &&
                    validateValues(values.ConsumerInterest) == validateValues(ActivityData.Consumer.ConsumerInterest) &&
                    validateValues(values.IsAdvocacyGroup) == validateValues(ActivityData.Consumer.IsAdvocacyGroup.toString())
                ) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 9) {
                if (validateValues(values.ConsumerInterest) == validateValues(ActivityData.ConsumerAdvocacy.ConsumerInterest)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (ActivityData.PrimaryActivityId === 10) {
                if (validateValues(values.MemberAffiliation) == validateValues(ActivityData.Other.MemberAffiliation) &&
                    validateValues(values.OtherInterest) == validateValues(ActivityData.Other.OtherInterest)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export const saveCommitteeActivity = (values) => {
    if (checkOnlyReasonUpdated(values)) {
        self.props.displayNoChangesMadeMessage();
        self.props.cancelEdit();
    } else {
        const id = values.PrimaryActivityId;
        let committeeMemberClassification = {};
        if (id === primaryActivityKey.manufacturer ||
            id === primaryActivityKey.salesDistributor ||
            id === primaryActivityKey.userPurchaser ||
            id === primaryActivityKey.testingProduct) {
            committeeMemberClassification = {
                DivisionName: isEmpty(values.DivisionName) ? null : values.DivisionName,
                OrganizationWebsite: isEmpty(values.OrganizationWebsite) ? null : values.OrganizationWebsite,
                ParentOrganization: isEmpty(values.ParentOrganization) ? null : values.ParentOrganization,
                Description: values.Description
            }
        } else if (id === primaryActivityKey.consultant || id === primaryActivityKey.consultingFirm) {
            committeeMemberClassification = {
                NameOfOrganizationRepresenting: values.NameOfOrganizationRepresenting || null,
                OrganizationWebsite: isEmpty(values.OrganizationWebsite) ? null : values.OrganizationWebsite,
                IsProducerOrganization: values.IsProducerOrganization.toString() == 'true' ? true : false,
                IsMultipleOrganizations: values.IsMultipleOrganizations.toString() == 'true' ? true : false,
                ProducerOrganizationValue: values.IsProducerOrganization.toString() == 'true' ? values.ProductSold : values.BusinessActivity
            };
        } else if (id === primaryActivityKey.consumer || id === primaryActivityKey.consumerAdvocacyGroup) {
            const isAdvocacyGroup = values.IsAdvocacyGroup === 'true' && !self.state.isOrgAssociated ? true : false
            committeeMemberClassification = {
                IsAdvocacyGroup: isAdvocacyGroup,
                NameOfConsumerAdvocacy: isAdvocacyGroup ? values.NameOfConsumerAdvocacy : null,
                ContactEmailOfConsumerAdvocacy: isAdvocacyGroup ? values.ContactEmailOfConsumerAdvocacy : null,
                ConsumerInterest: values.ConsumerInterest
            };
        } else if (id === primaryActivityKey.governmentAgency) {
            committeeMemberClassification = {
                Branch: isEmpty(values.Branch) ? null : values.Branch,
                OrganizationWebsite: isEmpty(values.OrganizationWebsite) ? null : values.OrganizationWebsite,
                GovernmentInterest: values.GovernmentInterest
            };
        } else if (id === primaryActivityKey.academia) {
            committeeMemberClassification = {
                Department: isEmpty(values.Department) ? null : values.Department,
                OrganizationWebsite: isEmpty(values.OrganizationWebsite) ? null : values.OrganizationWebsite,
                AcademiaInterest: values.AcademiaInterest
            };
        } else if (id === primaryActivityKey.other) {
            committeeMemberClassification = {
                OtherInterest: values.OtherInterest
            };
        }

        committeeMemberClassification.MemberId = self.props.MemberId;
        committeeMemberClassification.CommitteePrimaryActivityId = id;
        committeeMemberClassification.ReasonForUpdate = values.ReasonForUpdate;
        committeeMemberClassification.CommitteeId = self.props.CommitteeId;
        self.props.updateCommitteeActivityInfo(committeeMemberClassification, () => { self.props.cancelEdit() });
    }
}

export const cancelActivityChangeHandle = (key, value) => {
    self.setState({ isOpenModalOnActivityChange: false, selectedPrimaryActivity: self.state.oldPrimaryActivity }, () => { setActivityData() });
}

export const confirmActivityChangeHandle = () => {
    self.setState({
        isOpenModalOnActivityChange: false
    }, () => {
        setActivityData();
        self.props.reset();
        const primaryActivityId = self.state.selectedPrimaryActivity;
        self.props.change('PrimaryActivityId', primaryActivityId);
        if (!isEmpty(self.props.initialValues.FacilityName)) {
            if (primaryActivityId === primaryActivityKey.manufacturer ||
                primaryActivityId === primaryActivityKey.salesDistributor ||
                primaryActivityId === primaryActivityKey.userPurchaser ||
                primaryActivityId === primaryActivityKey.testingProduct
            ) {
                self.props.change('OrganizationName', self.props.initialValues.FacilityName);
            } else if (primaryActivityId === primaryActivityKey.consultant || primaryActivityId === primaryActivityKey.consultingFirm) {
                self.props.change('NameofyourConsultingFirm', self.props.initialValues.FacilityName);
            } else if (primaryActivityId === primaryActivityKey.governmentAgency) {
                self.props.change('GovernmentAgencyName', self.props.initialValues.FacilityName);
            } else if (primaryActivityId === primaryActivityKey.academia) {
                self.props.change('UniversityName', self.props.initialValues.FacilityName);
            } else if (primaryActivityId === primaryActivityKey.other) {
                self.props.change('MemberAffiliation', self.props.initialValues.FacilityName);
            }
        }
    });
}

export const primaryActivityChange = (value, key) => {
    const { primaryActivityList, selectedPrimaryActivity } = self.state;
    const selectedActivity = primaryActivityList.find(x => { return x.key == selectedPrimaryActivity });
    let text = selectedActivity?.text;
    if (key > 4 || !(selectedPrimaryActivity > 0 && selectedPrimaryActivity < 5)) {
        self.setState({
            oldPrimaryActivity: selectedPrimaryActivity
        }, () => {
            self.setState({
                isOpenModalOnActivityChange: selectedPrimaryActivity === 0 ? false : true,
                selectedPrimaryActivityName: text,
                selectedPrimaryActivity: key
            }, () => {
                if (selectedPrimaryActivity === 0) {
                    confirmActivityChangeHandle();
                }
            });
        });
    }
}


export const onChangeIsAdvocacyGroup = (e) => {
    let selectedIsAdvocacyGroup = e.currentTarget.querySelector('input').value;
    self.setState({ selectedIsAdvocacyGroup });
}

export const onChangeIsMultipleOrganizations = (e) => {
    let selectedIsMultipleOrganizations = e.currentTarget.querySelector('input').value;
    self.setState({ selectedIsMultipleOrganizations });
}

export const onChangeIsProducerOrganization = (e) => {
    let selectedIsProducerOrganization = e.currentTarget.querySelector('input').value;
    const BusinessActivities = e.currentTarget.querySelector('input').offsetParent && e.currentTarget.querySelector('input').offsetParent.offsetParent.nextSibling.firstElementChild.childNodes[1].firstElementChild;
    const ProductSold = e.currentTarget.querySelector('input').offsetParent && e.currentTarget.querySelector('input').offsetParent.offsetParent.nextSibling.nextSibling.firstChild.childNodes[1].firstChild;
    self.setState({ selectedIsProducerOrganization }, () => {
        if (BusinessActivities || ProductSold) {
            selectedIsProducerOrganization == 'true' ? ProductSold.focus() : BusinessActivities.focus()
        }
        return false
    });
}
