import * as common from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';
import { membershipTypeJSON, RefreshMemberCommitteeHeader, RefreshMemberCommitteeTableColumnOnBulkEdit } from '../../members.model';

let self = {};
let accountNo = '';
let initialState = {};
export let isGridEditable = {}

export const setClassInstance = (instance) => {
    self = instance;
    RefreshMemberCommitteeTableColumnOnBulkEdit();
    RefreshMemberCommitteeHeader();
    accountNo = self.props.initialValues.AccountNumber;
}
export const handleChangeClassification = (event, value) => {
    self.setState({ classificationDropdownSelectedValue: value, classificationDate: common.formatDateToMMDDYYYY() })
}
export const handleChangeVote = (event, value) => {
    self.setState({ voteDropdownSelectedValue: value, voteDate: common.formatDateToMMDDYYYY() })
    if (!value) {
        self.setState({ validatevoteDate: true })
    }
}
export const handleChangeNoVoteReason = (event, value) => {
    self.setState({ noVoteReasonDropdownSelectedValue: value, voteDate: common.formatDateToMMDDYYYY() })
}
export const handleChangeVoteDate = (event, value) => {
    self.setState({ voteDate: value, validatevoteDate: true })
}
export const handleChangeClassificationDate = (event, value) => {
    self.setState({ classificationDate: value })
}
export const handleChangeCommitteeJoinDate = (event, value) => {
    self.setState({ committeeJoinDate: value, validateCommitteeJoinDate: true })

    if (!common.compareDates(self.state.voteDate, value)) {
        self.setState({ voteDate: value })
    }
    if (!common.compareDates(self.state.classificationDate, value)) {
        self.setState({ classificationDate: value })
    }
}
export const setCellState = (fieldNameWithindex) => {
    isGridEditable[fieldNameWithindex] = false
}
export const handleEdit = (event, index, item) => {
    initialState = item
    self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(item.CommitteeId, accountNo)

    self.setState({
        edit: true,
        isRowEditable: isGridEditable,
        classificationDropdownSelectedValue: item.Classification,
        noVoteReasonDropdownSelectedValue: item.NoVoteReason != '' ? item.NoVoteReason : 'Pending',
        voteDropdownSelectedValue: item.Vote ? item.Vote : false,
        voteDate: item.VoteDate === null ? common.formatDateToMMDDYYYY() : common.formatDateToMMDDYYYY(item.VoteDate),
        committeeJoinDate: item.CommitteeJoinDate === null ? "" : common.formatDateToMMDDYYYY(item.CommitteeJoinDate),
        classificationDate: item.ClassificationDate === null ? common.formatDateToMMDDYYYY() : common.formatDateToMMDDYYYY(item.ClassificationDate)
    }, () => { self.props.isGridEdit(self.state.edit) })

    self.setState(prevState => {
        let isRowEditable = Object.assign({}, prevState.isRowEditable);
        isRowEditable[`classificationDropdown_${index}`] = true;
        isRowEditable[`classificationDate_${index}`] = true;
        isRowEditable[`voteDropdown_${index}`] = true;
        isRowEditable[`noVoteReasonDropdown_${index}`] = true;
        isRowEditable[`voteDate_${index}`] = true;
        isRowEditable[`committeeJoinDate_${index}`] = true;
        isRowEditable[`action_${index}`] = true;
        return { isRowEditable };
    })

    if (self.state.flagFeature.StudentCommitteeManagement && self.props.initialValues.MemberTypeId === membershipTypeJSON.student.id) {
        self.setState({ disableFieldForStudent: true });
    }
}
export const handleUpdate = (event, index, item) => {
    const isValidate = validateHandler({
        voteDropdownSelectedValue: self.state.voteDropdownSelectedValue,
        voteDate: self.state.voteDate,
        committeeJoinDate: self.state.committeeJoinDate,
        classificationDate: self.state.classificationDate
    })
    const updateData = {
        CommitteeId: item.CommitteeId,
        ClassificationTypeId: null,
        Vote: self.state.voteDropdownSelectedValue,
        NoVoteReason: 7,
        VoteDate: self.state.voteDate,
        CommitteeJoinDate: self.state.committeeJoinDate,
        ClassificationDate: self.state.classificationDate
    }
    const prevState = {
        CommitteeId: initialState.CommitteeId,
        ClassificationTypeId: null,
        Vote: initialState.Vote,
        NoVoteReason: null,
        VoteDate: common.formatDateToMMDDYYYY(initialState.VoteDate),
        CommitteeJoinDate: common.formatDateToMMDDYYYY(initialState.CommitteeJoinDate),
        ClassificationDate: common.formatDateToMMDDYYYY(initialState.ClassificationDate)
    }
    self.props.commonCommitteeClassificationTypeList && self.props.commonCommitteeClassificationTypeList.map((list) => {
        if (self.state.classificationDropdownSelectedValue === list.value) {
            updateData.ClassificationTypeId = list.ClassificationTypeId
        }
        if (initialState.Classification === list.value) {
            prevState.ClassificationTypeId = list.ClassificationTypeId
        }
    })
    self.props.noVoteReasonList && self.props.noVoteReasonList.map((list) => {
        if (self.state.noVoteReasonDropdownSelectedValue === list.value) {
            self.state.voteDropdownSelectedValue ? updateData.NoVoteReason = 7 : updateData.NoVoteReason = list.VoteId
        }
        if (initialState.NoVoteReason === list.value) {
            prevState.NoVoteReason = list.VoteId
        }
    })

    if (isValidate && (JSON.stringify(updateData) !== JSON.stringify(prevState))) {
        self.setState({ isValueChanged: true });
        if (self.state.classificationDropdownSelectedValue === 'Producer' && self.props.validateProducerClassification && item.LevelId === 2) {
            self.setState({ isUpdatePopUp: true, updateMainCommitteeToProducer: updateData })
        } else {
            self.setState({ isRowEditable: isGridEditable, edit: false, updateMainCommitteeToProducer: updateData, showUpdateReason: true }, () => { self.props.isGridEdit(self.state.edit) })
        }
    } else {
        self.setState({ updateMainCommitteeToProducer: updateData, showUpdateReason: true, isValueChanged: false })
    }
}
export const onUpdateRecored = (value) => {
    if (self.state.isValueChanged) {
        self.setState({ showUpdateReason: false })
        const updateDataWithUpdateReason = { ...self.state.updateMainCommitteeToProducer, UpdateReason: value.UpdateReason }
        self.props.updateMemberCommitteeDetails(updateDataWithUpdateReason, accountNo, () => { self.props.getMemberDetails(accountNo); self.props.callOnUpdate(); })
    } else {
        self.props.displayNoChangesMadeMessage();
        self.setState({ isRowEditable: isGridEditable, edit: false, showUpdateReason: false }, () => { self.props.isGridEdit(self.state.edit) })
    }
}
export const handleCancel = (event, index) => {
    self.setState({ edit: false }, () => { self.props.isGridEdit(self.state.edit) })
    self.setState(prevState => {
        let isRowEditable = Object.assign({}, prevState.isRowEditable);
        isRowEditable[`classificationDropdown_${index}`] = false;
        isRowEditable[`classificationDate_${index}`] = false;
        isRowEditable[`voteDropdown_${index}`] = false;
        isRowEditable[`noVoteReasonDropdown_${index}`] = false;
        isRowEditable[`voteDate_${index}`] = false;
        isRowEditable[`committeeJoinDate_${index}`] = false;
        isRowEditable[`action_${index}`] = false;
        return { isRowEditable };
    })
}
export const popUpConfirm = () => {
    self.setState({ isRowEditable: isGridEditable, isUpdatePopUp: false, edit: false, showUpdateReason: true }, () => { self.props.isGridEdit(self.state.edit) })
}
export const popUpCancel = () => {
    self.setState({ isUpdatePopUp: false, showUpdateReason: false })
}
export const validateHandler = (values) => {
    let isValidData = true;
    if (values.voteDropdownSelectedValue && (!values.voteDate || !common.checkIfDateValid(values.voteDate, 'MM/DD/YYYY') ||
        common.compareDates(minDateForInputDateFields, values.voteDate))) {
        self.setState({ validatevoteDate: false })
        isValidData = false;
    }

    if (!values.committeeJoinDate || !common.checkIfDateValid(values.committeeJoinDate, 'MM/DD/YYYY') ||
        new Date(values.committeeJoinDate) > new Date() || common.compareDates(minDateForInputDateFields, values.committeeJoinDate)) {
        self.setState({ validateCommitteeJoinDate: false })
        isValidData = false;
    }

    return isValidData;
}

