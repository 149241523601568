import * as utilCommon from 'helpers/util-common';
import { OfficerTitleEnum } from 'models/common.models';
import { get } from 'lodash';


let self = {};
let officerTitleInitialData = [];
let checkValue = 0;
let updatedDetailsForupdateMemberCommitteeBasicDetails = {};
let oldSPBOfficersList = [];
let studentMembershipId = 17;

export const setClassInstance = (instance) => {
    self = instance;
    loadInitialData();
}
const loadInitialData = () => {
    let count = 0;
    const { pageSize, pageNumber } = self.state
    self.setState({ showLoader: true });
    const committeeId = self.props.initialValues.CommitteeId;
    self.props.getOfficerTitleOnCommitteeLevel(committeeId, (response) => {
        let officerList = response.filter(item => item.IsLinked)
        let deLinkOfficerList = response.filter(item => !item.IsLinked);
        count = officerList.length || 0;
        self.setState({ showLoader: false });
        if (count > pageSize) {
            self.state.showPager = true;
        }
        updateCurrentlyShowingItems(count, pageSize, pageNumber);
        self.props.getMemberOnCommittee(committeeId, (memberResponse) => {
            if (self.props.flagFeature && self.props.flagFeature.StudentCommitteeManagement) {
                memberResponse = memberResponse.filter((item) => (item.MembershipTypeId !== studentMembershipId));
            }
            
            officerList.map(item => {
                item.memberList = findDifferenceMemberList(officerList, memberResponse, item.OfficerTitleId)
                return item;
            })

            const committeeOfficerInfo = { OfficerTitleList: officerList || [], MemberList: memberResponse || [] }
            officerTitleInitialData = utilCommon.deepCopy(officerList);
            // SPB officers list
            oldSPBOfficersList = [];
            let filteredAA = officerTitleInitialData.filter(x => x.OfficerTitleName == OfficerTitleEnum.AdministrativeAssistant);
            let filteredSM = officerTitleInitialData.filter(x => x.OfficerTitleName == OfficerTitleEnum.StaffManager);
            let filtteredSCC = officerTitleInitialData.filter(x => x.OfficerTitleName == OfficerTitleEnum.SubChair);
            let adminAssistant = filteredAA.length > 0 ? (filteredAA[0].AssignedMembers.length > 0 ? filteredAA[0].AssignedMembers[0] : 0) : 0; 
            oldSPBOfficersList.push(adminAssistant);
            let staffManager = filteredSM.length > 0 ? (filteredSM[0].AssignedMembers.length > 0 ? filteredSM[0].AssignedMembers[0] : 0) : 0; 
            oldSPBOfficersList.push(staffManager);
            let subCommitteeChair = filtteredSCC.length > 0 ? (filtteredSCC[0].AssignedMembers.length > 0 ? filtteredSCC[0].AssignedMembers[0] : 0) : 0; 
            oldSPBOfficersList.push(subCommitteeChair);
            
            self.setState({ committeeOfficerInfo, totalItems: count, deLinkOfficerList })
        })
    })
}

const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = utilCommon.getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems, totalPages });
}

export const onChangeMemberHandler = (value, OfficerTitleId) => {

    const committeeOfficerInfo = self.state.committeeOfficerInfo;
    let index = committeeOfficerInfo.OfficerTitleList.findIndex(x => x.OfficerTitleId === OfficerTitleId)
    committeeOfficerInfo.OfficerTitleList[index].AssignedMembers = value;
    committeeOfficerInfo.OfficerTitleList[index].IsLinked = true;
    committeeOfficerInfo.OfficerTitleList.map(item => {
        item.memberList = findDifferenceMemberList(committeeOfficerInfo.OfficerTitleList, committeeOfficerInfo.MemberList, item.OfficerTitleId)
        return item
    })
    self.setState({ committeeOfficerInfo });
}
export const showHideLinkOfficer = (linkedOfficerTitleId = 0, selectedOfficerTitle = '') => {
    let linkAdditionalOfficer = !self.state.linkAdditionalOfficer
    self.setState({ linkAdditionalOfficer, linkedOfficerTitleId, selectedOfficerTitle })
    if (!linkAdditionalOfficer) {
        loadInitialData();
    }
}
const findDifferenceMemberList = (officerList, memberList, OfficerTitleId) => {
    let assignedMemberList = [];
    officerList.map(item => {
        if (item.OfficerTitleId !== OfficerTitleId && item.AssignedMembers.length > 0) {
            if (assignedMemberList.length === 0) {
                assignedMemberList = item.AssignedMembers;
            } else {
                assignedMemberList = assignedMemberList.concat(item.AssignedMembers);
            }

        }
        return item
    })
    return memberList.filter(item => !assignedMemberList.map(member => member.toString()).includes(item.MemberId.toString()))
}
export const formatMemberList = (list) => {
    if (list && list.length > 0) {
        let result = list.map((res) => {
            res.key = res.MemberId;
            res.text = `${res.MemberName}`;
            res.value = `${res.MemberId}`;
            return res;

        });
        return result;
    }
    return [];
}
export const onPageChangeHandler = (event, data) => {
    let { totalPages, activePageNumber, committeeOfficerInfo: { OfficerTitleList }, pageSize } = self.state;

    if (totalPages > 1) {
        activePageNumber = data.activePage;
    }
    updateCurrentlyShowingItems(OfficerTitleList.length || 0, pageSize, activePageNumber);
    self.setState({ activePageNumber })
}
export const onChangeNumberOfItemsPerPage = (e, value) => {
    self.setState({ showLoader: true })
    let { pageSize, pageNumber, committeeOfficerInfo: { OfficerTitleList } } = self.state;
    pageSize = value;
    pageNumber = 1;

    self.setState({ activePageNumber: 1, pageSize, showLoader: false });
    updateCurrentlyShowingItems(OfficerTitleList.length || 0, pageSize, pageNumber);
}

export const sortBy = (event, key) => {
    let orderType; // 1 for Ascending order, 0 for descending order
    event.preventDefault();

    if (event.target.attributes.getNamedItem('data-order')) {
        orderType = event.target.attributes.getNamedItem('data-order').value;
    } else {
        orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
    }
    const committeeOfficerInfo = self.state.committeeOfficerInfo;
    if (committeeOfficerInfo.OfficerTitleList && committeeOfficerInfo.OfficerTitleList.length > 0) {
        self.setState({ showLoader: true });
        let sortkey = key.split(' ').join('');
        if (orderType === "0") {
            committeeOfficerInfo.OfficerTitleList.sort((a, b) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));

        }
        else {
            committeeOfficerInfo.OfficerTitleList.sort((b, a) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));
        }
        self.setState({
            sortKey: sortkey,
            orderType: orderType,
            committeeOfficerInfo: committeeOfficerInfo,
            showLoader: false
        });
    }
}
export const ConfirmationOption = {
    Back: 1,
    NoChage: 2,
    Delink: 3
}
export const officerTitleLinkHandler = (OfficerTitleId) => {
    let committeeOfficerInfo = self.state.committeeOfficerInfo;
    let index = committeeOfficerInfo.OfficerTitleList.findIndex(x => x.OfficerTitleId === OfficerTitleId)
    if (committeeOfficerInfo.OfficerTitleList[index].IsLinked) {
        self.setState({ isDeLink: true, activeIndex: index });
    } else {
        committeeOfficerInfo.OfficerTitleList[index].IsLinked = true;
        self.setState({ committeeOfficerInfo, activeIndex: -1 });
    }
}
const findDifference = () => {
    let { OfficerTitleList } = self.state.committeeOfficerInfo;
    return self.props.hasUpdateAccess && officerTitleInitialData.filter(item => {
        return OfficerTitleList.filter(changeInfo => changeInfo.OfficerTitleId === item.OfficerTitleId
            && (changeInfo.IsLinked !== item.IsLinked || item.AssignedMembers.filter(x => !changeInfo.AssignedMembers.includes(x)).length > 0 || changeInfo.AssignedMembers.filter(x => !item.AssignedMembers.includes(x)).length > 0)).length > 0
    }).length > 0;
}
export const backButtonHandler = () => {

    if (findDifference()) {
        self.setState({ isBackButtonPopUp: true });
    } else {
        self.props.showHide()
    }
}


export const ConfirmationPupUpHandler = (type, action) => {
    switch (type) {
        case ConfirmationOption.Back:
            if (action === 1) {
                self.props.showHide();
            } else {
                // Do nothing
            }
            break;
        case ConfirmationOption.NoChage:
            if (action === 1) {
                // Proceeed
            } else {
                // Cancel
            }
            break;
        case ConfirmationOption.Delink:
            if (action === 1) {
                let { committeeOfficerInfo, activeIndex } = self.state;
                committeeOfficerInfo.OfficerTitleList[activeIndex].IsLinked = false;
                self.setState({ committeeOfficerInfo, activeIndex: -1 })
                // Proceeed
            } else {
                // Do nothing
            }
            break;
        default:
            break;
    }
    self.setState({ isBackButtonPopUp: false, isDeLink: false, isNoChangeFound: false })
}

export const postCommitteeOfficer = () => {
    self.setState({ showUpdateReason: true })
}
export const confirmAdministrativeAssistantOrStaffManagerPopup = (event, value) => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });

    // Updating Member Committee Basic Details
    OnUpdateRecored(updatedDetailsForupdateMemberCommitteeBasicDetails);
}

export const setOfficerNameOnMessages = (staff, administrative, designations) => {
    const { messageCodes } = self.props;
    let officerTitlteName = '';
    if ((staff && staff.AssignedMembers.length) && (administrative && administrative.AssignedMembers.length)) {
        officerTitlteName = utilCommon.getMessage(messageCodes, '9166.text').replace('<Main Committee Designation>', designations)
    } else if (staff && staff.AssignedMembers.length) {
        officerTitlteName = utilCommon.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', staff.OfficerTitleName).replace('<Main Committee Designation>', designations)
    } else if (administrative && administrative.AssignedMembers.length) {
        officerTitlteName = utilCommon.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', administrative.OfficerTitleName).replace('<Main Committee Designation>', designations)
    }
    self.setState({
        OfficerTitleDisplayMsg: officerTitlteName
    })
}

export const OnUpdateRecored = (value) => {
    const postdata = {
        CommitteeId: self.props.initialValues.CommitteeId,
        Officers: self.state.committeeOfficerInfo.OfficerTitleList,
        UpdateReason: value.UpdateReason,
        IsdidNotChange: !findDifference()
    }

    // remove member list array obj from payload
    postdata.Officers.map(item => {
        delete item.memberList;
    });

    let administrativeFilter = postdata.Officers.filter(x => x.OfficerTitleName == OfficerTitleEnum.AdministrativeAssistant);
    let administrativeAssistantCount = administrativeFilter.length ? get(administrativeFilter[0], 'AssignedMembers.length') : 0;

    let staffManagerFilter = postdata.Officers.filter(x => x.OfficerTitleName == OfficerTitleEnum.StaffManager);
    let staffManagerCount = staffManagerFilter.length ? get(staffManagerFilter[0], 'AssignedMembers.length') : 0;

    let chairFilter = postdata.Officers.find(x => x.OfficerTitleName === OfficerTitleEnum.Chair);
    let chairCount = chairFilter ? get(chairFilter, 'AssignedMembers.length') : 0;

    let subChairFilter = postdata.Officers.find(x => x.OfficerTitleName === OfficerTitleEnum.SubChair);
    let subChairCount = subChairFilter ? get(subChairFilter, 'AssignedMembers.length') : 0;

    let newSPBOfficersList = [];
    newSPBOfficersList.push(administrativeFilter.length && administrativeFilter[0].AssignedMembers[0] ? administrativeFilter[0].AssignedMembers[0] : 0);
    newSPBOfficersList.push(staffManagerFilter.length && staffManagerFilter[0].AssignedMembers[0] ? staffManagerFilter[0].AssignedMembers[0] : 0);
    newSPBOfficersList.push(subChairFilter && subChairFilter.AssignedMembers[0] ? subChairFilter.AssignedMembers[0] : 0);

    if (staffManagerCount > 1 || administrativeAssistantCount > 1 || chairCount > 1 || subChairCount > 1) {
        self.setState({ isShowAdministrativeAssistantPopup: true });
    }
    else {
        const { initialValues: { CommitteeDesignation } } = self.props
        let CommitteeDesignations = CommitteeDesignation;
        updatedDetailsForupdateMemberCommitteeBasicDetails = value;
        if ((staffManagerCount >= 1 || administrativeAssistantCount >= 1) && CommitteeDesignations.indexOf('.') > -1 && checkValue == 0
        && (oldSPBOfficersList[0] !== newSPBOfficersList[0] || oldSPBOfficersList[1] !== newSPBOfficersList[1])) {
            checkValue = 1;
            // Update Officer Title Name on Message in the case of Staff and Administrative Assistant
            const designations = CommitteeDesignations && CommitteeDesignations.split('.')[0];
            setOfficerNameOnMessages(staffManagerFilter[0], administrativeFilter[0], designations);
            self.setState({
                isShowAdministrativeAssistantOrStaffManagerPopup: true,
                committeeName: designations
            });
        }
        else {
            self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
            checkValue = 0;
            const commentId = self.props.location.pathname && self.props.location.pathname.split('/').pop();
            if(JSON.stringify(oldSPBOfficersList) !== JSON.stringify(newSPBOfficersList)) {
                let data = [...oldSPBOfficersList, ...newSPBOfficersList];
                self.props.getMemberAccountNumbers(data, (response) => {
                    if (response) {
                        let result = response;
                        let updateOfficerTitle = {
                            groupMainCommitteeDesignation : (CommitteeDesignations && CommitteeDesignations.split('.')[0]),
                            groupSubCommitteeDesignation : [(CommitteeDesignations && CommitteeDesignations.split('.')[0] ? 
                            CommitteeDesignations : '')],
                            transfers : [
                                {"officerTitle" : OfficerTitleEnum.AdministrativeAssistant, oldOfficerAccountNumber : result[0] != 0 ? result[0] : '', newOfficerAccountNumber : result[3] != 0 ? result[3] : '' },
                                {"officerTitle" : OfficerTitleEnum.StaffManager, oldOfficerAccountNumber : result[1] != 0 ? result[1] : ''  , newOfficerAccountNumber : result[4] != 0 ? result[4] : '' },
                                {"officerTitle" : OfficerTitleEnum.SubChair, oldOfficerAccountNumber : result[2] != 0 ? result[2] : ''  , newOfficerAccountNumber : result[5] != 0 ? result[5] : '' },
                            ]
                        };
                        postdata.updateOfficerTitle = {};
                        postdata.updateOfficerTitle = updateOfficerTitle;
                            self.props.postCommitteeOfficer(postdata, () => {
                            self.setState({ showUpdateReason: false });
                            self.props.showHide(!postdata.IsdidNotChange);
                            self.props.getCommitteeDetails(commentId);
                        })
                    }
                })
            } else {
                self.props.postCommitteeOfficer(postdata, () => {
                    self.setState({ showUpdateReason: false });
                    self.props.showHide(!postdata.IsdidNotChange);
                    self.props.getCommitteeDetails(commentId);
                })
            }
            
        }
    }
}
export const handleClickOk = (e) => {
    self.setState({ isShowAdministrativeAssistantPopup: false });
}
export const cancelAdministrativeAssistantOrStaffManagerPopup = () => {
    self.setState({
        isShowAdministrativeAssistantOrStaffManagerPopup: false
    });
    self.props.showHide();
    checkValue = 0;
}
