import React, { Component } from "react";
import { Checkbox, Confirm, Icon } from 'semantic-ui-react'
import PaginationShorthand from 'shared-components/Pager';
import AddAssociateMemberForm from '../add-associate-member-modal'
import * as _func from './function';
import { checkUserPermissionOnPage, formatDateToMMDDYYYY, getMessage, routeNavigationUrl } from 'helpers/util-common';
import TableHeader from 'shared-components/Table/tableHeader';
import RenderSelect from 'shared-components/Select';
import { FeeGroupAssociatedMemberTableColumns, PaidStatus } from '../../feegroups.model';
import { ItemsPerPage } from 'models/common.models';
import UpdateReasonModel from 'modules/committee-management/components/update-reason-modal';
import AssociatedMemberExcelExport from "../associated-member-export-excel";

class AssociatedMembersList extends Component {
    membershipTypeList = [];
    state = {
        isShowAssociateMember: false,
        associatedMemberList: [],
        associatedAllMemberList: [],
        associatedMemberHeaderColumns: FeeGroupAssociatedMemberTableColumns,
        totalItems: 0,
        activePageNumber: 1,
        totalPages: 0,
        currentlyShowingItems: 0,
        pageSize: 25,
        checkedAll: false,
        sortOrder: 'AccountNumber',
        sortAction: '0',
        noMemberAssociatedMessage: getMessage(this.props.messageCodes, '8157.text'),
        partialClass: '',
        isSelectAll: false,
        showUpdateReason: false,
        showRemoveButton: false,
        selectedMemberType: 'All',
        selectedPaidStatus: PaidStatus[0].value,
        loading: false,
        exportedData: [],
        isExportExcel: false,
        isOpenExcelDownloadConfirmBox: false,
        getMemberListFlag : true
    }
    componentDidMount() {
        _func.setClassInstance(this);
        if (this.props.activeTabIndex !== "1") {
            _func.getFeeGroupAssociatedMemberList();
        }
    }

    componentDidUpdate () {
        if (this.props.membershipTypeList && this.props.membershipTypeList?.length > 0 && this.props.activeTabIndex === "1" && this.state.getMemberListFlag) {
            this.setState({getMemberListFlag : false})
            _func.getFeeGroupAssociatedMemberList();
        }
    }

    shouldComponentUpdate = () => {
        this.membershipTypeList = _func.filterMembershipTypes()
        return true;
    }

    render() {
        const { isShowAssociateMember, associatedMemberList, totalItems, activePageNumber, totalPages, currentlyShowingItems, pageSize, noMemberAssociatedMessage, partialClass, isSelectAll, showUpdateReason, showRemoveButton, selectedMemberType, selectedPaidStatus, loading, exportedData, isExportExcel, isOpenExcelDownloadConfirmBox, associatedMemberHeaderColumns } = this.state;
        const { PAGE_TYPE, USER_PRIVILEGE, userPermission, messageCodes, FeeGroupTabDetails: { FeeGroupTitle } } = this.props
        let begin = (((activePageNumber - 1) * pageSize));
        let end = begin + pageSize;
        let disableRemoveButton = (!associatedMemberList || associatedMemberList.length === 0 || associatedMemberList.filter(item => item.isChecked).length < 1);
        return (
            <div data-testid='associateComponent'>
                <div className="txtRight clearfix mb20">
                    {checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.UPDATE) && this.props.initialValues.Status === "Active" && !showRemoveButton && <button className="ui secondary button mr10" data-testid='associateMemberButton' onClick={() => _func.manageAssociatePopUp()}>Associate Member</button>}
                    {checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.DELETE) && this.props.initialValues.Status === "Active" && !showRemoveButton && <button className="ui secondary button" disabled={!associatedMemberList || associatedMemberList.length === 0} onClick={_func.enableAssociateRemove}>Remove Member from Fee Group</button>}
                </div>
                {checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.DELETE) && this.props.initialValues.Status === "Active" && showRemoveButton && <div className="memberSelected">
                    <span className="selectedList">{associatedMemberList.filter(item => item.isChecked).length || '0'} member(s) selected </span>

                    <ul className="editDetailsBtn floatRight">
                        {!disableRemoveButton &&
                            <React.Fragment>
                                <li>
                                    <button data-testid="btnUpdate" title="Update" className="updateBtn mr10" onClick={() => _func.showUpdateReason()}>
                                        <Icon name="check" />
                                    </button>
                                </li>
                                <li>
                                    <button data-testid="btnReset" title="Reset" className="resetBtn mr10" onClick={() => _func.resetMemberList()}>
                                        <Icon name="undo" />
                                    </button>
                                </li>
                            </React.Fragment>
                        }
                        <li>
                            <button data-testid="btnClose" title="Cancel" className="closeBtn" onClick={() => _func.popUpCancel()}>
                                <Icon name="close" />
                            </button>
                        </li>
                    </ul>
                </div>}
                <div className="clearfix member-header">
                    <div className="display-inline-block mr25" data-testid="membertypeSelect">
                        <label className="label-inline" for="memberType">Member Type</label>
                        <RenderSelect
                            placeholder="All"
                            name="memberType"
                            id="memberType"
                            value={selectedMemberType}
                            onChange={_func.onChangeMemberType}
                            options={this.membershipTypeList}
                        />
                    </div>
                    <div className="display-inline-block mr25" data-testid="paidstatus">
                        <label className="label-inline" for="paidStatus">Paid Status</label>
                        <RenderSelect
                            placeholder="All"
                            name="paidStatus"
                            id="paidStatus"
                            value={selectedPaidStatus}
                            onChange={_func.onChangePaidStatus}
                            options={PaidStatus.filter((paidStatus) => paidStatus.value !== 'Partial Paid')}
                        />
                    </div>
                    <div className="display-inline-block floatRight">
                        <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                        <button data-testid="ExportasExcel" style={{ pointerEvents: loading ? 'none' : 'auto' }} className="ui button secondary mr10" onClick={_func.getAssociateMemberExportExcel}><i className="icon file excel mr5"></i>Excel</button>
                    </div>
                </div>
                <div className="tableWrapper" data-testid="tableWrapper">
                    <table className="customTable memComInfoTable">
                        <thead>
                            <tr>
                                {showRemoveButton && <th width="4%">
                                    <Checkbox label={''} checked={isSelectAll} className={partialClass + ' vMiddle'} onClick={_func.selectAll} />
                                </th>}
                                <TableHeader headerProps={associatedMemberHeaderColumns} onClickProps={_func.handleSorting}></TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {associatedMemberList && associatedMemberList.length > 0 && associatedMemberList.slice(begin, end).map((item, index) => {
                                return (
                                    <tr>
                                        {showRemoveButton && <td>
                                            <Checkbox label={''} index={begin + index} checked={item.isChecked} onClick={_func.onChangeCheckBoxHandler} />
                                        </td>}
                                        <td><a href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [item.AccountNumber])}`} target="_blank">{item.AccountNumber}</a></td>
                                        <td>{item.Name}</td>
                                        <td>{item.MembershipTypeName}</td>
                                        <td><a href={"mailto:" + item.Email}>{item.Email}</a></td>
                                        <td>{item.Organization}</td>
                                        <td>{item.Status}</td>
                                        <td>{item.PaidStatus}</td>
                                        <td>{formatDateToMMDDYYYY(item.AssociationDate)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {associatedMemberList && associatedMemberList.length === 0 && (
                        <div className="noRecordMessage">{noMemberAssociatedMessage}</div>
                    )}
                </div>
                {totalItems > 0 && (
                    <div className="pagerWrap" data-testid="pagerWrap">
                        <div className="pager">
                            <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                onPageChange={_func.onPageChangeHandler} />
                            <div>
                            </div>
                        </div>
                        <div className="itemPerPage" data-testid="itemPerPage">
                            <RenderSelect
                                name="itemsPerPage"
                                value={pageSize}
                                onChange={_func.onChangeNumberOfItemsPerPage}
                                options={ItemsPerPage}
                            />
                            <span className="itemsPerPage">items per page</span>
                        </div>

                        <div className="totalPage">{currentlyShowingItems}</div>

                    </div>
                )}
                {isShowAssociateMember && <AddAssociateMemberForm {...this.props} close={_func.manageAssociatePopUp} />}
                {showUpdateReason && <UpdateReasonModel {...this.props} onSubmitCall={_func.OnDeleteMember} onClose={_func.popUpCancel} />}
                {isExportExcel && <AssociatedMemberExcelExport data={exportedData} title={FeeGroupTitle} />}
                <Confirm data-testid="Confirm-box" className="confirm-box"
                    open={isOpenExcelDownloadConfirmBox}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyExportDataCancelHandler}
                    onConfirm={_func.emptyExportDataConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        );
    }
}

export default AssociatedMembersList;
