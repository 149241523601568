import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Icon, Grid, Confirm, Form } from 'semantic-ui-react';
import RenderSelect from 'shared-components/Select';
import * as common from 'helpers/util-common';
import TextArea from 'shared-components/TextArea';
import RenderInputField from 'shared-components/Input';
import RenderRadioField from 'shared-components/RadioBox'
import { primaryActivityKey } from '../../members.model';
import * as _func from './function';
class CommitteeActivityEdit extends Component {
    state = {
        primaryActivityList: [],
        selectedPrimaryActivity: 0,
        selectedIsAdvocacyGroup: 'true',
        selectedIsMultipleOrganizations: 'false',
        selectedIsProducerOrganization: 'true',
        isOpenModalOnActivityChange: false,
        selectedPrimaryActivityName: '',
        oldPrimaryActivity: 0,
        disableFieldForStudent: false
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }
    render() {
        const { cancelEdit, handleSubmit, submitting, messageCodes } = this.props;
        const { primaryActivityList, selectedPrimaryActivity, selectedIsAdvocacyGroup,
            selectedIsMultipleOrganizations, selectedIsProducerOrganization,
            isOpenModalOnActivityChange, selectedPrimaryActivityName, isOrgAssociated, disableFieldForStudent } = this.state;
        return (

            <section className="ClassificationInfo">
                <Form size='large' onSubmit={handleSubmit(_func.saveCommitteeActivity)} noValidate data-testid="committeeActivityEdit">
                    <h5>Classification Information
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button title="Cancel" className="closeBtn" onClick={cancelEdit}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <p>In order to update the <i style={{ fontStyle: 'italic' }}>Organization Name / Government Agency Name / 'University/College/Institution Name' / Name of Consulting Firm</i> please navigate to Member Details page. The information provided against Division Name, Parent Organization and Organization Website for this committee will be updated for already associated committees as well.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid divided='vertically' columns={2} className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="PrimaryActivityId" type="text"
                                    component={RenderSelect} label="Primary Activity of Member"
                                    required='true' options={primaryActivityList}
                                    onChange={_func.primaryActivityChange}
                                    disabled={disableFieldForStudent}
                                />
                            </Grid.Column>

                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity > 0 && selectedPrimaryActivity < 5 && (
                                <Fragment>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column>
                                        <Field name="OrganizationName" type="text"
                                            component={RenderInputField} disabled
                                            label="Organization Name" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="DivisionName" type="text"
                                        dataTestId="divisionName"
                                            component={RenderInputField}
                                            label="Division Name" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="ParentOrganization" type="text"
                                            component={RenderInputField}
                                            label="Parent Organization" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="OrganizationWebsite" type="text"
                                            component={RenderInputField}
                                            label="Organization  Website" />
                                    </Grid.Column>

                                    <Grid.Column className="FullWidth">
                                        <Field name="Description" type="text" maxLength={255}
                                            component={TextArea} isCounterVisible={true} required={true} label="Describe your products or services" placeholder="Type here..." />
                                    </Grid.Column>
                                </Fragment>
                            )
                            }
                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity == primaryActivityKey.consumer && (
                                <Fragment>
                                    {!isOrgAssociated && (
                                        <Fragment>
                                            <Grid.Column>
                                                <span className="labelTitle"><i aria-hidden="true" className="asterisk  icon"></i>Consumer Advocacy Group or Groups</span>
                                                <Field name="IsAdvocacyGroup"
                                                    component={RenderRadioField}
                                                    className="mr10"
                                                    type="radio" onChange={_func.onChangeIsAdvocacyGroup}
                                                    options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                                    selectedValue={(selectedIsAdvocacyGroup ? selectedIsAdvocacyGroup : 'true')}
                                                />
                                            </Grid.Column>
                                            <Grid.Column className={selectedIsAdvocacyGroup == 'true' ? '' : 'hide'}>
                                                <Field name="NameOfConsumerAdvocacy" type="text"
                                                dataTestId="nameOfConsumerAdvocacy"
                                                    component={RenderInputField} required={true}
                                                    label="Name of Consumer Advocacy Group" />
                                            </Grid.Column>
                                            <Grid.Column className={selectedIsAdvocacyGroup == 'true' ? '' : 'hide'}>
                                                <Field name="ContactEmailOfConsumerAdvocacy" type="text"
                                                dataTestId="contactEmailOfConsumerAdvocacy"
                                                    component={RenderInputField} required={true}
                                                    label="Advocacy Group Contact Email" />
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                    <Grid.Column className='FullWidth'>
                                        <Field name="ConsumerInterest" type="text"
                                            component={TextArea} required={true}
                                            label="Consumer Interest(s)" placeholder="Type here..." />
                                    </Grid.Column>
                                </Fragment>
                            )}
                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity == primaryActivityKey.consumerAdvocacyGroup && (
                                <Grid.Column className="FullWidth">
                                    <Field name="ConsumerInterest" type="text"
                                        component={TextArea} required={true} placeholder="Type here..."
                                        label="Consumer Interest(s)" />
                                </Grid.Column>
                            )}
                            {selectedPrimaryActivity !== undefined && (selectedPrimaryActivity == primaryActivityKey.consultant || selectedPrimaryActivity == primaryActivityKey.consultingFirm) && (
                                <Fragment>
                                    <Grid.Column>
                                        <Field name="NameofyourConsultingFirm" type="text"
                                            component={RenderInputField} disabled
                                            label="Name of Consulting Firm" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelTitle"><i aria-hidden="true" className="asterisk  icon"></i>Retained by</span>
                                        <Field name="IsMultipleOrganizations"
                                            component={RenderRadioField}
                                            className="mr10"
                                            type="radio" onChange={_func.onChangeIsMultipleOrganizations}
                                            options={[{ label: 'One Organization', value: 'false' }, { label: 'Multiple Organization', value: 'true' }]}
                                            selectedValue={selectedIsMultipleOrganizations ? selectedIsMultipleOrganizations : 'false'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column className={selectedIsMultipleOrganizations == 'true' ? 'hide' : ''}>
                                        <Field name="OrganizationWebsite" type="text"
                                            component={RenderInputField}
                                            label="Organization Website" />
                                    </Grid.Column>
                                    <Grid.Column className={'FullWidth'}>
                                        <Field name="NameOfOrganizationRepresenting" type="text"
                                            component={TextArea}
                                            label="Name of Organization(s) Representing" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelTitle"><i aria-hidden="true" className="asterisk  icon"></i>Producer Organization(s)</span>
                                        <Field name="IsProducerOrganization"
                                            component={RenderRadioField}
                                            className="mr10"
                                            type="radio" onChange={_func.onChangeIsProducerOrganization}
                                            options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                            selectedValue={selectedIsProducerOrganization ? selectedIsProducerOrganization : 'true'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column className={selectedIsProducerOrganization == 'false' ? 'show FullWidth' : 'hide'}>
                                        <Field name="BusinessActivity" type="text"
                                            component={TextArea} required={true}
                                            label="Business Activities" placeholder="Type here..." />
                                    </Grid.Column>
                                    <Grid.Column className={selectedIsProducerOrganization == 'true' ? 'show FullWidth' : 'hide'}>
                                        <Field name="ProductSold" type="text"
                                            component={TextArea} required={true}
                                            label="Product Sold" placeholder="Type here..." />
                                    </Grid.Column>
                                </Fragment>)
                            }
                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity == primaryActivityKey.other && (
                                <Fragment>
                                    <Grid.Column>
                                        <Field name="MemberAffiliation" type="text"
                                        dataTestId="memberAffiliation"
                                            component={RenderInputField} disabled
                                            label="Member Affiliation" />
                                    </Grid.Column>
                                    <Grid.Column className="FullWidth">
                                        <Field name="OtherInterest" type="text"
                                            component={TextArea} required={true}
                                            disabled={disableFieldForStudent}
                                            label="Other Interest(s)" placeholder="Type here..." />
                                    </Grid.Column>
                                </Fragment>)
                            }
                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity == primaryActivityKey.governmentAgency && (
                                <Fragment>
                                    <Grid.Column>
                                        <Field name="GovernmentAgencyName" type="text"
                                            component={RenderInputField} disabled
                                            label="Government Agency Name" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="Branch" type="text"
                                        dataTestId="branch"
                                            component={RenderInputField}
                                            label="Branch/Office/Division" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="OrganizationWebsite" type="text"
                                        dataTestId="organizationWebsite"
                                            component={RenderInputField}
                                            label="Website Address of Agency" />
                                    </Grid.Column>
                                    <Grid.Column className="FullWidth">
                                        <Field name="GovernmentInterest" type="text"
                                            component={TextArea} required={true}
                                            placeholder="Type here..."
                                            label="Government Interest(s)" />
                                    </Grid.Column>
                                </Fragment>)
                            }
                            {selectedPrimaryActivity !== undefined && selectedPrimaryActivity == primaryActivityKey.academia && (
                                <Fragment>
                                    <Grid.Column>
                                        <Field name="UniversityName" type="text"
                                            component={RenderInputField} disabled
                                            label="University/College/Institution Name" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="Department" type="text"
                                         dataTestId="department"
                                            component={RenderInputField}
                                            label="Department" />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Field name="OrganizationWebsite" type="text"
                                        dataTestId="organizationWebsite"
                                            component={RenderInputField}
                                            label="Website Address" />
                                    </Grid.Column>

                                    <Grid.Column className="FullWidth">
                                        <Field name="AcademiaInterest" type="text"
                                            component={TextArea} required={true}
                                            label="Academia Interest(s)"
                                            placeholder="Type here..." />
                                    </Grid.Column>
                                </Fragment>)
                            }
                        </Grid.Row>
                    </Grid>
                    <Grid divided='vertically' className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="ReasonForUpdate" type="text"
                                    component={TextArea} required={true} label="Reason for Update"
                                    maxLength="250" placeholder="Type here.." />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Confirm className="confirm-box"
                        open={isOpenModalOnActivityChange}
                        content={common.getMessage(messageCodes, '3127.text').replace('@PrimaryActivityName', selectedPrimaryActivityName)}
                        onCancel={_func.cancelActivityChangeHandle}
                        onConfirm={_func.confirmActivityChangeHandle}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />
                </Form>
            </section >
        )
    }
}
export default (reduxForm({
    form: 'CommitteeActivityEdit',
    validate: _func.validateHandler
})(CommitteeActivityEdit));
