import axios from 'axios';
import { cacheManager, callAPI, clearAzureAdAuthCodeAction, cookiesManager, noCookiesRedirectToLogin } from 'helpers/util-common';
import { get } from 'lodash';
import * as types from './action-types';
import getApiUrl from './helpers/api-urls';
import { decrypt, encrypt, getUserAgent } from './helpers/util-common';
import { AppGridIds, cookieKeysEnum, S3_URL_TYPE } from './models/common.models';

export const hideMessage = (dispatch) => {
  dispatch(setMessage(false, '200'));
}

export const setLoader = (flag) => ({
  type: types.SET_LOADER,
  flag
});

export const setminiLoader = (flag) => ({
  type: types.SET_MINI_LOADER,
  flag
});

export const setMessage = (flag, code) => ({
  type: types.SET_MESSAGE,
  flag,
  code
});

export const showErrorPopup = (flag, data) => ({
  type: types.SHOW_ERROR_POPUP,
  flag,
  data
})

export const hideErrorPopup = (dispatch) => {
  dispatch(showErrorPopup(false, []));
}

export const getUserNameAction = (userId, callback) => {
  let url = getApiUrl('userPermission', 'getUserPersonalDetails') + `/${userId}`;

  axios.get(url)
    .then((response) => {
      if (response.data.status && response.data.content) {
        callback(response.data.content.UserName)
      } else {
        callback();
      }
    })
    .catch((err) => {
      callback();
    });
}

export const getGridPreferenceAction = (gridId, dispatch, callback) => {
  const url = `${getApiUrl('userPermission', 'getGridUserPreferences')}/${gridId}`;

  if (callback) {
    dispatch(setLoader(true));
  }

  axios.get(url)
    .then((response) => {
      dispatch(setLoader(false));
      if (response.data.status) {
        if (callback) {
          callback(response.data.content);
        }

        if (gridId === AppGridIds.CommitteeGrid) {
          dispatch({ type: types.GET_COMMITTEE_GRID_PREFERENCES, committeeGridPreferences: response.data.content });
        }
        else if (gridId === AppGridIds.CommitteeRosterGrid) {
          dispatch({ type: types.GET_COMMITTEE_ROSTER_GRID_PREFERENCES, committeeRosterGridPreference: response.data.content });
        }
      } else {
        dispatch(setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(setLoader(false));
      dispatch(setMessage(true, '3064'));
    });
}

export const updateGridPreferenceAction = (gridId, data, callback, dispatch) => {
  let url = '';

  if (gridId === AppGridIds.MemberGrid || gridId === AppGridIds.OrganizationGrid) {
    url = getApiUrl('manageMembers', 'updateGridPreferences') + gridId;
  } else if (gridId === AppGridIds.CommitteeGrid || gridId === AppGridIds.CommitteeRosterGrid) {
    url = getApiUrl('manageCommittee', 'updateGridPreferences') + gridId;
  } else if (gridId === AppGridIds.MembershipException) {
    url = getApiUrl('membershipException', 'updateGridPreferences') + gridId;
  }

  dispatch(setLoader(true));

  axios.put(url, data)
    .then((response) => {
      dispatch(setLoader(false));
      if (response.data.status) {
        callback(true);
        dispatch(setMessage(true, '2038'));
      } else {
        callback(false);
        dispatch(setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(setLoader(false));
      dispatch(setMessage(true, '3055')); // Default exception error
    });
};

export const getExportDataAction = (url, callback, dispatch) => {
  axios.get(url)
    .then((response) => {
      if (response.data.status) {
        callback(response.data.content);
      } else {
        // Handle error case
        dispatch(setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(setMessage(true, '3038'));
    });
}

export const showAlertPopup = (flag, data) => ({
  type: types.SHOW_ALERT_POPUP,
  flag,
  data
})

export const hideAlertPopup = (dispatch) => {
  dispatch(showAlertPopup(false, ''));
}

// Action for calling logout.
export const logout = (dispatch) => {
  let url = getApiUrl('login', 'logout');
  dispatch(setLoader(true));
  axios.post(url)
    .then((response) => {
      if (response.data.status) {
        // Clear all cache from react app.
        cacheManager.clearSession();
        // Validate if Logout from Azure AD.
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
          cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
          setCurrentLocationStorage()
          setTimeout(() => { window.location.assign(clearAzureAdAuthCodeAction()) }, 500)
        } else {
          window.location.assign('/login');
        }
      } else {
        dispatch(setMessage(true, response.data.message));
      }
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(setLoader(false));
      // Custom default code for autologout
      noCookiesRedirectToLogin(); // Default exception error
    });
}

export const currentLocationStorage = () => {
  if (cacheManager.getItem('current-location')) {
    return { isLocation: true, location: cacheManager.getItem('current-location') };
  }
  return { isLocation: false, location: null };
}

export const setCurrentLocationStorage = () => {
  if (window.location.href) {
    let location = window.location.href.replace(/.*\/\/[^\/]*/, '');
    let allowedRoutes = ['/renewal-tasks', '/admin', '/user', '/roles', '/member-management', '/committee-management', '/work-item-admin', '/ballot-admin'];
    let isAllowed = allowedRoutes.filter(x => location.includes(x));
    if (isAllowed && isAllowed.length) {
      cacheManager.setItem('current-location', window.location.href.replace(/.*\/\/[^\/]*/, ''));
    }
  }
}

export const getCountryAndStateListAction = (dispatch) => {
  const url = getApiUrl('manageMembers', 'countryAndStateList');

  callAPI(url, 'get', '', (response) => {
    if (response && response.data && response.data.status) {
      dispatch({ type: types.GET_COUNTRY_LIST, countryList: response.data.content.CountryList });
      dispatch({ type: types.GET_US_STATE_LIST, stateList: response.data.content.USStateList });
      dispatch({ type: types.GET_CANADA_STATE_LIST, stateList: response.data.content.CanadaStateList });
    } else {
      // Handle error case
      dispatch(setMessage(true, get(response, 'data.message')));
    }
  })
}

export const getAppUserListAction = (dispatch) => {
  let url = getApiUrl('userPermission', 'getAppUserDetailList');

  axios.get(url)
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: types.GET_APP_USER_LIST, userList: response.data.content });
      } else {
        // Handle error case
        dispatch(setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(setMessage(true, '7034'));
    });
};

export const getPermissionOnPageAction = (Input, groupInput) => ({
  type: types.ALLOW_PERMISSION_FOR_PAGE,
  Input,
  groupInput
});

export const downloadS3DocumentAction = (docPath, hasRelativePath, bucket, dispatch, fileName = '') => {
  let data = { docPath, hasRelativePath, bucket };
  dispatch(setLoader(true));
  let url = `${getApiUrl('common', 'verifyDocExists')}?data=${encrypt(JSON.stringify(data))}`;
  axios.get(url).then(response => {
    dispatch(setLoader(false));
    response = response && response.data ? response.data : null;
    if (response && response.Status && response.Result.isFound) {
      data = { docPath: response.Result.FilePath, bucket, fileName };
      // Download the file now
      url = `${getApiUrl('common', 'downloadS3File')}?data=${encrypt(JSON.stringify(data))}`;
      const a = document.createElement('a');
      a.href = url;
      a.target = "_blank";
      a.download = docPath ? docPath.split('/').pop() : '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  });
}

export const downloadDMSDocumentAction = (docPath, source, dispatch) => {
  let data = { source: source, documentAssetGUID: docPath };
  dispatch(setLoader(true));
  let url = `${getApiUrl('common', 'downloadS3File')}?data=${encrypt(JSON.stringify(data))}`;
  axios.get(url).then(response => {
    dispatch(setLoader(false));
    if (response && response.data && response.data.Status) {
      // Download the file now
      const a = document.createElement('a');
      a.href = response.data.Result.WebUrl;
      a.target = "_blank";
      a.download = '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  });
}

export const getS3SignedUrlAction = (urlType, data, callback, dispatch) => {
  const getUrl = (type) => {
    switch (type) {
      case S3_URL_TYPE.RENEWAL_TASK_UPLOAD:
        return getApiUrl('renewalTask', 'getS3SignedUrl');
      case S3_URL_TYPE.BALLOT_ADMIN_FILE_UPLOAD:
        return getApiUrl('ballotAdmin', 'getS3FileDownload');
      case S3_URL_TYPE.BALLOT_ADMIN_FILE_DOWNLOAD:
        return getApiUrl('ballotAdmin', 'getOutboundS3FileDownload');
      default:
        return;
    }
  }
  dispatch(setLoader(true));
  const url = getUrl(urlType);
  callAPI(url, 'post', data, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  })
}

export const uploadFileInS3Action = (url, postData, callback, dispatch) => {
  dispatch(setLoader(true));
  delete axios.defaults.headers.common["Authorization"];
  axios.put(url, postData)
    .then((response) => {
      dispatch(setLoader(false));
      // TODO : check whether status get 200, that only file has been uploaded.
      if (response.status == 200) {
        callback(true);
      } else {
        // Handle error case
        callback(false);
        dispatch(setMessage(true, '7104'));
      }
    })
    .catch((err) => {
      callback(false);
      dispatch(setLoader(false));
      dispatch(setMessage(true, '7104'));
    });
};

export const deleteS3FileAction = (fileName, callback, dispatch) => {
  dispatch(setLoader(true));
  let url = getApiUrl('renewalTask', 'deleteS3FileUrl');
  url = `${url}/${fileName}`;
  callAPI(url, 'put', '', (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  })
};

// TODO : initiate Azure Request
export const initiateAzureRequestAction = (code, sessionState, authState, cb, dispatch) => {
  // TODO : Start code for init azure request.
  dispatch(setLoader(true));
  // TODO : Call Request for Init Azure in OSL.
  let url = getApiUrl('login', 'initAzure');
  let authCode = encrypt(`${code}:${sessionState}:${decrypt(decodeURIComponent(authState))}`);
  callAPI(url, 'post', { code: authCode }, (response) => {
    if (response.data && response.data.success) {
      cookiesManager.setCookie(cookieKeysEnum.APP_SESSIONID, sessionState);
      cb(true);
    } else {
      dispatch(setLoader(false));
      cb(false);
    }
  })
}

export const downloadDocumentAction = (type, fileName, dispatch, callback) => {
  dispatch(setLoader(true));
  let url = ''
  if (type === S3_URL_TYPE.RENEWAL_TASK_DOWNLOAD) {
    url = getApiUrl('renewalTask', 'downloadTaskDocument');
  }

  url = `${url}/${fileName}`;
  callAPI(url, 'get', '', (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  })
}

export const copyS3FilesAction = (sourceFileName, targetFileName, targetFileType, callback, dispatch) => {
  let url = getApiUrl('renewalTask', 'copyS3FilesUrl');
  let postData = {
    sourceFileName,
    targetFileName,
    targetFileType
  }
  callAPI(url, 'post', postData, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data);
    } else {
      dispatch(setMessage(true, '9517'));
    }
  });
}
// TODO : Show Autofill safari msg.
export const setSafariAutoFillInfoAction = (dispatch, flag) => {
  const { name } = getUserAgent();
  if (name === 'Safari') {
    dispatch({
      type: types.IS_SAFARI_AUTOFILL_VISISBLE,
      flag
    })
  }
};

export const getBOSVolumeMasterList = (dispatch) => {
  let url = getApiUrl('common', 'getBOSVolumeMasterList');
  callAPI(url, 'get', null, (response) => {
    if (response.data && response.data.status) {
      dispatch({
        type: types.GET_BOS_VOLUME_MASTER_LIST,
        list: response.data.content
      })
    }
  });
}
const getMembershipTypeInfoByMemberTypeIdAction = (MemberTypeId, callback, dispatch) => {
  let url = getApiUrl('manageMembers', 'getMembershipTypeInfoByMemberTypeId') + MemberTypeId;
  dispatch(setLoader(true));
  callAPI(url, 'get', null, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data.content);
    } else {
      dispatch(setMessage(true, response.data.message || '8166'));
    }
  });
};
const getMemberCommitteeInfoAction = (accountNumber, callback, dispatch) => {
  let url = getApiUrl('manageMembers', 'getMemberCommitteeInfo') + `/${encrypt(accountNumber)}`;
  dispatch(setLoader(true));
  callAPI(url, 'get', null, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data.content);
    } else {
      dispatch(setMessage(true, response.data.message));
    }
  });
};

const getMemberCommitteeProfileAction = (accountNumber, callback, dispatch) => {
  let url = getApiUrl('manageMembers', 'getMemberCommitteeProfile') + `/${encrypt(accountNumber)}`;
  dispatch(setLoader(true));
  callAPI(url, 'get', null, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data.content);
    } else {
      dispatch(setMessage(true, response.data.message));
    }
  });
};

const getOrganizationPersonalDetailsAction = (accountNumber, cb, dispatch) => {
  let url = getApiUrl('manageMembers', 'getOrganizationPersonalDetails') + `/${encrypt(accountNumber)}`;
  callAPI(url, 'get', null, (response) => {
    if (response.data && response.data.status) {
      cb(response.data.content);
    } else {
      dispatch(setMessage(true, '3046'));
    }
  });
}
export const updateOrganizationAction = (data, callback, dispatch) => {
  const url = getApiUrl('manageMembers', 'updateOrganization');
  dispatch(setLoader(true));
  callAPI(url, 'put', data, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      dispatch(setMessage(true, '2001'));
      callback();
    } else {
      dispatch(setMessage(true, response.data.message || '8168'));
    }
  });
}

export const flagFeature = (callback, dispatch) => {
  let url = getApiUrl('openAPI', 'flagFeature', true);
  dispatch(setLoader(true));
  callAPI(url, 'get', null, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.Status) {
      cacheManager.setItem('featureFlag', response.data.Result)
      callback(response.data.Result);
    } else {
      dispatch(setMessage(true, response.data.Message || '8166'));
    }
  });
}

export const getTableauReportDetailAction = (submoduleId, dispatch, callback)=>{
  let url = `${getApiUrl('reports', 'getTableauReport')}/${submoduleId}`;
  dispatch(setLoader(true));
  callAPI(url, 'get', null, (response) => {
    dispatch(setLoader(false));
    if (response.data && response.data.status) {
      callback(response.data.content);
    } else {
      dispatch(setMessage(true, response.data.message|| '10009'));
      callback([])
    }
  });
}
export default {
  hideMessage,
  setLoader,
  setMessage,
  showErrorPopup,
  hideErrorPopup,
  getUserNameAction,
  getGridPreferenceAction,
  updateGridPreferenceAction,
  getExportDataAction,
  showAlertPopup,
  hideAlertPopup,
  logout,
  getPermissionOnPageAction,
  getCountryAndStateListAction,
  getAppUserListAction,
  currentLocationStorage,
  setCurrentLocationStorage,
  initiateAzureRequestAction,
  downloadDocumentAction,
  setSafariAutoFillInfoAction,
  downloadS3DocumentAction,
  getMembershipTypeInfoByMemberTypeIdAction,
  getMemberCommitteeInfoAction,
  getMemberCommitteeProfileAction,
  getOrganizationPersonalDetailsAction,
  updateOrganizationAction,
  downloadDMSDocumentAction
}

