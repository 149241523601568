import { formatDateToMMDDYYYY, getMessage } from 'helpers/util-common';
import moment from 'moment';

let self = {};
let accountNo = '';
let postData = {};
let studentMembershipId = 17;
export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.props.AccountNumber;
    const membershipTypeId = self.props.initialValues.MemberTypeId ? self.props.initialValues.MemberTypeId : self.props.initialValues.memberDetails.MemberTypeId;
    let classificationList = [];
    let noVoteReasonList = [];
    if (self.props.commonCommitteeClassificationTypeList && self.props.commonCommitteeClassificationTypeList.length > 0) {
        self.props.commonCommitteeClassificationTypeList.map(item => classificationList.push({ key: item.ClassificationTypeId, text: item.text, value: item.ClassificationTypeId }))
        self.setState({ classificationList }, () => {
            let defaultClassificationId = 0;
            if (classificationList.filter(item => item.text.toLowerCase() === 'unclassified').length > 0) {
                if (self.props.memberCommitteeDetails.GeneralInformation.Classification !== null) {
                    defaultClassificationId = classificationList.filter(item => item.text.toLowerCase() === self.props.memberCommitteeDetails.GeneralInformation.Classification.toLowerCase())[0].value;
                } else {
                    defaultClassificationId = classificationList.filter(item => item.text.toLowerCase() === 'unclassified')[0].value;
                }

            }

            self.props.change('classificationId', defaultClassificationId);


        });

    }
    let memberCommitteeNoVoteReasonList = self.props.memberCommitteeNoVoteReasonList && self.props.memberCommitteeNoVoteReasonList.length > 0 ? self.props.memberCommitteeNoVoteReasonList : self.props.noVoteReasonList;
    if (memberCommitteeNoVoteReasonList && memberCommitteeNoVoteReasonList.length > 0) {
        let selectedVote = 0;

        self.setState({ noVoteReasonList }, () => {
            memberCommitteeNoVoteReasonList.map(item => noVoteReasonList.push({ key: item.VoteId, text: item.Description, value: item.VoteId }))
            if (self.props.memberCommitteeDetails.GeneralInformation.NoVoteReason && self.props.memberCommitteeDetails.GeneralInformation.NoVoteReason != '') {
                selectedVote = memberCommitteeNoVoteReasonList.filter(item => item.Description.toLowerCase() === self.props.memberCommitteeDetails.GeneralInformation.NoVoteReason.toLowerCase())[0].VoteId || 0
            } else {
                selectedVote = memberCommitteeNoVoteReasonList.filter(item => item.Description.toLowerCase() === 'pending')[0].VoteId || 0;
            }
            self.props.change('noVoteReason', selectedVote);
        })

    }
    if (self.props.memberCommitteeDetails.GeneralInformation.IsOfficialVote) { self.props.change('voteDate', formatDateToMMDDYYYY(self.props.memberCommitteeDetails.GeneralInformation.VoteDate)); }
    if (self.props.MemberTypeId === 4) {
        if (self.props.memberCommitteeDetails.GeneralInformation.JoinAsAffiliateDate && self.props.memberCommitteeDetails.GeneralInformation.JoinAsAffiliateDate !== null) {
            self.props.change('affiliateDate', formatDateToMMDDYYYY(self.props.memberCommitteeDetails.GeneralInformation.JoinAsAffiliateDate));
        }
        else {
            self.props.change('affiliateDate', formatDateToMMDDYYYY(self.props.memberCommitteeDetails.TopSection.CommitteeJoinDate));
        }
    }
    if (self.props.flagFeature.StudentCommitteeManagement && membershipTypeId === studentMembershipId) {
        self.setState({ disableFieldForStudent: true });
    }

    let ClassificationDate = self.props.memberCommitteeDetails.GeneralInformation.ClassificationDate;
    ClassificationDate = ClassificationDate && ClassificationDate !== '' ? formatDateToMMDDYYYY(ClassificationDate) : ClassificationDate;

    let voteDate = self.props.memberCommitteeDetails.GeneralInformation.VoteDate;
    voteDate = voteDate && voteDate !== '' ? formatDateToMMDDYYYY(voteDate) : voteDate;

    self.props.change('rosterNotes', self.props.memberCommitteeDetails.GeneralInformation.RosterNotes);
    self.props.change('voteDate', formatDateToMMDDYYYY(voteDate));
    self.props.change('ClassificationDate', formatDateToMMDDYYYY(ClassificationDate));
}
export const onChangeVoteStatus = (e) => {
    let selectedVoteStatus = e.currentTarget.querySelector('input').value;
    self.setState({ selectedVoteStatus });
    self.props.change('voteDate', formatDateToMMDDYYYY());
}
export const onChangeNoVoteReason = (e) => {
    self.props.change('voteDate', formatDateToMMDDYYYY());
}
export const onChangeClassification = (e) => {
    self.props.change('ClassificationDate', formatDateToMMDDYYYY());
}
export const isValidDate = (value, formatType) => {
    if (value !== "") {
        let sptArr = value.split('/');
        if (sptArr !== null && sptArr.length === 3) {
            if ((sptArr[2] === '0000' && formatType === 1) || (sptArr[1] === '0000' && formatType === 2)) {
                return false;
            }
        }

    }
    return moment(value, formatType === 1 ? 'MM/DD/YYYY' : 'MM/YYYY', true).isValid();
}
export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.ClassificationId || values.ClassificationId <= 0) { // Validation for title
        errors.ClassificationId = getMessage(props.messageCodes, '8126.text');
    }

    if (!values.isOfficialVote && (!values.noVoteReason || values.noVoteReason <= 0)) {
        errors.noVoteReason = getMessage(props.messageCodes, '8127.text');
    } else if ((values.isOfficialVote || values.isOfficialVote == 'true') && !values.voteDate) {
        errors.voteDate = getMessage(props.messageCodes, '3080.text');
    } else if ((values.isOfficialVote || values.isOfficialVote == 'true') && !isValidDate(values.voteDate, 1)) {
        errors.voteDate = getMessage(props.messageCodes, '3081.text');
    } else if ((values.isOfficialVote || values.isOfficialVote == 'true') && isValidDate(values.voteDate, 1) && new Date(values.voteDate) > new Date()) {
        errors.voteDate = getMessage(props.messageCodes, '3081.text');
    }
    if (!values.updateReason || (values.updateReason && values.updateReason.trim().length == 0)) {
        errors.updateReason = getMessage(props.messageCodes, '8121.text');
    }
    // Validation removed  ref no MCS2-5978
    // if (props.isMainCommittee && !values.rosterNotes || (values.rosterNotes && values.rosterNotes.trim().length == 0)) {
    //     errors.rosterNotes = getMessage(props.messageCodes, '8134.text');
    // }
    return errors;
}
export const popUpCancel = () => {
    self.setState({ isUpdatePopUp: false }, () => self.props.onSuccess(self.props.activeCommitteeId))
}
export const popUpConfirm = () => {
    self.setState({ isUpdatePopUp: false }, () => {
        self.props.updateMemberCommitteeGeneralInfo(postData, () => {
            self.props.onSuccess(self.props.activeCommitteeId);
        })
    })

}
export const saveMemberCommittee = (value) => {
    let isUpdatePopUp = self.state.isUpdatePopUp
    postData = {
        "AccountNumber": accountNo,
        "CommitteeId": self.props.activeCommitteeId,
        "ClassificationId": value.classificationId,
        "IsOfficialVote": self.state.selectedVoteStatus,
        "NoVoteReasonId": (!self.state.selectedVoteStatus || self.state.selectedVoteStatus == 'false') ? value.noVoteReason : 0,
        "VoteDate": (value.voteDate) ? value.voteDate : formatDateToMMDDYYYY(),
        "MainCommitteeRosterNotes": self.props.isMainCommittee ? value.rosterNotes : '',
        "AffiliateDate": value.affiliateDate,
        "UpdateReason": value.updateReason,
        "IsMainCommittee": self.props.isMainCommittee,
        "IsdidNotChange": false,
        'ClassificationDate': (value.ClassificationDate) ? value.ClassificationDate : formatDateToMMDDYYYY()
    }

    if (!self.props.isMainCommittee && value.classificationId > 0 && self.props.commonCommitteeClassificationTypeList.filter(item => item.ClassificationTypeId == value.classificationId && item.text.toLowerCase() === 'producer').length > 0 && self.props.validateProducerClassification && self.props.memberCommitteeDetails.GeneralInformation.LevelId === 2) {
        isUpdatePopUp = true;
        self.setState({ isUpdatePopUp })
    } else if (((self.props.isMainCommittee && (value.rosterNotes || '') === self.props.memberCommitteeDetails.GeneralInformation.RosterNotes) || !self.props.isMainCommittee) && (value.classificationId > 0 && self.props.commonCommitteeClassificationTypeList.filter(item => item.ClassificationTypeId == value.classificationId && item.text.toLowerCase() === self.props.memberCommitteeDetails.GeneralInformation.Classification.toLowerCase()).length > 0)
        && ((self.state.selectedVoteStatus == 'false' && self.state.selectedVoteStatus === self.props.memberCommitteeDetails.GeneralInformation.IsOfficialVote.toString() && self.props.noVoteReasonList.filter(item => item.VoteId == value.noVoteReason && item.text.toLowerCase() === self.props.memberCommitteeDetails.GeneralInformation.NoVoteReason.toLowerCase()).length > 0)
            || (self.state.selectedVoteStatus == 'true' && self.state.selectedVoteStatus === self.props.memberCommitteeDetails.GeneralInformation.IsOfficialVote.toString()))
        && (value.voteDate == formatDateToMMDDYYYY(self.props.memberCommitteeDetails.GeneralInformation.VoteDate))
        && (value.ClassificationDate == formatDateToMMDDYYYY(self.props.memberCommitteeDetails.GeneralInformation.ClassificationDate))
        && ((self.props.MemberTypeId === 4 && formatDateToMMDDYYYY(self.props.memberCommitteeDetails.JoinAsAffiliateDate) == value.affiliateDate) || self.props.MemberTypeId !== 4)
    ) {
        postData.IsdidNotChange = true;

    }
    if (!isUpdatePopUp) {
        self.props.updateMemberCommitteeGeneralInfo(postData, () => {
            self.props.getMemberDetails(accountNo);
            self.props.onSuccess(self.props.activeCommitteeId);
        })
    }

}
