import { cacheManager } from 'helpers/util-common';
import React, { Component } from 'react';
import { Accordion, Grid, Icon } from "semantic-ui-react";
import CheckboxElement from 'shared-components/CheckBox';

class ViewCommitteeEnableOnWeb extends Component {

    state = {
        activeIndex: 0
    }

    shouldComponentUpdate(nextProps) {
        if ((this.props.initialValues.CommitteeId !== nextProps.initialValues.CommitteeId) ||
            (this.props.initialValues.CommitteeId === nextProps.initialValues.CommitteeId && this.props.initialValues.ModifiedDate !== nextProps.initialValues.ModifiedDate)) {
            this.setState({ activeIndex: 0 });
        }
        return true;
    }

    handleClick = (e, props) => {
        const { index } = props
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    checkIfPrivilegeSelected = (privilegeList) => {
        for (let i = 0; i < privilegeList.length; i++) {
            if (privilegeList[i].IsChecked) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { initialValues, hasUpdateAccess, onEditEnableOnWeb, applicationPrivilegeOnCommittee } = this.props;
        const { activeIndex } = this.state;
        const flagFeature = JSON.parse(cacheManager.getItem("featureFlag"));

        return (
            <div>
                <div className="settingArea">
                    <div className="mb20">
                        <CheckboxElement IsEditable={false} setLabeltitleProp='Suppress committee from public web (committee home page, search, product pages and jurisdiction list)'
                            IsChecked={initialValues.IsEnableCommitteeOnWeb} />
                        {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <span onClick={onEditEnableOnWeb} data-testid="editButton" title="Edit" className="editBtn floatRight"><Icon name="pencil" /></span>}
                    </div>
                    <div className="mb20">
                        <CheckboxElement IsEditable={false} setLabeltitleProp='Suppress committee from registering/editing a work item or submitting a ballot item'
                            IsChecked={initialValues.IsSuppressOnWI} />
                    </div>
                    {flagFeature?.StudentCommitteeManagement && <div className="mb20">
                        <CheckboxElement IsEditable={false} setLabeltitleProp='Enable student member program' IsChecked={initialValues.IsStudentEnabled} />
                    </div>}
                    <div className="mt10 mb20" style={{ display: 'none' }}>
                        {initialValues.IsEnableCommitteeOnWeb && applicationPrivilegeOnCommittee.length > 0 &&
                            applicationPrivilegeOnCommittee.map((item, index) => {
                                if (this.checkIfPrivilegeSelected(item.Privilege)) {
                                    return (
                                        <Accordion fluid >
                                            <Accordion.Title
                                                active={activeIndex === index}
                                                index={index}
                                                onClick={this.handleClick}>
                                                <Icon name='dropdown' />
                                                {item.ApplicationName}
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === index}>
                                                <div className="checkBoxWrapper">
                                                    <Grid columns='equal'>
                                                        <Grid.Row>
                                                            {item.Privilege.map((privilegeItem) => {
                                                                return (
                                                                    <Grid.Column>
                                                                        <CheckboxElement className="checkAccordian" IsEditable={false}
                                                                            IsChecked={privilegeItem.IsChecked} setLabeltitleProp={privilegeItem.ApplicationPrivilegeName}></CheckboxElement>
                                                                    </Grid.Column>
                                                                )
                                                            })}
                                                        </Grid.Row>
                                                    </Grid>
                                                </div>
                                            </Accordion.Content>
                                        </Accordion>
                                    )
                                }
                            })}
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewCommitteeEnableOnWeb;
