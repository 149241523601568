import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const ActiveStudentMemberModal = ({ handleClick, handleDismiss, showCustomMessageStudentExist }) => (

<Modal data-testid='ActiveStudentMemberModal' open={showCustomMessageStudentExist} className="tiny errormodalbox">
    <Modal.Header>MESSAGE<i onClick={handleDismiss} className="close" title="close">&#x2716;</i></Modal.Header>
    <Modal.Content>
        <Modal.Description>
            <p>Active student members are currently listed on the <b>From Committee</b>. To include these students in the <b>To Committee</b>, please enable the <b>Enable Student Member Program</b> setting on the main committee page. If this setting is not activated before copying, student members will not be transferred to the <b>To Committee</b>.</p>

            <div class="ui container center aligned">
                <Button secondary style={{ marginRight: '10px' }} onClick={handleClick}>CONTINUE COPY</Button>
                <Button secondary onClick={handleDismiss}>CLOSE</Button>
            </div>

        </Modal.Description>
    </Modal.Content>
</Modal>
)

export default ActiveStudentMemberModal;
